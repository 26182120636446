/*------------
    RESET
------------*/
/*------------------------------------------------------------------
[Table of contents]

1. RESET
-------------------------------------------------------------------*/
/*------------------
    1. RESET
------------------*/
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Rajdhani:400,500,600,700&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

*::before,
*::after {
  display: block;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
}

select {
  appearance: none;
}

h1, h2, h3, h4,
h5, h6, p,
ol, ul,
figure,
.table,
.form-row {
  margin: 0;
}

/*-------------
    GENERAL
-------------*/
/*------------------------------------------------------------------
[Table of contents]

1. FONTS
2. WEIGHTS
3. SIZES
4. PARAGRAPH
5. MIXINS
-------------------------------------------------------------------*/
/*-----------------
    1. FONTS
-----------------*/
/*-----------------
    2. WEIGHTS
-----------------*/
/*-----------------
    3. SIZES
-----------------*/
/*-----------------
    4. PARAGRAPH
-----------------*/
/*-----------------
    5. MIXINS
-----------------*/
/*------------------------------------------------------------------
[Table of contents]

1. RAW COLORS
2. SOCIAL COLORS
-------------------------------------------------------------------*/
/*---------------------
    1. RAW COLORS
---------------------*/
/*-----------------------
    1. SOCIAL COLORS
-----------------------*/
/*------------------------------------------------------------------
[Table of contents]

1. THEME COLORS
    1.1 PRIMARY COLORS
    1.2 SECONDARY COLORS
    1.3 TERTIARY COLORS
2. GENERAL COLORS
    2.1 BODY COLORS
    2.2 BOX COLORS
    2.3 TEXT COLORS
    2.4 TEXT SELECTION COLORS
    2.5 LINK COLORS
    2.6 ICON COLORS
    2.7 BORDER COLORS
    2.8 SHADOW COLORS
    2.9 INPUT COLORS
    2.10 BUTTON COLORS
    2.11 PAGE LOADER
    2.12 LOADER BAR
3. COMPONENT COLORS
    3.0 TAB SWITCH
    3.1 FORM SWITCH
    3.2 USER STATUS
    3.3 VIDEO STATUS
    3.4 SECTION MENU
    3.5 CHAT WIDGET
    3.6 NAVIGATION WIDGET
    3.7 XM TOOLTIP TEXT
    3.8 DROPDOWN LIST ITEM
    3.9 PICTURE ITEM
    3.10 TEXT STICKER
    3.11 TAG STICKER
    3.12 DATE STICKER
    3.13 FORUM POST
    3.14 ALBUM PREVIEW
    3.15 PICTURE COLLAGE
    3.16 STATUS COLORS
    3.17 PATTERN COLORS
    3.18 ACCOUNT STAT COLORS
    3.19 STAT COLORS
    3.20 ACTIVITY COLORS
    3.21 CATEGORY COLORS
    3.22 REFERENCE BULLET COLORS
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------
                        1. THEME COLORS
-------------------------------------------------------------------*/
/*-------------------------
    1.1 PRIMARY COLORS
-------------------------*/
/*---------------------------
    1.2 SECONDARY COLORS
---------------------------*/
/*---------------------------
    1.3 TERTIARY COLORS
---------------------------*/
/*-------------------------------------------------------------------
                        2. GENERAL COLORS
-------------------------------------------------------------------*/
/*-----------------------
    2.1 BODY COLORS
-----------------------*/
/*-----------------------
    2.2 BOX COLORS
-----------------------*/
/*-----------------------
    2.3 TEXT COLORS
-----------------------*/
/*-------------------------------
    2.4 TEXT SELECTION COLORS
-------------------------------*/
/*--------------------------
    2.5 LINK COLORS
--------------------------*/
/*-----------------------
    2.6 ICON COLORS
-----------------------*/
/*-----------------------
    2.7 BORDER COLORS
-----------------------*/
/*-----------------------
    2.8 SHADOW COLORS
-----------------------*/
/*-----------------------
    2.9 INPUT COLORS
-----------------------*/
/*------------------------------
    2.10 BUTTON
------------------------------*/
/*-----------------------
    2.11 PAGE LOADER
-----------------------*/
/*-----------------------
    2.12 LOADER BAR
-----------------------*/
/*-------------------------------------------------------------------
                        3. COMPONENT COLORS
-------------------------------------------------------------------*/
/*---------------------------
    3.0 TAB SWITCH
---------------------------*/
/*---------------------------
    3.1 FORM SWITCH
---------------------------*/
/*-----------------------
    3.2 USER STATUS
-----------------------*/
/*-----------------------
    3.3 VIDEO STATUS
-----------------------*/
/*-------------------------
    3.4 SECTION MENU
-------------------------*/
/*-------------------------
    3.5 CHAT WIDGET
-------------------------*/
/*---------------------------
    3.6 NAVIGATION WIDGET
---------------------------*/
/*---------------------------
    3.7 XM TOOLTIP TEXT
---------------------------*/
/*---------------------------
    3.8 DROPDOWN LIST ITEM
---------------------------*/
/*---------------------------
    3.9 PICTURE ITEM
---------------------------*/
/*---------------------------
    3.10 TEXT STICKER
---------------------------*/
/*---------------------------
    3.11 TAG STICKER
---------------------------*/
/*---------------------------
    3.12 DATE STICKER
---------------------------*/
/*-------------------------------
    3.13 FORUM POST
-------------------------------*/
/*------------------------------
    3.14 ALBUM PREVIEW
------------------------------*/
/*------------------------------
    3.15 PICTURE COLLAGE
------------------------------*/
/*-------------------------
    3.16 STATUS COLORS
-------------------------*/
/*-------------------------
    3.17 PATTERN COLORS
-------------------------*/
/*-----------------------------
    3.18 ACCOUNT STAT COLORS
-----------------------------*/
/*-----------------------
    3.19 STAT COLORS
-----------------------*/
/*--------------------------
    3.20 ACTIVITY COLORS
--------------------------*/
/*--------------------------
    3.21 CATEGORY COLORS
--------------------------*/
/*------------------------------------
    3.22 REFERENCE BULLET COLORS
------------------------------------*/
/*-------------------------------------------------------------------
                    YOUR CUSTOM COLORS HERE
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1. GENERAL
2. .form
3. .form-row
4. .form-item
5. .form-input-decorated
6. .form-input
7. .form-title
8. .form-textarea
9. .form-select
10. .checkbox-line
11. .checkbox-wrap
12. .form-link
13 .form-text
14. .interactive-input
15. .form-add-items
16. .form-rating-wrap
17. .form-counter-wrap
18. .form-counter
19. .form-switch
-------------------------------------------------------------------*/
/*-----------------
    1. GENERAL
-----------------*/
label,
input[type="text"],
input[type="password"],
textarea,
select,
button {
  font-family: "Rajdhani", sans-serif;
}

label {
  display: block;
  margin: 0;
  color: #3e3f5e;
  line-height: 1em;
}

input[type="text"],
input[type="password"],
textarea,
select,
button {
  width: 100%;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 700;
}

input[type="text"],
input[type="password"],
textarea,
select {
  background-color: #fff;
  border: 1px solid #dedeea;
  color: #3e3f5e;
  transition: border-color .2s ease-in-out;
}

input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border-color: #615dfa;
}

input[type="text"],
input[type="password"] {
  height: 54px;
  padding: 0 18px;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 500;
}

textarea {
  padding: 14px 18px;
  resize: none;
}

textarea::placeholder {
  color: #adafca;
  font-weight: 600;
}

/*--------------
    2. FORM
--------------*/
.form {
  width: 100%;
}

.form + .button {
  margin-top: 40px;
}

/*------------------
    3. FORM ROW
------------------*/
.form-row {
  width: 100%;
}

.form-row + .form-row {
  margin-top: 28px;
}

.form-row.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-row.space-between .form-item {
  width: auto;
}

.form-row.split {
  display: flex;
  flex-wrap: nowrap;
}

.form-row.split .form-item {
  margin-right: 16px;
}

.form-row.split .form-item:last-child {
  margin-right: 0;
}

.form-row.split .form-item > * {
  margin-bottom: 28px;
}

.form-row.split .form-item > *:last-child {
  margin-bottom: 0;
}

/*------------------
    4. FORM ITEM
------------------*/
.form-item {
  width: 100%;
}

.form-item.auto-width {
  width: auto;
}

.form-item.centered {
  display: flex;
  align-items: center;
}

.form-item.split {
  display: flex;
}

.form-item.split.medium > * {
  margin-right: 16px;
}

.form-item.split.medium > *:last-child {
  margin-right: 0;
}

.form-item.split > * {
  width: 100%;
  margin-right: 12px;
}

.form-item.split > *:last-child {
  margin-right: 0;
}

/*------------------------------
    5. FORM INPUT DECORATED
------------------------------*/
.form-input-decorated {
  position: relative;
}

.form-input-decorated input[type="text"] {
  padding-right: 60px;
}

.form-input-decorated .form-input-icon {
  fill: #adafca;
  opacity: .6;
  pointer-events: none;
  position: absolute;
  top: 14px;
  right: 20px;
}

/*-------------------
    6. FORM INPUT
-------------------*/
.form-input {
  position: relative;
}

.form-input.dark input[type="text"],
.form-input.dark input[type="password"],
.form-input.dark textarea {
  border: none;
  background-color: #4e4ac8;
  color: #fff;
}

.form-input.dark input[type="text"]::placeholder,
.form-input.dark input[type="password"]::placeholder,
.form-input.dark textarea::placeholder {
  color: #8b88ff;
  opacity: .6;
}

.form-input.social-input .social-link {
  position: absolute;
  top: 4px;
  left: 4px;
  pointer-events: none;
}

.form-input.social-input input {
  padding-left: 64px;
}

.form-input.social-input label {
  left: 64px;
}

.form-input.social-input.active label {
  left: 58px;
}

.form-input.currency-decorated::before {
  content: '$';
  color: #00c7d9;
  font-size: 0.875rem;
  font-weight: 700;
  position: absolute;
  top: 12px;
  left: 22px;
  pointer-events: none;
}

.form-input.currency-decorated input {
  padding-left: 32px;
}

.form-input.small label,
.form-input.small input,
.form-input.small textarea {
  font-size: 0.875rem;
}

.form-input.small label {
  top: 16px;
}

.form-input.small input {
  height: 48px;
}

.form-input.small textarea {
  height: 100px;
}

.form-input.small button {
  width: 64px;
  position: absolute;
  top: 0;
  right: 0;
}

.form-input.full {
  height: 100%;
}

.form-input.full textarea {
  height: 100%;
}

.form-input.mid-textarea textarea {
  height: 124px;
}

.form-input.medium-textarea textarea {
  height: 160px;
}

.form-input.active label {
  background-color: #fff;
  padding: 0 6px;
  font-size: 0.75rem;
  top: -6px;
  left: 12px;
}

.form-input label {
  color: #adafca;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 20px;
  transition: all .3s ease-in-out;
  pointer-events: none;
}

/*-------------------
    7. FORM TITLE
-------------------*/
.form-title {
  font-size: 0.875rem;
  font-weight: 700;
}

/*----------------------
    8. FORM TEXTAREA
----------------------*/
.form-textarea {
  position: relative;
}

.form-textarea textarea {
  font-size: 0.875rem;
  padding: 26px 28px 0;
}

.form-textarea .form-textarea-limit-text {
  padding: 0 28px;
  font-size: 0.75rem;
  font-weight: 500;
  opacity: .6;
  text-align: right;
}

/*----------------------
    9. FORM SELECT
----------------------*/
.form-select {
  width: 100%;
  height: 48px;
  position: relative;
}

.form-select.v2 {
  width: auto;
  height: auto;
}

.form-select.v2 select {
  width: auto;
  height: auto;
  padding-right: 20px;
  border: none;
  font-size: 0.75rem;
}

.form-select.v2 .form-select-icon {
  top: 10px;
  right: 0;
}

.form-select select {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  font-size: 0.875rem;
  cursor: pointer;
}

.form-select .form-select-icon {
  fill: #3e3f5e;
  transform: rotate(90deg);
  position: absolute;
  top: 20px;
  right: 20px;
  pointer-events: none;
}

.form-select label {
  padding: 0 6px;
  background-color: #fff;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 600;
  position: absolute;
  top: -8px;
  left: 12px;
  pointer-events: none;
}

/*------------------------
    10. CHECKBOX LINE
------------------------*/
.checkbox-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-line .checkbox-line-text {
  color: #00c7d9;
  font-size: 0.875rem;
  font-weight: 500;
}

/*------------------------
    11. CHECKBOX WRAP
------------------------*/
.checkbox-wrap {
  position: relative;
}

.checkbox-wrap .checkbox-info {
  margin-top: 12px;
}

.checkbox-wrap .checkbox-info .checkbox-info-text {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.checkbox-wrap.checkbox-rating .rating-list {
  position: absolute;
  top: 6px;
  left: 34px;
  pointer-events: none;
}

.checkbox-wrap.checkbox-rating label {
  padding-left: 104px;
}

.checkbox-wrap input[type="checkbox"],
.checkbox-wrap input[type="radio"] {
  display: none;
}

.checkbox-wrap input[type="checkbox"]:checked + .checkbox-box,
.checkbox-wrap input[type="radio"]:checked + .checkbox-box {
  background-color: #23d2e2;
  border-color: #23d2e2;
}

.checkbox-wrap input[type="checkbox"]:checked + .checkbox-box.round,
.checkbox-wrap input[type="radio"]:checked + .checkbox-box.round {
  border: 6px solid #23d2e2;
  background-color: #fff;
}

.checkbox-wrap input[type="checkbox"]:checked + .checkbox-box .icon-cross,
.checkbox-wrap input[type="radio"]:checked + .checkbox-box .icon-cross {
  fill: #fff;
}

.checkbox-wrap label {
  padding-left: 34px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
}

.checkbox-wrap .checkbox-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border: 1px solid #dedeea;
  border-radius: 6px;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: border-color .2s ease-in-out, background-color .2s ease-in-out;
}

.checkbox-wrap .checkbox-box.round {
  border-radius: 50%;
}

.checkbox-wrap .checkbox-box .icon-cross {
  fill: transparent;
  transition: fill .2s ease-in-out;
}

.checkbox-wrap.small label {
  padding-left: 24px;
  font-size: 0.75rem;
  line-height: 16px;
}

.checkbox-wrap.small .checkbox-box {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.checkbox-wrap.small .checkbox-box.round {
  border-radius: 50%;
}

.checkbox-wrap.small .checkbox-box .icon-cross {
  width: 8px;
  height: 8px;
}

/*--------------------
    12. FORM LINK
--------------------*/
.form-link {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 22px;
}

/*--------------------
    13. FORM TEXT
--------------------*/
.form-text {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
  font-weight: 500;
}

/*----------------------------
    14. INTERACTIVE INPUT
----------------------------*/
.interactive-input {
  width: 100%;
  height: 52px;
  position: relative;
}

.interactive-input.dark input {
  border: none;
  background-color: #4e4ac8;
  color: #fff;
}

.interactive-input.dark input::placeholder {
  color: #8b88ff;
  opacity: .6;
}

.interactive-input.dark .interactive-input-icon-wrap .interactive-input-icon {
  fill: #8b88ff;
}

.interactive-input.dark .interactive-input-action:hover .interactive-input-action-icon {
  fill: #fff;
}

.interactive-input.dark .interactive-input-action .interactive-input-action-icon {
  fill: #8b88ff;
}

.interactive-input.small {
  height: 48px;
}

.interactive-input.active .interactive-input-action {
  display: flex;
}

.interactive-input.active .interactive-input-icon {
  display: none;
}

.interactive-input input {
  height: 100%;
  padding-right: 60px;
}

.interactive-input .interactive-input-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}

.interactive-input .interactive-input-icon-wrap.actionable {
  pointer-events: all;
}

.interactive-input .interactive-input-icon-wrap.actionable .interactive-input-icon {
  cursor: pointer;
}

.interactive-input .interactive-input-icon-wrap.actionable .interactive-input-icon:hover {
  fill: #3e3f5e;
  opacity: 1;
}

.interactive-input .interactive-input-icon-wrap .interactive-input-icon {
  fill: #adafca;
  opacity: .6;
  transition: fill .2s ease-in-out, opacity .2s ease-in-out;
}

.interactive-input .interactive-input-action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  display: none;
}

.interactive-input .interactive-input-action:hover .interactive-input-action-icon {
  fill: #3e3f5e;
  opacity: 1;
}

.interactive-input .interactive-input-action .interactive-input-action-icon {
  fill: #adafca;
  opacity: .6;
  pointer-events: none;
  transition: fill .2s ease-in, opacity .2s ease-in;
}

/*----------------------------
    15. FORM ADD ITEMS
----------------------------*/
.form-add-items .form-add-items-title {
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 600;
}

.form-add-items .form-add-items-info {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.form-add-items .form-add-items-info .user-avatar-list {
  margin-left: 14px;
}

.form-add-items .form-add-items-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #23d2e2;
  cursor: pointer;
}

.form-add-items .form-add-items-button .form-add-items-button-icon {
  pointer-events: none;
}

/*----------------------------
    16. FORM RATING WRAP
----------------------------*/
.form-rating-wrap {
  display: flex;
  align-items: center;
}

.form-rating-wrap label {
  margin-right: 16px;
  font-size: 0.875rem;
  font-weight: 700;
}

/*----------------------------
    17. FORM COUNTER WRAP
----------------------------*/
.form-counter-wrap {
  position: relative;
}

.form-counter-wrap label {
  padding: 0 6px;
  background-color: #fff;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 600;
  position: absolute;
  top: -6px;
  left: 12px;
  z-index: 2;
  pointer-events: none;
}

/*----------------------
    18. FORM COUNTER
----------------------*/
.form-counter {
  display: flex;
  align-items: center;
  width: 96px;
  height: 48px;
  padding: 0 40px 0 18px;
  border: 1px solid #dedeea;
  border-radius: 12px;
  position: relative;
}

.form-counter.with-currency {
  padding-left: 30px;
}

.form-counter.with-currency::before {
  content: '$';
  color: #00c7d9;
  font-family: "Rajdhani", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  position: absolute;
  top: 13px;
  left: 20px;
}

.form-counter.full {
  width: 100%;
}

.form-counter .form-counter-value {
  font-size: 0.875rem;
  font-weight: 700;
}

.form-counter .form-counter-control {
  display: flex;
  justify-content: center;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.form-counter .form-counter-control .form-counter-icon {
  fill: #3e3f5e;
}

.form-counter .form-counter-control.form-counter-control-increase {
  top: 10px;
}

.form-counter .form-counter-control.form-counter-control-increase .form-counter-icon {
  transform: rotate(-90deg);
}

.form-counter .form-counter-control.form-counter-control-decrease {
  align-items: flex-end;
  bottom: 10px;
}

.form-counter .form-counter-control.form-counter-control-decrease .form-counter-icon {
  transform: rotate(90deg);
}

/*----------------------
    19. FORM SWITCH
----------------------*/
.form-switch {
  display: flex;
  align-items: center;
  width: 52px;
  height: 28px;
  border: 1px solid #dedeea;
  border-radius: 200px;
  background-color: #eaeaf5;
  cursor: pointer;
  position: relative;
  transition: background-color .3s ease-in-out;
}

.form-switch.active {
  background-color: #23d2e2;
}

.form-switch.active .form-switch-button {
  left: 26px;
}

.form-switch .form-switch-button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left .3s ease-in-out;
}

.form-switch .form-switch-button::before, .form-switch .form-switch-button::after {
  content: '';
  width: 2px;
  height: 8px;
  background-color: #adafca;
  opacity: .2;
  position: absolute;
  top: 7px;
}

.form-switch .form-switch-button::before {
  left: 8px;
}

.form-switch .form-switch-button::after {
  right: 8px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 960px) {
  .form-row.split {
    display: block;
  }
  .form-row.split .form-item {
    margin-bottom: 28px;
  }
  .form-row.split .form-item:last-child {
    margin-bottom: 0;
  }
  .form-row.split .form-item textarea {
    height: 124px;
  }
  .form-item.split.join-on-mobile {
    display: block;
  }
  .form-item.split.join-on-mobile.medium > * {
    margin-right: 0;
  }
  .form-item.split.join-on-mobile > * {
    margin-right: 0;
  }
  .form-item.split.join-on-mobile > *:last-child {
    margin-top: 28px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. ICONS
-------------------------------------------------------------------*/
/*-----------------
    1. ICONS
-----------------*/
.icon-facebook,
.icon-twitter,
.icon-twitch,
.icon-youtube,
.icon-instagram,
.icon-patreon,
.icon-discord,
.icon-google,
.icon-behance,
.icon-deviantart,
.icon-artstation,
.icon-dribbble,
.icon-cross {
  fill: #fff;
  width: 12px;
  height: 12px;
}

.icon-logo-vikinger {
  fill: #fff;
  width: 42px;
  height: 60px;
}

.icon-logo-vikinger.small {
  width: 28px;
  height: 40px;
}

.icon-dots {
  fill: #fff;
  width: 10px;
  height: 2px;
}

.icon-public,
.icon-private {
  fill: #3e3f5e;
  width: 20px;
  height: 20px;
}

.icon-comment,
.icon-thumbs-up,
.icon-friend,
.icon-info,
.icon-group,
.icon-marketplace,
.icon-add-friend,
.icon-remove-friend,
.icon-delete,
.icon-send-message,
.icon-settings,
.icon-cross-thin,
.icon-newsfeed,
.icon-overview,
.icon-members,
.icon-badges,
.icon-quests,
.icon-streams,
.icon-events,
.icon-events-monthly,
.icon-events-weekly,
.icon-events-daily,
.icon-pin,
.icon-ticket,
.icon-forums,
.icon-profile,
.icon-timeline,
.icon-photos,
.icon-videos,
.icon-blog-posts,
.icon-forum,
.icon-store,
.icon-share,
.icon-pinned,
.icon-status,
.icon-big-grid-view,
.icon-small-grid-view,
.icon-list-grid-view,
.icon-poll,
.icon-camera,
.icon-gif,
.icon-tags,
.icon-trophy,
.icon-return,
.icon-clock,
.icon-earnings,
.icon-wallet,
.icon-item,
.icon-revenue,
.icon-list,
.icon-minuteur {
  fill: #adafca;
  width: 20px;
  height: 20px;
}

.icon-grid,
.icon-magnifying-glass,
.icon-messages,
.icon-shopping-bag,
.icon-notification,
.icon-join-group,
.icon-leave-group,
.icon-plus,
.icon-login {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.icon-back-arrow {
  width: 14px;
  height: 10px;
}

.icon-small-arrow {
  fill: #adafca;
  width: 6px;
  height: 8px;
}

.icon-big-arrow {
  fill: #3e3f5e;
  width: 8px;
  height: 12px;
}

.icon-more-dots {
  fill: #fff;
  width: 22px;
  height: 6px;
}

.icon-star {
  fill: #eaeaf5;
  width: 9px;
  height: 8px;
}

.icon-star.medium {
  width: 13px;
  height: 12px;
}

.icon-play {
  fill: #fff;
  width: 12px;
  height: 14px;
}

.icon-plus-small,
.icon-minus-small {
  fill: #3e3f5e;
  width: 8px;
  height: 8px;
}

.icon-quote {
  fill: #3e3f5e;
  width: 32px;
  height: 22px;
}

.icon-check {
  width: 12px;
  height: 9px;
}

.demo-icon {
  fill: #3e3f5e;
}

/*------------------------------------------------------------------
[Table of contents]

1. .content-grid
2. .grid
3. .photos-masonry
-------------------------------------------------------------------*/
/*----------------------
    1. CONTENT GRID
----------------------*/
.content-grid {
  max-width: 1184px;
  padding: 112px 0 100px;
}

.content-grid.full {
  max-width: 100%;
  padding: 80px 80px 0;
}

.content-grid.medium {
  max-width: 784px;
  padding: 0 0 100px;
}

.content-grid .section-navigation {
  margin-top: 16px;
}

.content-grid .section-navigation + .grid {
  margin-top: 16px;
}

.content-grid .table {
  margin-top: 16px;
}

.content-grid .table + .loader-bars {
  margin-top: 52px;
}

.content-grid .section-filters-bar {
  margin-top: 30px;
}

.content-grid .grid {
  margin-top: 32px;
}

.content-grid .grid.medium-space {
  margin-top: 64px;
}

.content-grid .grid.small-space {
  margin-top: 16px;
}

.content-grid .grid .grid {
  margin-top: 0;
}

.content-grid .photos-masonry,
.content-grid .section-slider {
  margin-top: 32px;
}

.content-grid .section,
.content-grid .section-header {
  margin-top: 60px;
}

.content-grid .section-banner + .section-filters-bar {
  margin-top: 64px;
}

.content-grid .calendar-widget {
  margin-top: 16px;
}

.content-grid .forum-content .quick-post {
  margin-top: 60px;
}

.content-grid .forum-sidebar {
  padding-top: 40px;
}

.content-grid .account-hub-content .section-header {
  margin-bottom: 28px;
}

.content-grid .account-hub-content .section-header:first-child {
  margin-top: 0;
}

.content-grid .notification-box-list + .loader-bars {
  margin-top: 60px;
}

/*--------------
    2. GRID
--------------*/
.grid {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;
  align-items: start;
}

.grid.centered {
  justify-content: center;
}

.grid.stretched {
  align-items: stretch;
}

.grid.top-space {
  margin-top: 64px;
}

.grid.grid-3-6-3 {
  grid-template-areas: 'sidebar1 content sidebar2';
  grid-template-columns: 23.9864864865% 49.3243243243% 23.9864864865%;
}

.grid.grid-3-6-3 > .grid-column:nth-child(1) {
  grid-area: sidebar1;
}

.grid.grid-3-6-3 > .grid-column:nth-child(2) {
  grid-area: content;
}

.grid.grid-3-6-3 > .grid-column:nth-child(3) {
  grid-area: sidebar2;
}

.grid.grid-6-3_9 {
  grid-template-areas: 'content sidebar';
  grid-template-columns: 66.0633484163% 32.1266968326%;
}

.grid.grid-6-3_9 > .grid-column:nth-child(1) {
  grid-area: content;
}

.grid.grid-6-3_9 > .grid-column:nth-child(2) {
  grid-area: sidebar;
}

.grid.grid-3-6_9 {
  grid-template-areas: 'sidebar content';
  grid-template-columns: 32.1266968326% 66.0633484163%;
}

.grid.grid-3-6_9 > .grid-column:nth-child(1) {
  grid-area: sidebar;
}

.grid.grid-3-6_9 > .grid-column:nth-child(2) {
  grid-area: content;
}

.grid.grid-3-9 {
  grid-template-areas: 'sidebar content';
  grid-template-columns: 23.9864864865% 74.6621621622%;
}

.grid.grid-3-9 > .grid-column:nth-child(1) {
  grid-area: sidebar;
}

.grid.grid-3-9 > .grid-column:nth-child(2) {
  grid-area: content;
}

.grid.grid-9-3 {
  grid-template-areas: 'content sidebar';
  grid-template-columns: 74.6621621622% 23.9864864865%;
}

.grid.grid-9-3 > .grid-column:nth-child(1) {
  grid-area: content;
}

.grid.grid-9-3 > .grid-column:nth-child(2) {
  grid-area: sidebar;
}

.grid.grid-8-4 {
  grid-template-areas: 'content sidebar';
  grid-template-columns: 66.2162162162% 32.4324324324%;
}

.grid.grid-8-4 > .grid-column:nth-child(1) {
  grid-area: content;
}

.grid.grid-8-4 > .grid-column:nth-child(2) {
  grid-area: sidebar;
}

.grid.grid-6-3 {
  grid-template-areas: 'content sidebar';
  grid-template-columns: 49.3243243243% 23.9864864865%;
}

.grid.grid-6-3 > .grid-column:nth-child(1) {
  grid-area: content;
}

.grid.grid-6-3 > .grid-column:nth-child(2) {
  grid-area: sidebar;
}

.grid.grid-half {
  grid-template-columns: 1fr 1fr;
}

.grid.grid-4-4-4 {
  grid-template-columns: repeat(auto-fit, 384px);
}

.grid.grid-4-4 {
  grid-template-columns: repeat(auto-fit, 384px);
}

.grid.grid-3-3-3-3 {
  grid-template-columns: repeat(auto-fit, 284px);
}

.grid.grid-3-3-3 {
  grid-template-columns: repeat(auto-fit, 284px);
}

.grid.grid-2-2-2-2-2-2 {
  grid-template-columns: repeat(auto-fit, 184px);
}

.grid.grid-layout-1 {
  grid-template-columns: repeat(4, 23.9864864865%);
  grid-template-areas: 'sidebar header header header' 'sidebar content content contentsidebar';
}

.grid.grid-layout-1 .grid-sidebar {
  grid-area: sidebar;
}

.grid.grid-layout-1 .grid-header {
  grid-area: header;
}

.grid.grid-layout-1 .grid-content {
  grid-area: content;
}

.grid.grid-layout-1 .grid-content-sidebar {
  grid-area: contentsidebar;
}

.grid .grid-column {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;
  min-width: 0;
}

.grid .grid-column .loader-bars {
  margin: 48px auto 0;
}

.grid .grid-column .simple-tab-items {
  margin: 16px 0;
}

/*------------------------
    3. PHOTOS MASONRY
------------------------*/
.photos-masonry {
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(3, 284px);
  grid-template-areas: 'a b c c' 'a d e f' 'g h i j';
}

.photos-masonry .photo-preview:nth-child(1) {
  grid-area: a;
}

.photos-masonry .photo-preview:nth-child(2) {
  grid-area: b;
}

.photos-masonry .photo-preview:nth-child(3) {
  grid-area: c;
}

.photos-masonry .photo-preview:nth-child(4) {
  grid-area: d;
}

.photos-masonry .photo-preview:nth-child(5) {
  grid-area: e;
}

.photos-masonry .photo-preview:nth-child(6) {
  grid-area: f;
}

.photos-masonry .photo-preview:nth-child(7) {
  grid-area: g;
}

.photos-masonry .photo-preview:nth-child(8) {
  grid-area: h;
}

.photos-masonry .photo-preview:nth-child(9) {
  grid-area: i;
}

.photos-masonry .photo-preview:nth-child(10) {
  grid-area: j;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1500px) {
  .content-grid {
    max-width: 1142px;
  }
}

@media screen and (max-width: 1366px) {
  .content-grid {
    max-width: 100%;
    padding: 112px 112px 200px;
    margin: 0 auto;
  }
  .content-grid.full {
    padding: 80px 80px 0;
  }
  .content-grid.medium {
    max-width: 100%;
    padding: 0 112px 200px;
  }
}

@media screen and (max-width: 1365px) {
  .content-grid .account-hub-content .section-header:first-child {
    margin-top: 60px;
  }
  .grid.grid-3-6-3 {
    grid-template-columns: 32.4324324324% 66.2162162162%;
    grid-template-areas: 'sidebar1 content' 'sidebar2 content';
  }
  .grid.grid-3-9, .grid.grid-9-3, .grid.grid-8-4 {
    grid-template-columns: 100%;
    grid-template-areas: 'content' 'sidebar';
  }
  .grid.grid-3-9 .forum-sidebar, .grid.grid-9-3 .forum-sidebar, .grid.grid-8-4 .forum-sidebar {
    display: none;
  }
  .grid.grid-layout-1 {
    grid-template-areas: 'header header header header' 'sidebar sidebar contentsidebar contentsidebar' 'content content content content';
  }
  .grid.grid-half.change-on-desktop {
    grid-template-columns: 100%;
  }
  .grid.centered-on-mobile {
    justify-content: center;
  }
  .photos-masonry {
    grid-template-areas: unset;
    grid-template-columns: repeat(auto-fit, 184px);
    grid-template-rows: none;
    justify-content: center;
  }
  .photos-masonry .photo-preview {
    height: 184px;
  }
  .photos-masonry .photo-preview:nth-child(1), .photos-masonry .photo-preview:nth-child(2), .photos-masonry .photo-preview:nth-child(3), .photos-masonry .photo-preview:nth-child(4), .photos-masonry .photo-preview:nth-child(5), .photos-masonry .photo-preview:nth-child(6), .photos-masonry .photo-preview:nth-child(7), .photos-masonry .photo-preview:nth-child(8), .photos-masonry .photo-preview:nth-child(9), .photos-masonry .photo-preview:nth-child(10) {
    grid-area: auto;
  }
}

@media screen and (max-width: 1070px) {
  .grid.grid-3-6-3 {
    grid-template-columns: 100%;
    grid-template-areas: 'sidebar1' 'sidebar2' 'content';
  }
  .grid.grid-3-6-3.mobile-prefer-content {
    grid-template-areas: 'content' 'sidebar1' 'sidebar2';
  }
  .grid.grid-3-6-3.mobile-prefer-content .grid-column:nth-child(1), .grid.grid-3-6-3.mobile-prefer-content .grid-column:nth-child(3) {
    display: none;
  }
  .grid.grid-3-9, .grid.grid-6-3_9, .grid.grid-3-6_9 {
    grid-template-columns: 100%;
    grid-template-areas: 'content' 'sidebar';
  }
}

@media screen and (max-width: 960px) {
  .grid.grid-half {
    grid-template-columns: 100%;
  }
  .grid.grid-layout-1 {
    grid-template-columns: 100%;
    grid-template-areas: 'header' 'contentsidebar' 'sidebar' 'content';
  }
}

@media screen and (max-width: 680px) {
  .content-grid {
    width: 95%;
    padding: 92px 0 200px;
  }
  .content-grid.full {
    width: 100%;
    padding: 60px 0 0;
  }
  .content-grid.medium {
    width: 95%;
    padding: 0 0 200px;
  }
  .chat-widget,
  .navigation-widget-desktop {
    display: none;
  }
  .chat-widget-wrap .chat-widget {
    display: block;
  }
}

@media screen and (max-width: 460px) {
  .grid.grid-4-4-4, .grid.grid-4-4, .grid.grid-3-3-3-3 {
    grid-template-columns: 100%;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. GENERAL
-------------------------------------------------------------------*/
/*-----------------
    1. GENERAL
-----------------*/
*::selection {
  color: #fff;
  background-color: #23d2e2;
}

body {
  font-size: 16px;
  background-color: #f8f8fb;
  overflow-x: hidden;
}

h1, h2, h3, h4,
h5, h6, a, p {
  color: #3e3f5e;
  font-family: "Rajdhani", sans-serif;
  line-height: 1em;
}

h1, h2, h3, h4,
h5, h6 {
  font-weight: 700;
}

p a {
  color: #00c7d9;
  font-weight: 600;
}

p .reaction {
  width: 20px;
  height: 20px;
  margin: 0 2px;
  position: relative;
  top: -2px;
}

p .reaction:first-child {
  margin-left: 0;
}

a:hover {
  color: #00c7d9;
  text-decoration: none;
}

figure > img {
  width: 100%;
  height: 100%;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 480px) {
  .hide-text-mobile {
    display: none;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .button
-------------------------------------------------------------------*/
/*-----------------------
    1. BUTTON
-----------------------*/
.button {
  display: inline-block;
  height: 48px;
  border-radius: 10px;
  background-color: #3e3f5e;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  transition: background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  box-shadow: 3px 5px 10px 0 rgba(62, 63, 94, 0.2);
}

.button.no-shadow {
  box-shadow: none;
}

.button.full {
  width: 100%;
}

.button.medium {
  height: 54px;
  line-height: 54px;
}

.button.small {
  height: 40px;
  font-size: 0.75rem;
  line-height: 40px;
}

.button:hover {
  color: #fff;
  background-color: #2e2e47;
}

.button.void {
  background-color: transparent;
  color: #adafca;
  box-shadow: none;
}

.button.void .button-icon {
  fill: #adafca;
}

.button.void:hover {
  color: #fff;
  background-color: #2e2e47;
}

.button.void:hover .button-icon {
  fill: #fff;
}

.button.void.void-primary:hover {
  background-color: #23d2e2;
}

.button.void.void-secondary:hover {
  background-color: #615dfa;
}

.button.white-solid {
  background-color: #fff;
  color: #3e3f5e;
}

.button.white {
  background-color: transparent;
  border: 1px solid #dedeea;
  color: #adafca;
  box-shadow: none;
}

.button.white .button-icon {
  fill: #adafca;
}

.button.white:hover {
  color: #fff;
  background-color: #2e2e47;
  border-color: #2e2e47;
}

.button.white.white-tertiary:hover {
  background-color: #fd4350;
  border-color: #fd4350;
  box-shadow: 4px 7px 12px 0 rgba(253, 67, 80, 0.2);
}

.button.primary {
  background-color: #23d2e2;
  box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
}

.button.primary:hover {
  background-color: #1bc5d4;
}

.button.secondary {
  background-color: #615dfa;
  box-shadow: 4px 7px 12px 0 rgba(97, 93, 250, 0.2);
}

.button.secondary:hover {
  background-color: #5753e4;
}

.button.tertiary {
  background-color: #fd4350;
  box-shadow: 4px 7px 12px 0 rgba(253, 67, 80, 0.2);
}

.button.tertiary:hover {
  background-color: #dd343f;
}

.button.twitch {
  background-color: #7b5dfa;
  box-shadow: 4px 7px 12px 0 rgba(123, 93, 250, 0.2);
}

.button.twitch:hover {
  background-color: #6a4fdf;
}

.button.twitter {
  background-color: #1abcff;
  box-shadow: 3px 5px 10px 0px rgba(26, 188, 255, 0.2);
}

.button.twitter:hover {
  background-color: #0aabed;
}

.button.add-field-button {
  width: 128px;
  margin-top: 40px;
}

.button.padded {
  padding: 0 24px;
}

.button .button-icon {
  fill: #fff;
  transition: fill .2s ease-in-out;
}

.button .button-icon.spaced {
  margin-right: 6px;
}

.button.with-only-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*------------------------------------------------------------------
[Table of contents]

1. .lined-text
2. .social-links
3. .social-link
4. .tab-switch
5. .tab-switch-button
6. .action-request-list
7. .action-request
8. .burger-icon
9. .action-list
10. .action-item
11. .user-stats
12. .user-stat
13. .badge-list
14. .badge-item
15. .tag-list
16. .tag-item
17. .xm-tooltip-text
18. .user-short-description
19. .slider-control
20. .slider-roster
21. .slider-roster-item
22. .tag-sticker
23. .text-sticker
24. .date-sticker
25. .decorated-text
26. .simple-dropdown
27. .paragraph
28. .information-line-list
29. .information-line
30. .information-block-list
31. .information-block
32. .timeline-information-list
33. .timeline-information
34. .rating-list
35. .rating
36. .filters
37. .filter-tabs
38. .filter-tab
39. .view-actions
40. .view-action
41. .tweets
42. .tweet
43. .play-button
44. .iframe-wrap
45. .popup-video
46. .popup-picture
47. .popup-event
48. .popup-close-button
49. .decorated-feature-list
50. .decorated-feature
51. .meta-line
52. .reaction-options
53. .post-options
54. .post-option
55. .post-settings
56. .slider-line
57. .stat-block-list
58. .stat-block
59. .achievement-status-list
60. .achievement-status
61. .page-loader
62. .reaction-count-list
63. .reaction-count
64. .upload-item-list
65. .upload-item
66. .simple-accordion-list
67. .simple-accordion
68. .week-box
69. .week-box-item
70. .forum-category
71. .discussion-preview
72. .page-items
73. .page-item
74. .quick-post
75. .option-items
76. .option-item
77. .stats-box
78. .stats-box-slider
79. .reaction-stats
80. .reaction-stat
81. .simple-tab-items
82. .simple-tab-item
83. .banner-promo
84. .poll-box
85. .poll-results
86. .poll-result
87. .picture-collage
88. .quote-box
89. .stats-decoration
90. .percentage-diff
91. .percentage-diff-icon-wrap
92. .sidebar-box
93. .sidebar-menu
94. .sidebar-menu-item
95. .sidebar-menu-header
96. .sidebar-menu-body
97. .price-title
98. .promo-line
99. .tab-box
100. .bullet-item-list
101. .bullet-item
102. .ordered-item-list
103. .ordered-item
104. .profile-stats
105. .featured-stat-list
106. .featured-stat
107. .featured-stat-box
108. .achievement-box
109. .level-progress-box
110. .level-progress-badge
111. .exp-line-list
112. .exp-line
113. .account-stat-box
114. .reference-item-list
115. .reference-item
116. .reference-bullet
117. .country-stat-list
118. .country-stat
119. .full-width-image
120. .totals-line-list
121. .totals-line
122. .upload-box
123. .draggable-items
124. .draggable-item
125. .switch-option-list
126. .switch-option
127. .notification-box-list
128. .notification-box
129. .create-entity-box
130. .earning-stat-box
131. .status-info
-------------------------------------------------------------------*/
/*--------------------
    1. LINED TEXT
--------------------*/
.lined-text {
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  position: relative;
}

.lined-text::before, .lined-text::after {
  content: '';
  width: 70px;
  height: 1px;
  background-color: #eaeaf5;
  position: absolute;
  top: 7px;
}

.lined-text::before {
  left: 0;
}

.lined-text::after {
  right: 0;
}

/*-----------------------
    2. SOCIAL LINKS
-----------------------*/
.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.social-links.align-left {
  justify-content: flex-start;
}

.social-links.small .social-link {
  margin-right: 8px;
}

.social-links.small .social-link:last-child {
  margin-right: 0;
}

.social-links.vertical {
  flex-direction: column;
}

.social-links.vertical .social-link {
  margin: 0 0 16px 0;
}

.social-links.vertical .social-link:last-child {
  margin: 0;
}

.social-links.multiline {
  margin-top: -12px;
}

.social-links.multiline .social-link {
  margin: 12px 12px 0 0;
}

.social-links.multiline .social-link:last-child {
  margin-right: 0;
}

.social-links .social-link {
  margin-right: 12px;
}

.social-links .social-link:last-child {
  margin-right: 0;
}

/*-----------------------
    3. SOCIAL LINK
-----------------------*/
.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: transform .2s ease-in-out;
}

.social-link.small {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.social-link.small .social-link-icon {
  width: 10px;
  height: 10px;
}

.social-link.no-hover:hover {
  transform: translate(0, 0);
}

.social-link:hover {
  transform: translate(0, -4px);
}

.social-link.facebook {
  background-color: #3763d2;
}

.social-link.twitter {
  background-color: #1abcff;
}

.social-link.twitch {
  background-color: #7b5dfa;
}

.social-link.youtube {
  background-color: #fd434f;
}

.social-link.instagram {
  background-color: #f8468d;
}

.social-link.patreon {
  background-color: #ff7a51;
}

.social-link.discord {
  background-color: #7289da;
}

.social-link.google {
  background-color: #ffc529;
}

.social-link.behance {
  background-color: #3486f5;
}

.social-link.deviantart {
  background-color: #32e1d3;
}

.social-link.artstation {
  background-color: #00e194;
}

.social-link.dribbble {
  background-color: #f761ab;
}

.social-link.void {
  background-color: #fff;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.12);
}

.social-link.void.facebook .icon-facebook {
  fill: #3763d2;
}

.social-link.void.twitter .icon-twitter {
  fill: #1abcff;
}

/*-----------------------
    4. TAB SWITCH
-----------------------*/
.tab-switch {
  display: flex;
  justify-content: center;
}

.tab-switch .tab-switch-button:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.tab-switch .tab-switch-button:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

/*---------------------------
    5. TAB SWITCH BUTTON
---------------------------*/
.tab-switch-button {
  width: 180px;
  height: 54px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 54px;
  text-align: center;
  cursor: pointer;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.tab-switch-button.active {
  color: #3e3f5e;
  background-color: #fff;
  cursor: auto;
}

/*-----------------------------
    6. ACTION REQUEST LIST
-----------------------------*/
.action-request-list {
  display: flex;
}

.action-request-list .action-request {
  margin-right: 12px;
}

.action-request-list .action-request:last-child {
  margin-right: 0;
}

/*------------------------
    7. ACTION REQUEST
------------------------*/
.action-request {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #dedeea;
  border-radius: 10px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  transition: border-color .2s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out;
}

.action-request.with-text {
  width: auto;
  padding: 0 32px 0 22px;
}

.action-request.with-text .action-request-icon {
  margin-right: 12px;
}

.action-request:hover {
  color: #fff;
}

.action-request:hover .action-request-icon {
  fill: #fff;
  opacity: 1;
}

.action-request.accept:hover {
  background-color: #615dfa;
  border-color: #615dfa;
}

.action-request.decline:hover {
  background-color: #fd434f;
  border-color: #fd434f;
}

.action-request .action-request-icon {
  fill: #adafca;
  opacity: .6;
  transition: fill .2s ease-in-out, opacity .2s ease-in-out;
}

/*-----------------------------
    8. BURGER ICON
-----------------------------*/
.burger-icon {
  width: 20px;
  height: 14px;
  position: relative;
}

.burger-icon.open .burger-icon-bar:nth-child(1) {
  width: 100%;
}

.burger-icon.open .burger-icon-bar:nth-child(2) {
  width: 14px;
}

.burger-icon.open .burger-icon-bar:nth-child(3) {
  width: 10px;
}

.burger-icon.inverted .burger-icon-bar {
  left: 0;
}

.burger-icon.inverted .burger-icon-bar:nth-child(1) {
  width: 100%;
}

.burger-icon.inverted .burger-icon-bar:nth-child(2) {
  width: 14px;
}

.burger-icon.inverted .burger-icon-bar:nth-child(3) {
  width: 10px;
}

.burger-icon .burger-icon-bar {
  height: 2px;
  background-color: #fff;
  position: absolute;
  right: 0;
  transition: width .4s ease-in-out;
}

.burger-icon .burger-icon-bar:nth-child(1) {
  top: 0;
  width: 10px;
}

.burger-icon .burger-icon-bar:nth-child(2) {
  top: 6px;
  width: 14px;
}

.burger-icon .burger-icon-bar:nth-child(3) {
  top: 12px;
  width: 100%;
}

/*-----------------------------
    9. ACTION LIST
-----------------------------*/
.action-list {
  display: flex;
  padding: 0 18px;
  position: relative;
}

.action-list::after, .action-list::before {
  content: '';
  width: 1px;
  height: 32px;
  background-color: #dedeea;
  position: absolute;
}

.action-list::after {
  left: 0;
}

.action-list::before {
  right: 0;
}

.action-list.dark::after, .action-list.dark::before {
  background-color: #7a77fd;
}

.action-list.dark .action-list-item:hover .action-list-item-icon, .action-list.dark .action-list-item.active .action-list-item-icon, .action-list.dark .action-list-item.unread .action-list-item-icon {
  fill: #fff;
}

.action-list.dark .action-list-item .action-list-item-icon {
  fill: #8b88ff;
}

.action-list .action-list-item {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 14px;
  cursor: pointer;
}

.action-list .action-list-item:hover .action-list-item-icon, .action-list .action-list-item.active .action-list-item-icon, .action-list .action-list-item.unread .action-list-item-icon {
  fill: #3e3f5e;
}

.action-list .action-list-item.unread {
  position: relative;
}

.action-list .action-list-item.unread::after {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #41efff;
  position: absolute;
  top: 26px;
  right: 10px;
}

.action-list .action-list-item .action-list-item-icon {
  fill: #adafca;
  transition: fill .3s ease-in-out;
}

/*-----------------------------
    10. ACTION ITEM
-----------------------------*/
.action-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.action-item .action-item-icon {
  fill: #adafca;
}

.action-item.dark .action-item-icon {
  fill: #fff;
}

/*-----------------------------
    11. USER STATS
-----------------------------*/
.user-stats {
  display: flex;
  justify-content: center;
}

.user-stats .user-stat {
  position: relative;
}

.user-stats .user-stat.big::after {
  top: 8px;
}

.user-stats .user-stat::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 6px;
  right: 0;
}

.user-stats .user-stat:last-child::after {
  display: none;
}

/*-----------------------------
    12. USER STAT
-----------------------------*/
.user-stat {
  padding: 0 24px;
}

.user-stat.big {
  padding: 0 30px;
}

.user-stat.big .reference-bullet {
  margin: 0 auto 20px;
}

.user-stat.big .user-stat-title {
  font-size: 1.375rem;
}

.user-stat.big .user-stat-text {
  font-size: 0.75rem;
}

.user-stat .user-stat-title,
.user-stat .user-stat-text {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.user-stat .user-stat-title {
  font-size: 0.875rem;
}

.user-stat .user-stat-title .user-stat-title-icon.positive {
  fill: #23d2e2;
}

.user-stat .user-stat-title .user-stat-title-icon.negative {
  fill: #f9515c;
}

.user-stat .user-stat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-stat .user-stat-icon + .user-stat-text {
  margin-top: 12px;
}

.user-stat .user-stat-image {
  display: block;
  width: 24px;
  height: 16px;
}

.user-stat .user-stat-image + .user-stat-text {
  margin-top: 15px;
}

.user-stat .user-stat-text {
  margin-top: 10px;
  color: #adafca;
  font-size: 0.6875rem;
}

.user-stat .rating-list {
  margin-top: 10px;
}

/*-----------------------------
    13. BADGE LIST
-----------------------------*/
.badge-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 32px);
  grid-gap: 14px;
}

.badge-list.align-left {
  justify-content: flex-start;
}

.badge-list.small {
  grid-gap: 8px;
}

/*-----------------------------
    14. BADGE ITEM
-----------------------------*/
.badge-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 35px;
  position: relative;
}

.badge-item img {
  position: absolute;
  top: 0;
  left: 0;
}

.badge-item .badge-item-text {
  color: #fff;
  font-size: 0.6875rem;
  font-weight: 700;
  position: relative;
  top: 1px;
  z-index: 2;
}

/*-----------------------------
    15. TAG LIST
-----------------------------*/
.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
}

.tag-list .tag-item {
  margin: 12px 12px 0 0;
}

/*-----------------------------
    16. TAG ITEM
-----------------------------*/
.tag-item {
  display: block;
  height: 24px;
  padding: 0 12px;
  border-radius: 200px;
  background-color: #3e3f5e;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 24px;
}

.tag-item.secondary {
  background-color: #615dfa;
  transition: background-color .2s ease-in-out;
}

.tag-item.secondary:hover {
  color: #fff;
  background-color: #23d2e2;
}

/*-----------------------------
    17. XM TOOLTIP TEXT
-----------------------------*/
.xm-tooltip-text {
  padding: 0 12px;
  border-radius: 200px;
  background-color: #3e3f5e;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 24px;
}

/*--------------------------------
    18. USER SHORT DESCRIPTION
--------------------------------*/
.user-short-description {
  padding-top: 84px;
  position: relative;
}

.user-short-description.big {
  padding-top: 52px;
}

.user-short-description.big .user-short-description-avatar {
  top: -116px;
  margin-left: -74px;
}

.user-short-description.big .user-short-description-title {
  font-size: 1.5rem;
}

.user-short-description.big .user-short-description-text {
  font-size: 0.75rem;
}

.user-short-description.small {
  padding-top: 62px;
}

.user-short-description.small .user-short-description-avatar {
  margin-left: -50px;
}

.user-short-description.landscape {
  padding: 0 0 0 32px;
}

.user-short-description.landscape .user-short-description-avatar {
  top: 0;
  margin-left: 0;
}

.user-short-description.landscape .user-short-description-title,
.user-short-description.landscape .user-short-description-text {
  text-align: left;
}

.user-short-description.landscape.tiny {
  height: 56px;
  padding-top: 12px;
}

.user-short-description.landscape.tiny .user-short-description-avatar {
  left: -25px;
}

.user-short-description .user-short-description-avatar {
  position: absolute;
  top: -54px;
  left: 50%;
  margin-left: -60px;
}

.user-short-description .user-short-description-title,
.user-short-description .user-short-description-text {
  font-weight: 700;
  text-align: center;
}

.user-short-description .user-short-description-title {
  font-size: 1.125rem;
}

.user-short-description .user-short-description-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.user-short-description .user-short-description-title.small {
  font-size: 0.875rem;
}

.user-short-description .user-short-description-text {
  margin-top: 10px;
  color: #adafca;
  font-size: 0.6875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.user-short-description .user-short-description-text a {
  color: #adafca;
  font-weight: 700;
}

.user-short-description .user-short-description-text a:hover {
  color: #00c7d9;
}

.user-short-description .user-short-description-text.regular {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: none;
}

/*-----------------------------
    19. SLIDER CONTROL
-----------------------------*/
.slider-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slider-control.negative .slider-control-icon {
  fill: #fff;
}

.slider-control.negative.disabled, .slider-control.negative[aria-disabled=true] {
  opacity: .4;
}

.slider-control.negative.disabled:hover .slider-control-icon, .slider-control.negative[aria-disabled=true]:hover .slider-control-icon {
  fill: #fff;
  opacity: .4;
}

.slider-control.negative:hover .slider-control-icon {
  fill: #fff;
  opacity: 1;
}

.slider-control.disabled, .slider-control[aria-disabled=true] {
  opacity: .5;
}

.slider-control.disabled:hover .slider-control-icon, .slider-control[aria-disabled=true]:hover .slider-control-icon {
  fill: #adafca;
  opacity: .5;
}

.slider-control .slider-control-icon {
  fill: #adafca;
  pointer-events: none;
  opacity: .6;
  transition: fill .2s ease-in-out, opacity .2s ease-in-out;
}

.slider-control.left .slider-control-icon {
  transform: rotate(180deg);
}

.slider-control:hover .slider-control-icon {
  fill: #3e3f5e;
  opacity: 1;
}

/*-----------------------------
    20. SLIDER ROSTER
-----------------------------*/
.slider-roster {
  display: flex;
}

.slider-roster .slider-roster-item {
  margin-right: 8px;
}

.slider-roster .slider-roster-item:last-child {
  margin-right: 0;
}

/*-----------------------------
    21. SLIDER ROSTER ITEM
-----------------------------*/
.slider-roster-item {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #3e3f5e;
  opacity: .2;
  cursor: pointer;
}

.slider-roster-item.tns-nav-active {
  opacity: 1;
}

/*-----------------------------
    22. TAG STICKER
-----------------------------*/
.tag-sticker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.1);
}

.tag-sticker .tag-sticker-icon {
  fill: #3e3f5e;
}

.tag-sticker .tag-sticker-icon.primary {
  fill: #23d2e2;
}

/*-----------------------------
    23. TEXT STICKER
-----------------------------*/
.text-sticker {
  height: 32px;
  padding: 0 14px;
  border-radius: 200px;
  background-color: #fff;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.12);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 32px;
}

.text-sticker.round {
  border-radius: 12px;
}

.text-sticker .highlighted {
  color: #00c7d9;
}

.text-sticker .text-sticker-icon {
  margin-right: 4px;
  fill: #00c7d9;
}

.text-sticker.small-text {
  font-size: 0.75rem;
}

.text-sticker.small {
  height: 24px;
  padding: 0 12px;
  font-size: 0.75rem;
  line-height: 24px;
}

.text-sticker.medium {
  height: 44px;
  padding: 0 16px;
  line-height: 44px;
}

.text-sticker.negative {
  color: #fff;
  background: #15151f;
}

.text-sticker.void {
  box-shadow: none;
  background-color: transparent;
}

/*-----------------------------
    24. DATE STICKER
-----------------------------*/
.date-sticker {
  width: 44px;
}

.date-sticker .date-sticker-day,
.date-sticker .date-sticker-month {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.date-sticker .date-sticker-day {
  padding: 10px 0 6px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #3e3f5e;
  font-size: 1.25rem;
}

.date-sticker .date-sticker-month {
  padding: 4px 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #23d2e2;
  font-size: 0.75rem;
}

/*-----------------------------
    25. DECORATED TEXT
-----------------------------*/
.decorated-text {
  display: flex;
  align-items: center;
}

.decorated-text .decorated-text-icon {
  margin-right: 8px;
  fill: #23d2e2;
}

.decorated-text .decorated-text-content {
  font-size: 0.875rem;
  font-weight: 500;
}

/*-----------------------------
    26. SIMPLE DROPDOWN
-----------------------------*/
.simple-dropdown {
  width: 140px;
  padding: 10px 0;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.12);
}

.simple-dropdown.padded {
  padding: 12px 16px 16px;
}

.simple-dropdown .simple-dropdown-link {
  padding: 8px 16px;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  transition: color .2s ease-in-out, padding-left .2s ease-in-out;
}

.simple-dropdown .simple-dropdown-link:hover {
  padding-left: 20px;
  color: #00c7d9;
}

.simple-dropdown .simple-dropdown-text {
  font-size: 0.75rem;
  font-weight: 500;
}

.simple-dropdown .simple-dropdown-text + .simple-dropdown-text {
  margin-top: 4px;
}

.simple-dropdown .simple-dropdown-text .reaction {
  margin-right: 4px;
}

.simple-dropdown .simple-dropdown-text .bold {
  font-weight: 700;
}

/*-----------------------------
    27. PARAGRAPH
-----------------------------*/
.paragraph {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

/*--------------------------------
    28. INFORMATION LINE LIST
--------------------------------*/
.information-line-list .information-line {
  margin-bottom: 14px;
}

.information-line-list .information-line:last-child {
  margin-bottom: 0;
}

/*-----------------------------
    29. INFORMATION LINE
-----------------------------*/
.information-line {
  display: flex;
}

.information-line .information-line-title,
.information-line .information-line-text {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.information-line .information-line-title {
  flex-shrink: 0;
  width: 80px;
  color: #8f91ac;
}

.information-line .information-line-text .bold {
  font-weight: 700;
}

/*---------------------------------
    30. INFORMATION BLOCK LIST
---------------------------------*/
.information-block-list .information-block {
  margin-bottom: 26px;
}

.information-block-list .information-block:last-child {
  margin-bottom: 0;
}

/*-----------------------------
    31. INFORMATION BLOCK
-----------------------------*/
.information-block .information-block-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.information-block .information-block-text {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

/*------------------------------------
    32. TIMELINE INFORMATION LIST
------------------------------------*/
.timeline-information-list .timeline-information {
  padding-bottom: 24px;
}

.timeline-information-list .timeline-information:last-child {
  padding-bottom: 0;
}

.timeline-information-list .timeline-information:last-child::before {
  display: none;
}

/*---------------------------------
    33. TIMELINE INFORMATION
---------------------------------*/
.timeline-information {
  padding-left: 28px;
  position: relative;
}

.timeline-information::before {
  content: '';
  width: 1px;
  height: 100%;
  background-color: #eaeaf5;
  position: absolute;
  top: 0;
  left: 6px;
}

.timeline-information::after {
  content: '';
  width: 13px;
  height: 13px;
  border: 4px solid #23d2e2;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: 0;
}

.timeline-information .timeline-information-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.timeline-information .timeline-information-date {
  margin-top: 6px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.timeline-information .timeline-information-text {
  margin-top: 8px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

/*---------------------------------
    34. RATING LIST
---------------------------------*/
.rating-list {
  display: flex;
}

.rating-list.form-rating .rating {
  cursor: pointer;
}

.rating-list .rating {
  margin-right: 3px;
}

.rating-list .rating.medium {
  margin-right: 4px;
}

.rating-list .rating:last-child {
  margin-right: 0;
}

.rating-list .rating:last-child.medium {
  margin-right: 0;
}

/*---------------------------------
    35. RATING
---------------------------------*/
.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9px;
  height: 8px;
}

.rating.dark .rating-icon {
  fill: #8f91ac;
}

.rating.dark.filled .rating-icon {
  fill: #fff10d;
}

.rating.medium {
  width: 13px;
  height: 12px;
}

.rating.filled .rating-icon {
  fill: #ffe00d;
}

.rating .rating-icon {
  fill: #eaeaf5;
}

/*---------------------------------
    36. FILTERS
---------------------------------*/
.filters {
  display: flex;
}

.filters .filter {
  margin-right: 20px;
  padding-bottom: 4px;
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 700;
  opacity: .6;
  cursor: pointer;
  transition: color .2s ease-in-out, opacity .2s ease-in-out;
}

.filters .filter.active, .filters .filter:hover {
  color: #3e3f5e;
  opacity: 1;
}

.filters .filter.active {
  border-bottom: 2px solid #23d2e2;
}

.filters .filter:last-child {
  margin-right: 0;
}

/*---------------------------------
    37. FILTER TABS
---------------------------------*/
.filter-tabs {
  display: flex;
}

.filter-tabs .filter-tab::before {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 38px;
  left: 0;
}

.filter-tabs .filter-tab:last-child::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 38px;
  right: 0;
}

/*---------------------------------
    38. FILTER TAB
---------------------------------*/
.filter-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 100%;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  position: relative;
  transition: border-color .2s ease-in-out;
}

.filter-tab.secondary.active {
  border-bottom-color: #615dfa;
}

.filter-tab.active {
  border-bottom-color: #23d2e2;
}

.filter-tab.active .filter-tab-text {
  color: #3e3f5e;
}

.filter-tab .filter-tab-text {
  padding: 4px 0;
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 700;
  transition: color .2s ease-in-out;
}

/*---------------------------------
    39. VIEW ACTIONS
---------------------------------*/
.view-actions {
  display: flex;
}

.view-actions .view-action {
  margin-right: 26px;
}

.view-actions .view-action:last-child {
  margin-right: 0;
}

/*---------------------------------
    40. VIEW ACTION
---------------------------------*/
.view-action {
  cursor: pointer;
}

.view-action.active .view-action-icon, .view-action:hover .view-action-icon {
  fill: #3e3f5e;
  opacity: 1;
}

.view-action .view-action-icon {
  fill: #adafca;
  opacity: .4;
  transition: opacity .2s ease-in-out, fill .2s ease-in-out;
}

/*---------------------------------
    41. TWEETS
---------------------------------*/
.tweets .tweet {
  margin-bottom: 28px;
}

.tweets .tweet:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    42. TWEET
---------------------------------*/
.tweet .tweet-author {
  display: block;
  min-height: 32px;
  padding-left: 40px;
  position: relative;
}

.tweet .tweet-author .tweet-author-image {
  position: absolute;
  top: 0;
  left: 0;
}

.tweet .tweet-author .tweet-author-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.tweet .tweet-author .tweet-author-text {
  margin-top: 6px;
  font-size: 0.75rem;
  font-weight: 500;
}

.tweet .tweet-text {
  display: block;
  margin-top: 16px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

.tweet .tweet-text .highlighted {
  color: #00c7d9;
  font-weight: 600;
}

.tweet .tweet-timestamp {
  margin-top: 12px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

/*---------------------------------
    43. PLAY BUTTON
---------------------------------*/
.play-button {
  width: 46px;
  height: 46px;
  padding: 12px 0 0 15px;
  border: 4px solid #41efff;
  border-radius: 50%;
  background-color: rgba(21, 21, 31, 0.96);
}

.play-button .play-button-icon {
  display: block;
}

/*---------------------------------
    44. IFRAME WRAP
---------------------------------*/
.iframe-wrap {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.iframe-wrap iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/*---------------------------------
    45. POPUP VIDEO
---------------------------------*/
.popup-video {
  width: 80%;
  opacity: 0;
  visibility: hidden;
}

/*---------------------------------
    46. POPUP PICTURE
---------------------------------*/
.popup-picture {
  display: flex;
  width: 80%;
  height: 80%;
  opacity: 0;
  visibility: hidden;
}

.popup-picture .widget-box {
  flex-shrink: 0;
  width: 384px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.popup-picture .widget-box .meta-line.settings {
  margin-left: 22px;
}

.popup-picture .widget-box .meta-line.settings .post-settings {
  width: 22px;
}

.popup-picture .widget-box .widget-box-scrollable {
  overflow: hidden auto;
}

.popup-picture .widget-box .post-comment.reply-2 {
  padding-left: 96px;
}

.popup-picture .widget-box .post-comment.reply-2 .user-avatar {
  left: 42px;
}

.popup-picture .popup-picture-image-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #15151f;
}

.popup-picture .popup-picture-image-wrap .popup-picture-image {
  width: 100%;
  height: auto;
}

/*---------------------------------
    47. POPUP EVENT
---------------------------------*/
.popup-event {
  width: 90%;
  max-width: 584px;
  border-radius: 12px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
}

.popup-event .popup-event-cover {
  width: 100%;
  height: 204px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.popup-event .popup-event-info {
  padding: 32px 28px;
  position: relative;
}

.popup-event .popup-event-info .user-avatar-list {
  margin-top: 18px;
}

.popup-event .popup-event-info .g-map {
  height: 260px;
  margin-top: 22px;
}

.popup-event .popup-event-title {
  font-size: 1.5rem;
  font-weight: 700;
}

.popup-event .popup-event-subtitle {
  margin-top: 32px;
  font-size: 1rem;
  font-weight: 700;
}

.popup-event .popup-event-text {
  margin-top: 16px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
  color: #3e3f5e;
}

.popup-event .decorated-feature-list {
  margin-top: 14px;
}

.popup-event .popup-event-button {
  width: 200px;
  position: absolute;
  top: -30px;
  right: 28px;
}

.popup-event > .content-actions {
  border-top: 1px solid #eaeaf5;
  margin: 0 28px;
}

/*---------------------------------
    48. POPUP CLOSE BUTTON
---------------------------------*/
.popup-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #45437f;
  cursor: pointer;
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 2;
  transition: background-color .2s ease-in-out;
}

.popup-close-button:hover {
  background-color: #23d2e2;
}

.popup-close-button .popup-close-button-icon {
  pointer-events: none;
}

/*---------------------------------
    49. DECORATED FEATURE LIST
---------------------------------*/
.decorated-feature-list {
  margin-top: -18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.decorated-feature-list .decorated-feature {
  width: 50%;
  margin-top: 18px;
}

/*---------------------------------
    50. DECORATED FEATURE
---------------------------------*/
.decorated-feature {
  display: flex;
}

.decorated-feature .decorated-feature-icon {
  margin-top: 6px;
  flex-shrink: 0;
  fill: #23d2e2;
}

.decorated-feature .decorated-feature-info {
  margin-left: 12px;
}

.decorated-feature .decorated-feature-title {
  font-size: 1rem;
  font-weight: 700;
}

.decorated-feature .decorated-feature-text {
  margin-top: 2px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

/*---------------------------------
    51. META LINE
---------------------------------*/
.meta-line {
  display: flex;
  align-items: center;
}

.meta-line + .meta-line {
  margin-left: 22px;
}

.meta-line .meta-line-list + .meta-line-text {
  margin-left: 10px;
}

.meta-line.settings {
  margin-left: 14px;
}

.meta-line .meta-line-text {
  font-size: 0.75rem;
  font-weight: 700;
}

.meta-line .meta-line-text a {
  color: #3e3f5e;
  font-weight: 700;
}

.meta-line .meta-line-link {
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  transition: color .2s ease-in-out;
}

.meta-line .meta-line-link.light {
  color: #adafca;
}

.meta-line .meta-line-link:hover {
  color: #00c7d9;
}

.meta-line .meta-line-timestamp {
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

/*---------------------------------
    52. REACTION OPTIONS
---------------------------------*/
.reaction-options {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 16px;
  border-radius: 200px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.12);
}

.reaction-options.small {
  height: 40px;
}

.reaction-options.small .reaction-option {
  width: 20px;
  height: 20px;
}

.reaction-options .reaction-option {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  cursor: pointer;
  transition: transform .2s ease-in-out;
}

.reaction-options .reaction-option:last-child {
  margin-right: 0;
}

.reaction-options .reaction-option:hover {
  transform: translate(0, -4px);
}

.reaction-options .reaction-option .reaction-option-image {
  width: 100%;
  height: 100%;
}

/*---------------------------------
    53. POST OPTIONS
---------------------------------*/
.post-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0 28px;
  border-top: 1px solid #eaeaf5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fcfcfd;
}

/*---------------------------------
    54. POST OPTION
---------------------------------*/
.post-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 44px;
  border-radius: 12px;
  cursor: pointer;
  transition: box-shadow .2s ease-in-out, background-color .2s ease-in-out;
}

.post-option.no-text {
  width: 80px;
}

.post-option.no-text .post-option-icon {
  margin-right: 0;
}

.post-option.active, .post-option:hover {
  background-color: #fff;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.1);
}

.post-option.active .post-option-icon, .post-option:hover .post-option-icon {
  fill: #23d2e2;
  opacity: 1;
}

.post-option.active .post-option-text, .post-option:hover .post-option-text {
  color: #3e3f5e;
}

.post-option .post-option-icon {
  margin-right: 16px;
  fill: #adafca;
  opacity: .6;
  transition: fill .2s ease-in-out, opacity .2s ease-in-out;
}

.post-option .post-option-icon.icon-thumbs-up {
  position: relative;
  top: -2px;
}

.post-option .post-option-text {
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  transition: color .2s ease-in-out;
}

/*---------------------------------
    55. POST SETTINGS
---------------------------------*/
.post-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  cursor: pointer;
}

.post-settings .post-settings-icon {
  fill: #adafca;
  opacity: .4;
  transition: opacity .2s ease-in-out, fill .2s ease-in-out;
}

.post-settings.active .post-settings-icon, .post-settings:hover .post-settings-icon {
  fill: #3e3f5e;
  opacity: 1;
}

/*---------------------------------
    56. SLIDER LINE
---------------------------------*/
.slider-line {
  height: 120px;
  padding: 0 48px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.slider-line.small {
  height: 100px;
}

.slider-line.small .slider-controls {
  top: 40px;
}

.slider-line.small .slider-slides .user-stat {
  height: 100px;
}

.slider-line.medium {
  height: 160px;
}

.slider-line.medium .slider-controls {
  top: 70px;
}

.slider-line.medium .slider-slides.with-separator .slider-slide::before {
  height: 40px;
  top: 60px;
}

.slider-line.medium .slider-slides.with-separator .slider-slide:last-child::after {
  height: 40px;
  top: 60px;
}

.slider-line.medium .slider-slides .reaction-stat {
  height: 160px;
}

.slider-line .slider-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 14px;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1;
}

.slider-line .slider-slides {
  position: relative;
  z-index: 2;
}

.slider-line .slider-slides.with-separator .slider-slide {
  position: relative;
}

.slider-line .slider-slides.with-separator .slider-slide::before {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 40px;
  left: 0;
}

.slider-line .slider-slides.with-separator .slider-slide:last-child::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 40px;
  right: 0;
}

.slider-line .slider-slides .stat-block {
  height: 120px;
  padding-left: 28px;
  align-items: center;
}

.slider-line .slider-slides .user-stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider-line .slider-slides .reaction-stat {
  justify-content: center;
}

/*---------------------------------
    57. STAT BLOCK LIST
---------------------------------*/
.stat-block-list .stat-block {
  margin-bottom: 22px;
}

.stat-block-list .stat-block:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    58. STAT BLOCK
---------------------------------*/
.stat-block {
  display: flex;
  min-height: 48px;
}

.stat-block .stat-block-decoration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: linear-gradient(-145deg, #41efff, #615dfa);
  margin-right: 16px;
}

.stat-block .stat-block-decoration .stat-block-decoration-icon {
  fill: #fff;
}

.stat-block .stat-block-info {
  margin-top: 6px;
}

.stat-block .stat-block-info .stat-block-title {
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.stat-block .stat-block-info .stat-block-text {
  margin-top: 8px;
  font-size: 1rem;
  font-weight: 700;
}

/*---------------------------------
    59. ACHIEVEMENT STATUS LIST
---------------------------------*/
.achievement-status-list {
  display: flex;
}

.achievement-status-list .achievement-status {
  width: 50%;
}

.achievement-status-list .achievement-status .achievement-status-info {
  position: relative;
}

.achievement-status-list .achievement-status .achievement-status-info::after {
  content: '';
  width: 1px;
  height: 100%;
  background-color: #eaeaf5;
  position: absolute;
  top: 0;
  right: 0;
}

.achievement-status-list .achievement-status:last-child .achievement-status-info::after {
  display: none;
}

/*---------------------------------
    60. ACHIEVEMENT STATUS
---------------------------------*/
.achievement-status {
  text-align: center;
}

.achievement-status .achievement-status-progress {
  font-size: 1.375rem;
  font-weight: 700;
}

.achievement-status .achievement-status-info {
  margin-top: 14px;
}

.achievement-status .achievement-status-info .achievement-status-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.achievement-status .achievement-status-info .achievement-status-text {
  margin-top: 6px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.achievement-status .achievement-status-image {
  display: block;
  margin: 22px auto 0;
}

/*---------------------------------
    61. PAGE LOADER
---------------------------------*/
.page-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3e3f5e;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
}

.page-loader.hidden {
  opacity: 0;
  visibility: hidden;
}

.page-loader .page-loader-decoration {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 28px;
  background-color: #615dfa;
}

.page-loader .page-loader-decoration .icon-logo {
  fill: #fff;
  width: 36px;
  height: 52px;
}

.page-loader .page-loader-info .page-loader-info-title,
.page-loader .page-loader-info .page-loader-info-text {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.page-loader .page-loader-info .page-loader-info-title {
  margin-top: 24px;
  font-family: "Titillium Web", sans-serif;
  font-size: 1.75rem;
  font-weight: 900;
}

.page-loader .page-loader-info .page-loader-info-text {
  margin-top: 10px;
  font-size: 0.75rem;
  font-weight: 700;
}

.page-loader .page-loader-indicator {
  margin-top: 60px;
}

/*---------------------------------
    62. REACTION COUNT LIST
---------------------------------*/
.reaction-count-list {
  display: flex;
  flex-direction: column;
}

.reaction-count-list.landscape {
  flex-direction: row;
}

.reaction-count-list.landscape .reaction-count {
  margin: 0 32px 0 0;
}

.reaction-count-list.landscape .reaction-count:last-child {
  margin: 0;
}

.reaction-count-list .reaction-count {
  margin-bottom: 22px;
}

.reaction-count-list .reaction-count:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    63. REACTION COUNT
---------------------------------*/
.reaction-count {
  display: flex;
  align-items: center;
}

.reaction-count.negative .reaction-count-icon {
  fill: #41efff;
}

.reaction-count.negative .reaction-count-text {
  color: #fff;
}

.reaction-count .reaction-count-icon {
  fill: #23d2e2;
}

.reaction-count .reaction-count-text {
  margin-left: 16px;
  font-size: 0.75rem;
  font-weight: 700;
  position: relative;
  top: 1px;
}

/*---------------------------------
    64. UPLOAD ITEM LIST
---------------------------------*/
.upload-item-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 168px);
  grid-gap: 16px 22px;
  justify-content: center;
}

/*---------------------------------
    65. UPLOAD ITEM
---------------------------------*/
.upload-item:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 168px;
  background-color: #23d2e2;
  border-radius: 12px;
  cursor: pointer;
}

.upload-item:first-child .icon-plus {
  pointer-events: none;
}

.upload-item .upload-item-image {
  width: 100%;
  height: 168px;
  border-radius: 12px;
}

.upload-item .form-input,
.upload-item .checkbox-wrap {
  margin-top: 16px;
}

/*---------------------------------
    66. SIMPLE ACCORDION LIST
---------------------------------*/
.simple-accordion-list .simple-accordion {
  margin-bottom: 22px;
}

.simple-accordion-list .simple-accordion:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    67. SIMPLE ACCORDION
---------------------------------*/
.simple-accordion .simple-accordion-header {
  position: relative;
}

.simple-accordion .simple-accordion-header.selected .simple-accordion-icon .icon-plus-small {
  display: none;
}

.simple-accordion .simple-accordion-header.selected .simple-accordion-icon .icon-minus-small {
  display: block;
}

.simple-accordion .simple-accordion-header .simple-accordion-title {
  padding-right: 16px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}

.simple-accordion .simple-accordion-header .simple-accordion-icon {
  position: absolute;
  top: 4px;
  right: 0;
  cursor: pointer;
}

.simple-accordion .simple-accordion-header .simple-accordion-icon .icon-plus-small,
.simple-accordion .simple-accordion-header .simple-accordion-icon .icon-minus-small {
  fill: #23d2e2;
}

.simple-accordion .simple-accordion-header .simple-accordion-icon .icon-plus-small {
  display: block;
}

.simple-accordion .simple-accordion-header .simple-accordion-icon .icon-minus-small {
  display: none;
}

.simple-accordion .simple-accordion-header .simple-accordion-content {
  margin-top: 20px;
}

.simple-accordion .simple-accordion-header .simple-accordion-content .simple-accordion-text {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

/*---------------------------------
    68. WEEK BOX
---------------------------------*/
.week-box {
  display: flex;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.1);
}

.week-box .week-box-item {
  width: 14.2857142857%;
  position: relative;
}

.week-box .week-box-item::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 40px;
  right: 0;
}

.week-box .week-box-item:last-child::after {
  display: none;
}

/*---------------------------------
    69. WEEK BOX ITEM
---------------------------------*/
.week-box-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.week-box-item.active {
  border-bottom: 4px solid #23d2e2;
}

.week-box-item.active .week-box-item-title {
  color: #00c7d9;
}

.week-box-item.active .week-box-item-text {
  color: #3e3f5e;
}

.week-box-item .week-box-item-title,
.week-box-item .week-box-item-text {
  font-weight: 700;
  text-transform: uppercase;
}

.week-box-item .week-box-item-title {
  font-size: 1.375rem;
}

.week-box-item .week-box-item-text {
  margin-top: 10px;
  color: #adafca;
  font-size: 0.75rem;
}

/*---------------------------------
    70. FORUM CATEGORY
---------------------------------*/
.forum-category {
  display: flex;
  align-items: center;
}

.forum-category .forum-category-image {
  flex-shrink: 0;
}

.forum-category .forum-category-info {
  margin-left: 28px;
}

.forum-category .forum-category-info .forum-category-title {
  font-size: 1.125rem;
  font-weight: 700;
}

.forum-category .forum-category-info .forum-category-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.forum-category .forum-category-info .forum-category-text {
  margin-top: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.forum-category .forum-category-info .forum-category-link {
  margin-top: 16px;
  font-size: 0.875rem;
  font-weight: 700;
}

.forum-category .forum-category-info .forum-category-link a {
  color: #3e3f5e;
  font-weight: 700;
}

.forum-category .forum-category-info .forum-category-link a:hover {
  color: #00c7d9;
}

/*---------------------------------
    71. DISCUSSION PREVIEW
---------------------------------*/
.discussion-preview.pinned .discussion-preview-title {
  display: flex;
  align-items: center;
}

.discussion-preview.pinned .discussion-preview-title::before {
  content: 'Pinned';
  height: 20px;
  padding: 0 8px;
  margin-right: 12px;
  border-radius: 200px;
  background-color: #23d2e2;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
}

.discussion-preview .discussion-preview-title {
  font-size: 1rem;
  font-weight: 700;
}

.discussion-preview .discussion-preview-title + .discussion-preview-meta {
  margin-top: 8px;
}

.discussion-preview .page-items {
  margin-top: 12px;
}

.discussion-preview .discussion-preview-meta {
  margin-top: 14px;
  display: flex;
  align-items: center;
}

.discussion-preview .discussion-preview-meta .discussion-preview-meta-text {
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
}

.discussion-preview .discussion-preview-meta .discussion-preview-meta-text a {
  color: #3e3f5e;
  font-weight: 700;
}

.discussion-preview .discussion-preview-meta .discussion-preview-meta-text a.highlighted {
  color: #00c7d9;
}

.discussion-preview .discussion-preview-meta .discussion-preview-meta-text .separator {
  margin: 0 8px;
}

.discussion-preview .discussion-preview-meta .user-avatar {
  margin: 0 6px;
}

/*---------------------------------
    72. PAGE ITEMS
---------------------------------*/
.page-items {
  display: flex;
}

.page-items .page-item {
  margin-right: 6px;
}

.page-items .page-item:last-child {
  margin-right: 0;
}

/*---------------------------------
    73. PAGE ITEM
---------------------------------*/
.page-item {
  height: 24px;
  padding: 0 8px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 3px 10px 0 rgba(94, 92, 154, 0.12);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 24px;
}

.page-item.void {
  box-shadow: none;
}

/*---------------------------------
    74. QUICK POST
---------------------------------*/
.quick-post {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.quick-post.medium .quick-post-header {
  display: flex;
  align-items: center;
  min-height: 82px;
  padding-left: 28px;
}

.quick-post.medium .quick-post-body .form-textarea textarea {
  height: 200px;
}

.quick-post.medium .quick-post-footer {
  min-height: 92px;
}

.quick-post.medium .quick-post-footer .quick-post-footer-actions .button {
  width: 120px;
}

.quick-post.medium .quick-post-footer .quick-post-footer-actions .button:last-child {
  width: 140px;
}

.quick-post .quick-post-header {
  border-bottom: 1px solid #eaeaf5;
}

.quick-post .quick-post-header .quick-post-header-title {
  font-size: 1rem;
  font-weight: 700;
}

.quick-post .quick-post-header .option-items {
  height: 65px;
  background-color: #fff;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.quick-post .quick-post-header .option-items .option-item {
  width: 33.3333333333%;
}

.quick-post .quick-post-header .option-items .option-item::after {
  top: 22px;
}

.quick-post .quick-post-body .form-textarea {
  background-color: #fcfcfd;
}

.quick-post .quick-post-body .form-textarea textarea {
  height: 120px;
  background-color: #fcfcfd;
  border-radius: 0;
  border: none;
}

.quick-post .quick-post-body .form-textarea textarea::placeholder {
  font-weight: 500;
}

.quick-post .quick-post-body .form-textarea .form-textarea-limit-text {
  color: #adafca;
  padding-top: 10px;
  height: 40px;
}

.quick-post .quick-post-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 76px;
  padding: 0 28px;
  border-top: 1px solid #eaeaf5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.quick-post .quick-post-footer .quick-post-footer-actions {
  display: flex;
  align-items: center;
}

.quick-post .quick-post-footer .quick-post-footer-actions .quick-post-footer-action {
  margin-right: 22px;
}

.quick-post .quick-post-footer .quick-post-footer-actions .quick-post-footer-action:last-child {
  margin-right: 0;
}

.quick-post .quick-post-footer .quick-post-footer-actions .button {
  width: 80px;
  margin-right: 6px;
}

.quick-post .quick-post-footer .quick-post-footer-actions .button:last-child {
  margin-right: 0;
}

.quick-post .quick-post-footer .quick-post-footer-action {
  cursor: pointer;
}

.quick-post .quick-post-footer .quick-post-footer-action:hover .quick-post-footer-action-icon {
  fill: #3e3f5e;
}

.quick-post .quick-post-footer .quick-post-footer-action .quick-post-footer-action-icon {
  transition: fill .2s ease-in-out;
}

/*---------------------------------
    75. OPTION ITEMS
---------------------------------*/
.option-items {
  display: flex;
}

.option-items .option-item {
  position: relative;
}

.option-items .option-item::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 0;
  right: 0;
}

.option-items .option-item:last-child::after {
  display: none;
}

/*---------------------------------
    76. OPTION ITEM
---------------------------------*/
.option-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.option-item.active {
  border-bottom: 4px solid #23d2e2;
}

.option-item.active .option-item-icon {
  fill: #3e3f5e;
}

.option-item.active .option-item-title {
  color: #3e3f5e;
}

.option-item .option-item-icon {
  margin-right: 16px;
  fill: #adafca;
}

.option-item .option-item-title {
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
}

/*---------------------------------
    77. STATS BOX
---------------------------------*/
.stats-box {
  height: 220px;
  padding: 90px 28px 0;
  border-radius: 12px;
}

.stats-box.small {
  height: 142px;
  padding-top: 32px;
}

.stats-box.small .stats-box-value {
  font-size: 1.75rem;
}

.stats-box.small .stats-box-title {
  font-size: 0.875rem;
}

.stats-box.small.stat-profile-views {
  background: url("../img/graph/stat/01.jpg") no-repeat center;
  background-size: cover;
}

.stats-box.small.stat-posts-created {
  background: url("../img/graph/stat/02.jpg") no-repeat center;
  background-size: cover;
}

.stats-box.small.stat-reactions-received {
  background: url("../img/graph/stat/03.jpg") no-repeat center;
  background-size: cover;
}

.stats-box.small.stat-comments-received {
  background: url("../img/graph/stat/04.jpg") no-repeat center;
  background-size: cover;
}

.stats-box.stat-profile-views {
  background: url("../img/graph/stat/01-big.png") no-repeat center;
  background-size: cover;
}

.stats-box.stat-posts-created {
  background: url("../img/graph/stat/02-big.png") no-repeat center;
  background-size: cover;
}

.stats-box.stat-reactions-received {
  background: url("../img/graph/stat/03-big.png") no-repeat center;
  background-size: cover;
}

.stats-box.stat-comments-received {
  background: url("../img/graph/stat/04-big.png") no-repeat center;
  background-size: cover;
}

.stats-box .stats-box-value,
.stats-box .stats-box-diff-value,
.stats-box .stats-box-title,
.stats-box .stats-box-text {
  color: #fff;
}

.stats-box .stats-box-value-wrap {
  display: flex;
  align-items: flex-start;
}

.stats-box .stats-box-value-wrap .stats-box-diff {
  margin-left: 12px;
}

.stats-box .stats-box-value {
  font-size: 2rem;
  font-weight: 700;
}

.stats-box .stats-box-diff {
  margin-left: 12px;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}

.stats-box .stats-box-diff .stats-box-diff-icon {
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.stats-box .stats-box-diff .stats-box-diff-icon .icon-plus-small,
.stats-box .stats-box-diff .stats-box-diff-icon .icon-minus-small {
  width: 6px;
  height: 6px;
}

.stats-box .stats-box-diff .stats-box-diff-icon.positive {
  border-color: #41efff;
}

.stats-box .stats-box-diff .stats-box-diff-icon.positive .icon-plus-small {
  fill: #41efff;
}

.stats-box .stats-box-diff .stats-box-diff-icon.negative {
  border-color: #ff5384;
}

.stats-box .stats-box-diff .stats-box-diff-icon.negative .icon-minus-small {
  fill: #ff5384;
}

.stats-box .stats-box-diff .stats-box-diff-value {
  font-size: 0.75rem;
  font-weight: 700;
  position: relative;
  top: 1px;
}

.stats-box .stats-box-title {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 700;
}

.stats-box .stats-box-text {
  margin-top: 2px;
  font-size: 0.75rem;
  font-weight: 500;
}

/*---------------------------------
    78. STATS BOX SLIDER
---------------------------------*/
.stats-box-slider {
  position: relative;
}

.stats-box-slider .stats-box-slider-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 28px 28px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.stats-box-slider .stats-box-slider-controls .stats-box-slider-controls-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}

.stats-box-slider .stats-box-slider-controls .slider-controls {
  display: flex;
}

.stats-box-slider .stats-box-slider-controls .slider-controls .slider-control:first-child {
  margin-right: 2px;
}

/*---------------------------------
    79. REACTION STATS
---------------------------------*/
.reaction-stats {
  display: flex;
  justify-content: center;
}

.reaction-stats .reaction-stat {
  width: 114px;
  position: relative;
}

.reaction-stats .reaction-stat::after {
  content: '';
  width: 1px;
  height: 40px;
  background-color: #eaeaf5;
  position: absolute;
  top: 32px;
  right: 0;
}

.reaction-stats .reaction-stat:last-child::after {
  display: none;
}

/*---------------------------------
    80. REACTION STAT
---------------------------------*/
.reaction-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reaction-stat .reaction-stat-image {
  width: 40px;
  height: 40px;
}

.reaction-stat .reaction-stat-title {
  margin-top: 16px;
  font-size: 1.375rem;
  font-weight: 700;
}

.reaction-stat .reaction-stat-text {
  margin-top: 8px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

/*---------------------------------
    81. SIMPLE TAB ITEMS
---------------------------------*/
.simple-tab-items {
  display: flex;
  border-bottom: 1px solid #eaeaf5;
}

.simple-tab-items .simple-tab-item {
  margin-right: 40px;
}

.simple-tab-items .simple-tab-item:last-child {
  margin-right: 0;
}

.simple-tab-items .form {
  display: none;
}

/*---------------------------------
    82. SIMPLE TAB ITEM
---------------------------------*/
.simple-tab-item {
  height: 36px;
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 700;
  opacity: .6;
  cursor: pointer;
}

.simple-tab-item.active {
  border-bottom: 4px solid #23d2e2;
  color: #3e3f5e;
  opacity: 1;
}

/*---------------------------------
    83. BANNER PROMO
---------------------------------*/
.banner-promo {
  display: block;
  width: 100%;
  max-width: 284px;
  height: auto;
  border-radius: 12px;
  margin: 0 auto;
}

.banner-promo img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

/*---------------------------------
    84. POLL BOX
---------------------------------*/
.poll-box {
  padding: 32px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.1);
}

.poll-box .poll-title {
  font-size: 1rem;
  font-weight: 700;
}

.poll-box .poll-text {
  margin-top: 4px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
  color: #8f91ac;
}

.poll-box .form,
.poll-box .poll-results {
  margin-top: 28px;
}

.poll-box .poll-box-actions {
  margin-top: 40px;
}

.poll-box .poll-box-actions .button {
  width: 140px;
  margin-right: 16px;
}

.poll-box .poll-box-actions .button:last-child {
  margin-right: 0;
}

/*---------------------------------
    85. POLL RESULTS
---------------------------------*/
.poll-results .poll-result {
  margin-bottom: 22px;
}

.poll-results .poll-result:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    86. POLL RESULT
---------------------------------*/
.poll-result .progress-stat {
  max-width: 472px;
}

.poll-result .meta-line {
  margin-top: 10px;
}

/*---------------------------------
    87. PICTURE COLLAGE
---------------------------------*/
.picture-collage .picture-collage-row {
  display: flex;
  margin-bottom: 6px;
}

.picture-collage .picture-collage-row:last-child {
  margin-bottom: 0;
}

.picture-collage .picture-collage-row.medium .picture-collage-item {
  width: 261px;
  height: 240px;
}

.picture-collage .picture-collage-row .picture-collage-item {
  width: 172px;
  height: 160px;
  margin-right: 6px;
  position: relative;
  cursor: pointer;
}

.picture-collage .picture-collage-row .picture-collage-item:last-child {
  margin-right: 0;
}

.picture-collage .picture-collage-row .picture-collage-item .photo-preview {
  width: 100%;
  height: 100%;
}

.picture-collage .picture-collage-row .picture-collage-item .picture-collage-item-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: rgba(35, 210, 226, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.picture-collage .picture-collage-row .picture-collage-item .picture-collage-item-overlay .picture-collage-item-overlay-text {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
}

/*---------------------------------
    88. QUOTE BOX
---------------------------------*/
.quote-box {
  padding: 26px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.1);
  position: relative;
}

.quote-box .quote-box-icon {
  fill: #23d2e2;
  opacity: .1;
  position: absolute;
  top: 14px;
  left: 14px;
}

.quote-box .quote-box-text {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

/*---------------------------------
    89. STATS DECORATION
---------------------------------*/
.stats-decoration {
  height: 96px;
  padding: 28px 0 0 88px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.stats-decoration.secondary {
  background: url("../img/graph/stat/05.png") no-repeat right bottom #fff;
}

.stats-decoration.secondary .stats-decoration-icon-wrap {
  background-color: #615dfa;
}

.stats-decoration.primary {
  background: url("../img/graph/stat/06.png") no-repeat right bottom #fff;
}

.stats-decoration.primary .stats-decoration-icon-wrap {
  background-color: #23d2e2;
}

.stats-decoration.v2 {
  height: 118px;
  padding: 24px 0 0 28px;
}

.stats-decoration.v2 .stats-decoration-title {
  font-size: 1.75rem;
}

.stats-decoration.v2 .stats-decoration-subtitle {
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: 700;
}

.stats-decoration.v2 .stats-decoration-text {
  margin-top: 6px;
}

.stats-decoration.v2 .percentage-diff {
  position: absolute;
  top: 24px;
  right: 28px;
}

.stats-decoration.v2.big {
  height: 213px;
  padding: 46px 0 0;
  text-align: center;
}

.stats-decoration.v2.big.secondary {
  background: url("../img/graph/stat/05-big.png") repeat-x center bottom #fff;
}

.stats-decoration.v2.big.primary {
  background: url("../img/graph/stat/06-big.png") repeat-x center bottom #fff;
}

.stats-decoration.v2.big .stats-decoration-title {
  font-size: 3rem;
}

.stats-decoration .stats-decoration-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: absolute;
  top: 26px;
  left: 28px;
  z-index: 1;
}

.stats-decoration .stats-decoration-icon-wrap .stats-decoration-icon {
  fill: #fff;
}

.stats-decoration .stats-decoration-title {
  font-size: 1.375rem;
  font-weight: 700;
}

.stats-decoration .stats-decoration-text {
  margin-top: 8px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

/*---------------------------------
    90. PERCENTAGE DIFF
---------------------------------*/
.percentage-diff {
  display: flex;
  align-items: center;
}

.percentage-diff .percentage-diff-icon-wrap {
  margin-right: 4px;
}

.percentage-diff .percentage-diff-text {
  font-size: 0.75rem;
  font-weight: 700;
}

/*-----------------------------------
    91. PERCENTAGE DIFF ICON WRAP
-----------------------------------*/
.percentage-diff-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-radius: 50%;
}

.percentage-diff-icon-wrap.positive {
  border-color: #23d2e2;
}

.percentage-diff-icon-wrap.positive .percentage-diff-icon {
  fill: #23d2e2;
}

.percentage-diff-icon-wrap.negative {
  border-color: #ff5384;
}

.percentage-diff-icon-wrap.negative .percentage-diff-icon {
  fill: #ff5384;
}

.percentage-diff-icon-wrap .percentage-diff-icon {
  width: 6px;
  height: 6px;
}

/*---------------------------------
    92. SIDEBAR BOX
---------------------------------*/
.sidebar-box {
  padding: 32px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.sidebar-box.no-padding {
  padding: 0;
}

.sidebar-box.margin-top {
  margin-top: 56px;
}

.sidebar-box .sidebar-box-title {
  margin-top: 36px;
  font-size: 1rem;
  font-weight: 700;
}

.sidebar-box .sidebar-box-title.medium-space {
  margin-top: 60px;
}

.sidebar-box .sidebar-box-title:first-child {
  margin-top: 0;
}

.sidebar-box .sidebar-menu + .button {
  margin-top: 28px;
}

.sidebar-box .sidebar-menu + .sidebar-box-footer {
  border-top: 1px solid #eaeaf5;
}

.sidebar-box .sidebar-box-footer {
  padding: 28px;
}

.sidebar-box .sidebar-box-footer .button:first-child {
  margin-top: 0;
}

.sidebar-box .sidebar-box-items {
  margin-top: 36px;
}

.sidebar-box .sidebar-box-items:first-child {
  margin-top: 0;
}

.sidebar-box .sidebar-box-items.small-space {
  margin-top: 32px;
}

.sidebar-box .sidebar-box-items .checkbox-line {
  margin-bottom: 18px;
}

.sidebar-box .sidebar-box-items .checkbox-line:last-child {
  margin-bottom: 0;
}

.sidebar-box .sidebar-box-items .form-input.small {
  width: 80px;
}

.sidebar-box .sidebar-box-items .user-status + .badge-list {
  margin-top: 20px;
}

.sidebar-box .sidebar-box-items .totals-line-list + .totals-line-list {
  margin-top: 26px;
}

.sidebar-box .sidebar-box-items .totals-line-list + .button {
  margin-top: 36px;
}

.sidebar-box .sidebar-box-items .totals-line-list + .price-title {
  margin-top: 32px;
}

.sidebar-box .sidebar-box-items .price-title {
  text-align: center;
}

.sidebar-box .sidebar-box-items .price-title + .totals-line-list {
  margin-top: 32px;
}

.sidebar-box .sidebar-box-items .badge-list + .button {
  margin-top: 32px;
}

.sidebar-box .sidebar-box-items .price-title + .form {
  margin-top: 40px;
}

.sidebar-box .sidebar-box-items .form .checkbox-wrap + .checkbox-wrap {
  margin-top: 22px;
}

.sidebar-box .sidebar-box-items .form + .button {
  margin-top: 30px;
}

.sidebar-box .sidebar-box-items .button + .user-stats {
  margin-top: 60px;
}

.sidebar-box .button {
  width: 100%;
  margin-top: 22px;
}

.sidebar-box .button.small-space {
  margin-top: 16px;
}

/*---------------------------------
    93. SIDEBAR MENU
---------------------------------*/
.sidebar-menu .sidebar-menu-item:first-child .sidebar-menu-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.sidebar-menu .sidebar-menu-item:last-child {
  border-bottom: none;
}

.sidebar-menu.round-borders .sidebar-menu-item:last-child .sidebar-menu-header,
.sidebar-menu.round-borders .sidebar-menu-item:last-child .sidebar-menu-body {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

/*---------------------------------
    94. SIDEBAR MENU ITEM
---------------------------------*/
.sidebar-menu-item {
  border-bottom: 1px solid #eaeaf5;
}

.sidebar-menu-item.selected .sidebar-menu-header .sidebar-menu-header-control-icon .sidebar-menu-header-control-icon-open {
  display: block;
}

.sidebar-menu-item.selected .sidebar-menu-header .sidebar-menu-header-control-icon .sidebar-menu-header-control-icon-closed {
  display: none;
}

/*---------------------------------
    95. SIDEBAR MENU HEADER
---------------------------------*/
.sidebar-menu-header {
  height: 112px;
  padding: 28px 26px 0 60px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.sidebar-menu-header .sidebar-menu-header-icon {
  fill: #23d2e2;
  position: absolute;
  top: 28px;
  left: 28px;
}

.sidebar-menu-header .sidebar-menu-header-control-icon .sidebar-menu-header-control-icon-open,
.sidebar-menu-header .sidebar-menu-header-control-icon .sidebar-menu-header-control-icon-closed {
  fill: #3e3f5e;
  position: absolute;
  top: 32px;
  right: 28px;
}

.sidebar-menu-header .sidebar-menu-header-control-icon .sidebar-menu-header-control-icon-open {
  display: none;
}

.sidebar-menu-header .sidebar-menu-header-title {
  font-size: 1rem;
  font-weight: 700;
}

.sidebar-menu-header .sidebar-menu-header-text {
  margin-top: 4px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.3333333333em;
}

/*---------------------------------
    96. SIDEBAR MENU BODY
---------------------------------*/
.sidebar-menu-body {
  padding: 12px 0 12px 60px;
  border-top: 1px solid #eaeaf5;
  background-color: #fcfcfd;
}

.sidebar-menu-body.secondary .sidebar-menu-link:hover, .sidebar-menu-body.secondary .sidebar-menu-link.active {
  color: #615dfa;
}

.sidebar-menu-body .sidebar-menu-link {
  display: block;
  height: 34px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 34px;
  cursor: pointer;
  transition: transform .2s ease-in-out, color .25s ease-in-out;
}

.sidebar-menu-body .sidebar-menu-link:hover, .sidebar-menu-body .sidebar-menu-link.active {
  color: #00c7d9;
  transform: translate(4px, 0);
}

/*---------------------------------
    97. PRICE TITLE
---------------------------------*/
.price-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.price-title.separator-bottom {
  padding-bottom: 32px;
  border-bottom: 1px solid #eaeaf5;
}

.price-title .currency {
  color: #00c7d9;
}

.price-title.big {
  font-size: 2.875rem;
}

/*---------------------------------
    98. PROMO LINE
---------------------------------*/
.promo-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 98px;
  padding: 0 20px 0 32px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.promo-line .promo-line-text {
  width: 310px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.promo-line .promo-line-actions {
  display: flex;
}

.promo-line .promo-line-actions .form-input {
  margin-right: 16px;
}

.promo-line .form-input {
  width: 240px;
}

.promo-line .button {
  width: 180px;
}

/*---------------------------------
    99. TAB BOX
---------------------------------*/
.tab-box {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.tab-box .tab-box-options {
  display: flex;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.tab-box .tab-box-options .tab-box-option:first-child {
  border-top-left-radius: 12px;
}

.tab-box .tab-box-options .tab-box-option:last-child {
  border-right: none;
  border-top-right-radius: 12px;
}

.tab-box .tab-box-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 66px;
  border-bottom: 1px solid #eaeaf5;
  border-right: 1px solid #eaeaf5;
  background-color: #fcfcfd;
  cursor: pointer;
}

.tab-box .tab-box-option .tab-box-option-title {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 700;
}

.tab-box .tab-box-option .tab-box-option-title .highlighted {
  color: #00c7d9;
}

.tab-box .tab-box-option.active {
  background-color: #fff;
  border-bottom: none;
}

.tab-box .tab-box-option.active .tab-box-option-title {
  color: #3e3f5e;
}

.tab-box .tab-box-item .tab-box-item-content {
  padding: 0 28px 48px;
}

.tab-box .tab-box-item .tab-box-item-content .tab-box-item-title {
  margin-top: 48px;
  font-size: 1.375rem;
  font-weight: 700;
}

.tab-box .tab-box-item .tab-box-item-content .tab-box-item-paragraph {
  margin-top: 28px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

.tab-box .tab-box-item .tab-box-item-content .bullet-item-list {
  margin-top: 32px;
}

/*---------------------------------
    100. BULLET ITEM LIST
---------------------------------*/
.bullet-item-list .bullet-item {
  margin-bottom: 12px;
}

.bullet-item-list .bullet-item:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    101. BULLET ITEM
---------------------------------*/
.bullet-item {
  display: flex;
  align-items: center;
}

.bullet-item .bullet-item-icon {
  margin-right: 12px;
  fill: #23d2e2;
}

.bullet-item .bullet-item-text {
  font-size: 0.875rem;
  font-weight: 700;
}

/*---------------------------------
    102. ORDERED ITEM LIST
---------------------------------*/
.ordered-item-list .ordered-item {
  margin-bottom: 12px;
}

.ordered-item-list .ordered-item:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    103. ORDERED ITEM
---------------------------------*/
.ordered-item {
  display: flex;
}

.ordered-item .ordered-item-bullet {
  width: 28px;
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.7142857143em;
}

.ordered-item .ordered-item-text {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

/*---------------------------------
    104. PROFILE STATS
---------------------------------*/
.profile-stats {
  padding-bottom: 32px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.profile-stats.fixed-height {
  height: 558px;
  padding-bottom: 0;
}

.profile-stats .profile-stats-cover {
  height: 160px;
  padding-top: 34px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: url("../img/banner/banner-profile-stats.jpg") no-repeat center;
  background-size: cover;
}

.profile-stats .profile-stats-cover .profile-stats-cover-title,
.profile-stats .profile-stats-cover .profile-stats-cover-text {
  color: #fff;
  text-align: center;
}

.profile-stats .profile-stats-cover .profile-stats-cover-title {
  font-size: 1.5rem;
  font-weight: 700;
}

.profile-stats .profile-stats-cover .profile-stats-cover-text {
  margin-top: 4px;
  font-size: 1rem;
  font-weight: 500;
}

.profile-stats .profile-stats-info .user-avatar {
  margin: -60px auto 0;
}

.profile-stats .profile-stats-info .featured-stat-list {
  margin-top: 18px;
}

.profile-stats .profile-stats-info .featured-stat-list + .featured-stat-list {
  margin-top: 38px;
}

/*---------------------------------
    105. FEATURED STAT LIST
---------------------------------*/
.featured-stat-list {
  display: flex;
}

.featured-stat-list .featured-stat {
  width: 100%;
  position: relative;
}

.featured-stat-list .featured-stat::after {
  content: '';
  width: 1px;
  height: 40px;
  background-color: #eaeaf5;
  position: absolute;
  top: 52px;
  right: 0;
}

.featured-stat-list .featured-stat:last-child::after {
  display: none;
}

/*---------------------------------
    106. FEATURED STAT
---------------------------------*/
.featured-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featured-stat .featured-stat-icon {
  fill: #3e3f5e;
}

.featured-stat .featured-stat-title {
  margin-top: 20px;
  font-size: 1.375rem;
  font-weight: 700;
}

.featured-stat .featured-stat-subtitle {
  margin-top: 12px;
  font-size: 0.875rem;
  font-weight: 700;
}

.featured-stat .featured-stat-text {
  margin-top: 6px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.featured-stat .progress-arc-wrap + .featured-stat-subtitle {
  margin-top: 20px;
}

/*---------------------------------
    107. FEATURED STAT BOX
---------------------------------*/
.featured-stat-box {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.featured-stat-box.reactioner .featured-stat-box-cover {
  background: url("../img/banner/banner-reaction.jpg") no-repeat center;
  background-size: cover;
}

.featured-stat-box.commenter .featured-stat-box-cover {
  background: url("../img/banner/banner-commenter.jpg") no-repeat center;
  background-size: cover;
}

.featured-stat-box .featured-stat-box-cover {
  height: 120px;
  padding-top: 32px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.featured-stat-box .featured-stat-box-cover .featured-stat-box-cover-title,
.featured-stat-box .featured-stat-box-cover .featured-stat-box-cover-text {
  color: #fff;
  text-align: center;
}

.featured-stat-box .featured-stat-box-cover .featured-stat-box-cover-title {
  font-size: 1.5rem;
  font-weight: 700;
}

.featured-stat-box .featured-stat-box-cover .featured-stat-box-cover-text {
  margin-top: 6px;
  font-size: 0.875rem;
  font-weight: 500;
}

.featured-stat-box .featured-stat-box-info {
  padding-bottom: 28px;
}

.featured-stat-box .featured-stat-box-info .user-avatar {
  margin: -30px auto 0;
}

.featured-stat-box .featured-stat-box-title,
.featured-stat-box .featured-stat-box-subtitle,
.featured-stat-box .featured-stat-box-text {
  text-align: center;
}

.featured-stat-box .featured-stat-box-title {
  margin-top: 8px;
  font-size: 1.375rem;
  font-weight: 700;
}

.featured-stat-box .featured-stat-box-subtitle {
  margin-top: 14px;
  font-size: 0.875rem;
  font-weight: 700;
}

.featured-stat-box .featured-stat-box-text {
  margin-top: 4px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

/*---------------------------------
    108. ACHIEVEMENT BOX
---------------------------------*/
.achievement-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 142px;
  padding: 0 28px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.achievement-box.secondary {
  background: url("../img/achievement/banner/01.jpg") no-repeat center;
  background-size: cover;
}

.achievement-box.primary {
  background: url("../img/achievement/banner/02.jpg") no-repeat center;
  background-size: cover;
}

.achievement-box .achievement-box-info-wrap {
  display: flex;
  align-items: center;
}

.achievement-box .achievement-box-info-wrap .achievement-box-image {
  margin-right: 16px;
}

.achievement-box .achievement-box-info-wrap .achievement-box-title,
.achievement-box .achievement-box-info-wrap .achievement-box-text {
  color: #fff;
}

.achievement-box .achievement-box-info-wrap .achievement-box-title {
  font-size: 1.5rem;
  font-weight: 700;
}

.achievement-box .achievement-box-info-wrap .achievement-box-text {
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: 500;
}

.achievement-box .achievement-box-info-wrap .achievement-box-text .bold {
  margin-right: 4px;
  font-weight: 700;
}

.achievement-box .button {
  width: 140px;
}

/*---------------------------------
    109. LEVEL PROGRESS BOX
---------------------------------*/
.level-progress-box {
  display: flex;
  align-items: center;
  min-height: 120px;
  padding: 0 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.level-progress-box .level-progress-badge {
  flex-shrink: 0;
  margin-right: 28px;
}

.level-progress-box .progress-stat {
  width: 100%;
}

/*---------------------------------
    110. LEVEL PROGRESS BADGE
---------------------------------*/
.level-progress-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 88px;
  height: 86px;
  background: url("../img/badge/level-badge.png") no-repeat center;
}

.level-progress-badge .level-progress-badge-title,
.level-progress-badge .level-progress-badge-text {
  color: #fff;
  text-align: center;
}

.level-progress-badge .level-progress-badge-title {
  font-size: 0.6875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.level-progress-badge .level-progress-badge-text {
  margin-top: -3px;
  font-size: 1.75rem;
  font-weight: 700;
}

/*---------------------------------
    111. EXP LINE LIST
---------------------------------*/
.exp-line-list .exp-line {
  margin-bottom: 22px;
}

.exp-line-list .exp-line:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    112. EXP LINE
---------------------------------*/
.exp-line {
  display: flex;
  align-items: center;
  padding-right: 100px;
  position: relative;
}

.exp-line .exp-line-icon {
  fill: #adafca;
  flex-shrink: 0;
  margin-right: 18px;
}

.exp-line .text-sticker {
  flex-shrink: 0;
  margin-right: 28px;
}

.exp-line .exp-line-text {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286em;
}

.exp-line .exp-line-timestamp {
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
  position: absolute;
  top: 10px;
  right: 0;
}

/*---------------------------------
    113. ACCOUNT STAT BOX
---------------------------------*/
.account-stat-box {
  padding: 32px 28px 100px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.account-stat-box.account-stat-active-users {
  background: url("../img/graph/stat/07.png") no-repeat center bottom #fff;
  background-size: contain;
}

.account-stat-box.account-stat-active-users .account-stat-box-icon-wrap {
  background-color: #615dfa;
}

.account-stat-box.account-stat-visits {
  background: url("../img/graph/stat/08.png") no-repeat center bottom #fff;
  background-size: contain;
}

.account-stat-box.account-stat-visits .account-stat-box-icon-wrap {
  background-color: #4f8dff;
}

.account-stat-box.account-stat-session-duration {
  background: url("../img/graph/stat/09.png") no-repeat center bottom #fff;
  background-size: contain;
}

.account-stat-box.account-stat-session-duration .account-stat-box-icon-wrap {
  background-color: #3ad2fe;
}

.account-stat-box.account-stat-returning-visitors {
  background: url("../img/graph/stat/10.png") no-repeat center bottom #fff;
  background-size: contain;
}

.account-stat-box.account-stat-returning-visitors .account-stat-box-icon-wrap {
  background-color: #23d2e2;
}

.account-stat-box .percentage-diff {
  position: absolute;
  top: 24px;
  right: 28px;
}

.account-stat-box .account-stat-box-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0 auto;
}

.account-stat-box .account-stat-box-icon-wrap .account-stat-box-icon {
  fill: #fff;
}

.account-stat-box .account-stat-box-title {
  margin-top: 24px;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}

.account-stat-box .account-stat-box-subtitle {
  margin-top: 16px;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
}

.account-stat-box .account-stat-box-text {
  margin-top: 10px;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
  text-align: center;
}

/*---------------------------------
    114. REFERENCE ITEM LIST
---------------------------------*/
.reference-item-list {
  display: flex;
}

.reference-item-list .reference-item {
  margin-right: 32px;
}

.reference-item-list .reference-item:last-child {
  margin-right: 0;
}

.reference-item-list.centered {
  justify-content: center;
}

/*---------------------------------
    115. REFERENCE ITEM
---------------------------------*/
.reference-item {
  display: flex;
  align-items: center;
}

.reference-item .reference-bullet {
  margin-right: 6px;
}

.reference-item .reference-item-text {
  font-size: 0.75rem;
  font-weight: 700;
}

/*---------------------------------
    116. REFERENCE BULLET
---------------------------------*/
.reference-bullet {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

.reference-bullet.secondary {
  background-color: #615dfa;
}

.reference-bullet.blue {
  background-color: #4f8dff;
}

.reference-bullet.light-blue {
  background-color: #3ad2fe;
}

.reference-bullet.primary {
  background-color: #23d2e2;
}

/*---------------------------------
    117. COUNTRY STAT LIST
---------------------------------*/
.country-stat-list .country-stat {
  margin-bottom: 26px;
}

.country-stat-list .country-stat:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    118. COUNTRY STAT
---------------------------------*/
.country-stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  position: relative;
}

.country-stat.with-progress {
  padding-left: 50px;
}

.country-stat.with-progress .country-stat-image {
  width: 38px;
  height: 26px;
}

.country-stat .country-stat-image {
  width: 20px;
  height: 14px;
  position: absolute;
  top: -1px;
  left: 0;
}

.country-stat .country-stat-title,
.country-stat .country-stat-text {
  font-size: 0.875rem;
  font-weight: 700;
}

/*---------------------------------
    119. FULL WIDTH IMAGE
---------------------------------*/
.full-width-image {
  width: 100%;
  height: auto;
}

/*---------------------------------
    120. TOTALS LINE LIST
---------------------------------*/
.totals-line-list.separator-bottom {
  padding-bottom: 26px;
  border-bottom: 1px solid #eaeaf5;
}

.totals-line-list .totals-line {
  margin-bottom: 26px;
}

.totals-line-list .totals-line:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    121. TOTALS LINE
---------------------------------*/
.totals-line {
  display: flex;
  justify-content: space-between;
}

.totals-line .totals-line-title {
  font-size: 0.875rem;
  font-weight: 500;
}

.totals-line .totals-line-title .bold {
  color: #3e3f5e;
  font-weight: 700;
}

.totals-line .totals-line-text {
  margin-top: 4px;
  font-size: 0.75rem;
  font-weight: 500;
}

.totals-line .price-title {
  flex-shrink: 0;
}

/*---------------------------------
    122. UPLOAD BOX
---------------------------------*/
.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
  padding-top: 32px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  cursor: pointer;
  transition: transform .2s ease-in-out, box-shadow .25s ease-in-out;
}

.upload-box:hover {
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.12);
  transform: translate(0, -4px);
}

.upload-box:hover .upload-box-icon {
  fill: #23d2e2;
}

.upload-box .upload-box-icon {
  fill: #adafca;
  transition: fill .25s ease-in-out;
}

.upload-box .upload-box-title {
  margin-top: 26px;
  font-size: 0.875rem;
  font-weight: 700;
}

.upload-box .upload-box-text {
  margin-top: 4px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

/*---------------------------------
    123. DRAGGABLE ITEMS
---------------------------------*/
.draggable-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, 60px);
  grid-gap: 24px;
  justify-content: center;
}

/*---------------------------------
    124. DRAGGABLE ITEM
---------------------------------*/
.draggable-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.1);
  cursor: pointer;
}

.draggable-item.empty {
  border: 2px dashed #eaeaf5;
  background-color: transparent;
  box-shadow: none;
  cursor: auto;
}

/*---------------------------------
    125. SWITCH OPTION LIST
---------------------------------*/
.switch-option-list .switch-option {
  margin-bottom: 26px;
}

.switch-option-list .switch-option:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    126. SWITCH OPTION
---------------------------------*/
.switch-option {
  position: relative;
}

.switch-option .switch-option-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.switch-option .switch-option-text {
  margin-top: 4px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.switch-option .form-switch {
  position: absolute;
  top: 0;
  right: 0;
}

.switch-option .button {
  width: 260px;
  margin-top: 32px;
}

.switch-option .switch-option-meta {
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
  position: absolute;
  bottom: 12px;
  right: 0;
}

.switch-option .switch-option-meta .bold {
  color: #3e3f5e;
  font-weight: 700;
}

/*---------------------------------
    127. NOTIFICATION BOX LIST
---------------------------------*/
.notification-box-list .notification-box {
  margin-bottom: 12px;
}

.notification-box-list .notification-box:last-child {
  margin-bottom: 0;
}

/*---------------------------------
    128. NOTIFICATION BOX
---------------------------------*/
.notification-box {
  display: flex;
  align-items: center;
  min-height: 88px;
  padding: 22px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.notification-box.unread {
  background-color: #fcfcfd;
}

.notification-box:hover .notification-box-close-button {
  display: flex;
}

.notification-box:hover .mark-unread-button,
.notification-box:hover .mark-read-button {
  display: block;
}

.notification-box .user-status {
  width: 100%;
}

.notification-box .notification-box-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}

.notification-box .notification-box-close-button .notification-box-close-button-icon {
  fill: #3e3f5e;
  width: 8px;
  height: 8px;
}

.notification-box .notification-box-close-button,
.notification-box .mark-unread-button,
.notification-box .mark-read-button {
  display: none;
}

.notification-box .mark-unread-button,
.notification-box .mark-read-button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
}

.notification-box .mark-unread-button {
  border: 2px solid #23d2e2;
}

.notification-box .mark-read-button {
  background-color: #23d2e2;
}

/*---------------------------------
    129. CREATE ENTITY BOX
---------------------------------*/
.create-entity-box {
  height: 284px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.create-entity-box.v2 {
  height: 360px;
}

.create-entity-box.v2 .create-entity-box-cover {
  height: 180px;
}

.create-entity-box.v2 .create-entity-box-avatar {
  background: url("../img/badge/badge-empty-02.png") no-repeat center;
  top: 36px;
}

.create-entity-box.v2 .create-entity-box-info {
  padding-top: 28px;
}

.create-entity-box.v2 .create-entity-box-title {
  font-size: 1rem;
  text-align: left;
}

.create-entity-box.v2 .create-entity-box-category {
  margin-top: 8px;
}

.create-entity-box .create-entity-box-cover {
  height: 70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px dashed #eaeaf5;
  background-color: #fcfcfd;
}

.create-entity-box .create-entity-box-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 110px;
  background: url("../img/badge/badge-empty.png") no-repeat center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -50px;
}

.create-entity-box .create-entity-box-avatar.primary .create-entity-box-avatar-icon {
  fill: #23d2e2;
}

.create-entity-box .create-entity-box-avatar .create-entity-box-avatar-icon {
  fill: #615dfa;
}

.create-entity-box .create-entity-box-info {
  padding: 60px 28px 0;
}

.create-entity-box .create-entity-box-title {
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
}

.create-entity-box .create-entity-box-category {
  padding-left: 16px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  position: relative;
}

.create-entity-box .create-entity-box-category::before {
  content: '';
  width: 8px;
  height: 8px;
  border: 2px solid #adafca;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 0;
}

.create-entity-box .create-entity-box-text {
  margin-top: 6px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
}

.create-entity-box .button {
  margin-top: 36px;
}

/*---------------------------------
    130. EARNING STAT BOX
---------------------------------*/
.earning-stat-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  padding: 0 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.earning-stat-box .earning-stat-box-info {
  padding-left: 66px;
  position: relative;
}

.earning-stat-box .earning-stat-box-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.earning-stat-box .earning-stat-box-icon-wrap .earning-stat-box-icon {
  fill: #fff;
}

.earning-stat-box .earning-stat-box-icon-wrap.stat-item {
  background-color: #615dfa;
}

.earning-stat-box .earning-stat-box-icon-wrap.stat-earning {
  background-color: #4f8dff;
}

.earning-stat-box .earning-stat-box-icon-wrap.stat-revenue {
  background-color: #3ad2fe;
}

.earning-stat-box .earning-stat-box-icon-wrap.stat-balance {
  background-color: #23d2e2;
}

.earning-stat-box .earning-stat-box-title {
  font-size: 1.75rem;
  font-weight: 700;
}

.earning-stat-box .earning-stat-box-title .currency {
  font-size: 1.125rem;
}

.earning-stat-box .earning-stat-box-text {
  margin-top: 2px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

/*---------------------------------
    131. STATUS INFO
---------------------------------*/
.status-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-info.success .status-icon-wrap {
  background-color: #23d2e2;
}

.status-info .status-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.status-info .status-icon-wrap .status-icon {
  fill: #fff;
  width: 20px;
  height: 16px;
}

.status-info .status-title {
  margin-top: 22px;
  font-size: 0.875rem;
  font-weight: 700;
}

.status-info .status-text {
  margin-top: 4px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-info .status-description {
  margin-top: 28px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
  text-align: center;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1365px) {
  .popup-picture .widget-box {
    display: none;
  }
  .notification-box .notification-box-close-button {
    display: flex;
  }
  .notification-box .mark-unread-button,
  .notification-box .mark-read-button {
    display: block;
  }
}

@media screen and (max-width: 1070px) {
  .week-box {
    display: none;
  }
  .promo-line {
    display: block;
    padding: 32px 28px;
  }
  .promo-line .promo-line-text {
    width: auto;
  }
  .promo-line .promo-line-actions {
    margin-top: 24px;
  }
}

@media screen and (max-width: 960px) {
  .switch-option .form-switch {
    margin-top: 12px;
    position: relative;
    top: auto;
    right: auto;
  }
  .switch-option .button {
    margin-top: 22px;
  }
  .switch-option .switch-option-meta {
    margin-top: 16px;
    position: static;
  }
  .earning-stat-box.full {
    width: 100%;
    justify-content: center;
  }
  .earning-stat-box .user-stats {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .achievement-box {
    flex-direction: column;
    padding: 28px 18px;
  }
  .achievement-box .button {
    margin-top: 16px;
  }
  .level-progress-box {
    display: block;
    height: auto;
    padding: 32px 28px;
  }
  .level-progress-box .level-progress-badge {
    margin: 0 auto;
  }
  .level-progress-box .progress-stat {
    margin-top: 28px;
  }
  .exp-line {
    display: block;
    padding: 32px 0 0;
  }
  .exp-line .exp-line-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .exp-line .text-sticker {
    height: 24px;
    padding: 0 12px;
    line-height: 24px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .exp-line .exp-line-timestamp {
    margin-top: 8px;
    position: static;
  }
}

@media screen and (max-width: 479px) {
  .post-option {
    width: 80px;
  }
  .post-option .post-option-text {
    display: none;
  }
  .post-option .post-option-icon {
    margin-right: 0;
  }
  .reaction-options {
    height: 40px;
  }
  .reaction-options .reaction-option {
    width: 20px;
    height: 20px;
  }
  .simple-tab-items {
    border-bottom: none;
  }
  .simple-tab-items .simple-tab-item {
    display: none;
  }
  .simple-tab-items .form {
    display: block;
  }
  .quick-post.medium .quick-post-footer .quick-post-footer-actions .button {
    width: 80px;
  }
  .quick-post.medium .quick-post-footer .quick-post-footer-actions .button:last-child {
    width: 80px;
  }
  .promo-line .promo-line-actions {
    display: block;
  }
  .promo-line .promo-line-actions .form-input,
  .promo-line .promo-line-actions .button {
    width: 100%;
  }
  .promo-line .promo-line-actions .form-input {
    margin-right: 0;
  }
  .promo-line .promo-line-actions .button {
    margin-top: 16px;
  }
  .switch-option .button {
    width: 100%;
  }
  .action-request.with-text {
    width: 40px;
    padding: 0;
  }
  .action-request.with-text .action-request-icon {
    margin-right: 0;
  }
  .action-request.with-text .action-request-text {
    display: none;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .table-wrap
2. .table
-------------------------------------------------------------------*/
/*--------------------
    1. TABLE WRAP
--------------------*/
.table-wrap {
  overflow-x: auto;
}

/*----------------
    2. TABLE
----------------*/
.table {
  width: 100%;
  display: table;
}

.table.table-forum-category .forum-category {
  width: 384px;
}

.table.table-quests .table-body .table-column:first-child {
  width: 240px;
}

.table.table-quests .table-body .table-column:last-child {
  width: 284px;
}

.table.table-quests .progress-stat-wrap {
  width: 256px;
}

.table.table-top-friends .progress-stat-wrap {
  width: 228px;
}

.table.table-top-friends .table-row .table-column:first-child {
  width: 172px;
}

.table.table-cart .table-header-column:last-child,
.table.table-cart .table-column:last-child {
  width: 20px;
}

.table.table-cart .product-preview {
  width: 330px;
}

.table.table-cart .form-select {
  width: 156px;
}

.table.table-cart .price-title {
  width: 76px;
  margin: 0 auto;
}

.table.table-payments .table-header-column:first-child .table-text,
.table.table-payments .table-column:first-child .table-text {
  width: 100px;
}

.table.table-sales .table-header-column:first-child .table-text,
.table.table-sales .table-column:first-child .table-text {
  width: 100px;
}

.table.table-sales .table-header-column:nth-child(2) .table-link,
.table.table-sales .table-column:nth-child(2) .table-link {
  width: 210px;
}

.table.table-sales .table-column:last-child {
  width: 16px;
}

.table.table-sales .table-row:first-child {
  height: 55px;
}

.table.table-sales .table-row:first-child .table-column {
  padding-top: 15px;
}

.table.table-sales .table-row:last-child {
  height: 55px;
}

.table.table-sales .table-row:last-child .table-column {
  padding-bottom: 15px;
}

.table.table-downloads .table-header-column:last-child,
.table.table-downloads .table-column:last-child {
  width: 216px;
}

.table.table-downloads .product-preview {
  min-width: 220px;
}

.table.table-downloads .price-title {
  width: 76px;
}

.table.join-rows .table-header-column {
  border-bottom: 1px solid #eaeaf5;
}

.table.join-rows .table-header-column:first-child {
  padding-left: 0;
}

.table.join-rows .table-header-column:last-child {
  padding-right: 0;
}

.table.join-rows .table-body {
  border-radius: 0;
  box-shadow: none;
}

.table.join-rows .table-body .table-row {
  background-color: transparent;
}

.table.join-rows .table-body .table-row.micro:first-child {
  height: 55px;
}

.table.join-rows .table-body .table-row.micro:first-child .table-column {
  padding-top: 15px;
}

.table.join-rows .table-body .table-row.tiny:first-child {
  height: 77px;
}

.table.join-rows .table-body .table-row.tiny:first-child .table-column {
  padding-top: 11px;
}

.table.join-rows .table-body .table-row:first-child .table-column:first-child {
  border-top-left-radius: 0;
}

.table.join-rows .table-body .table-row:first-child .table-column:last-child {
  border-top-right-radius: 0;
}

.table.join-rows .table-body .table-row:last-child .table-column:first-child {
  border-bottom-left-radius: 0;
}

.table.join-rows .table-body .table-row:last-child .table-column:last-child {
  border-bottom-right-radius: 0;
}

.table.join-rows .table-body .table-row:nth-child(2n+2) {
  background-color: transparent;
}

.table.join-rows .table-body .table-row .table-column:first-child {
  padding-left: 0;
}

.table.join-rows .table-body .table-row .table-column:last-child {
  padding-right: 0;
}

.table.split-rows {
  border-collapse: separate;
  border-spacing: 0 12px;
}

.table.split-rows .table-header-column {
  height: 16px;
}

.table.split-rows .table-body {
  box-shadow: none;
}

.table.split-rows .table-row {
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.table.split-rows .table-row .table-column:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.table.split-rows .table-row .table-column:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.table.split-rows .table-row:first-child .table-column:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.table.split-rows .table-row:first-child .table-column:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.table.split-rows .table-row:last-child .table-column:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.table.split-rows .table-row:last-child .table-column:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.table .table-header {
  display: table-header-group;
}

.table .table-header-column {
  display: table-cell;
  vertical-align: middle;
  height: 40px;
}

.table .table-header-column.centered {
  text-align: center;
}

.table .table-header-column.align-right {
  text-align: right;
}

.table .table-header-column.padded {
  padding: 0 24px;
}

.table .table-header-column.padded-medium {
  padding: 0 46px;
}

.table .table-header-column.padded-left {
  padding-left: 24px;
}

.table .table-header-column.padded-big-left {
  padding-left: 60px;
}

.table .table-header-column:first-child {
  padding-left: 28px;
}

.table .table-header-column:last-child {
  padding-right: 28px;
}

.table .table-header-column .table-header-title {
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.table .table-body {
  display: table-row-group;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  border-radius: 12px;
}

.table .table-body.same-color-rows .table-row:nth-child(2n+2) {
  background-color: #fff;
}

.table .table-body .table-row:first-child .table-column:first-child {
  border-top-left-radius: 12px;
}

.table .table-body .table-row:first-child .table-column:last-child {
  border-top-right-radius: 12px;
}

.table .table-body .table-row:last-child .table-column:first-child {
  border-bottom-left-radius: 12px;
}

.table .table-body .table-row:last-child .table-column:last-child {
  border-bottom-right-radius: 12px;
}

.table .table-body .table-row:nth-child(2n+2) {
  background-color: #fcfcfd;
}

.table .table-row {
  display: table-row;
  background-color: #fff;
  height: 100px;
}

.table .table-row.micro {
  height: 40px;
}

.table .table-row.tiny {
  height: 66px;
}

.table .table-row.small {
  height: 86px;
}

.table .table-row.medium {
  height: 108px;
}

.table .table-row.mid {
  height: 148px;
}

.table .table-row.big {
  height: 160px;
}

.table .table-row.big.auto {
  height: auto;
}

.table .table-row.big.auto .table-column {
  padding-top: 44px;
  padding-bottom: 44px;
}

.table .table-column {
  display: table-cell;
  vertical-align: middle;
}

.table .table-column.centered {
  text-align: center;
}

.table .table-column.align-right {
  text-align: right;
}

.table .table-column.padded {
  padding: 0 24px;
}

.table .table-column.padded-medium {
  padding: 0 46px;
}

.table .table-column.padded-left {
  padding-left: 24px;
}

.table .table-column.padded-big-left {
  padding-left: 60px;
}

.table .table-column:first-child {
  padding-left: 28px;
}

.table .table-column:last-child {
  padding-right: 28px;
}

.table .table-column .table-information {
  display: flex;
  align-items: center;
}

.table .table-column .table-information .table-image {
  margin-right: 16px;
}

.table .table-column .table-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.table .table-column .table-title .highlighted {
  color: #00c7d9;
}

.table .table-column .table-text {
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.1428571429em;
}

.table .table-column .table-text .light {
  color: #8f91ac;
}

.table .table-column .table-link {
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
}

.table .table-column .table-link .highlighted {
  color: #00c7d9;
}

.table .table-column .table-link + .table-link {
  margin-top: 12px;
}

.table .table-column .table-actions {
  display: flex;
}

.table .table-column .table-actions .button {
  width: 100px;
  margin-right: 16px;
}

.table .table-column .table-actions .button:last-child {
  margin-right: 0;
}

.table .table-column .table-action {
  cursor: pointer;
}

.table .table-column .table-action .icon-delete {
  opacity: .4;
  transition: opacity .2s ease-in-out, fill .2s ease-in-out;
}

.table .table-column .table-action .icon-delete:hover {
  fill: #3e3f5e;
  opacity: 1;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1260px) {
  .table.table-forum-category .table-header-column:last-child,
  .table.table-forum-category .table-column:last-child, .table.table-forum-discussion .table-header-column:last-child,
  .table.table-forum-discussion .table-column:last-child {
    display: none;
  }
  .table.table-forum-category .table-row:first-child .table-column:nth-last-child(2), .table.table-forum-discussion .table-row:first-child .table-column:nth-last-child(2) {
    border-top-right-radius: 12px;
  }
  .table.table-forum-category .table-row:last-child .table-column:nth-last-child(2), .table.table-forum-discussion .table-row:last-child .table-column:nth-last-child(2) {
    border-bottom-right-radius: 12px;
  }
  .table.table-forum-category .forum-category {
    width: auto;
  }
  .table.table-quests .table-header-column:nth-child(3),
  .table.table-quests .table-column:nth-child(3) {
    display: none;
  }
  .table.table-top-friends .table-header-column:nth-child(4), .table.table-top-friends .table-header-column:nth-child(5),
  .table.table-top-friends .table-column:nth-child(4),
  .table.table-top-friends .table-column:nth-child(5) {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .table.table-forum-category .table-header-column.padded-medium,
  .table.table-forum-category .table-column.padded-medium, .table.table-forum-discussion .table-header-column.padded-medium,
  .table.table-forum-discussion .table-column.padded-medium {
    padding: 0 28px;
  }
  .table.table-forum-category .table-header-column:nth-last-child(3),
  .table.table-forum-category .table-column:nth-last-child(3), .table.table-forum-discussion .table-header-column:nth-last-child(3),
  .table.table-forum-discussion .table-column:nth-last-child(3) {
    display: none;
  }
  .table.table-forum-category .table-column {
    min-height: 160px;
  }
  .table.table-forum-category .forum-category .forum-category-image {
    display: none;
  }
  .table.table-forum-category .forum-category .forum-category-info {
    margin-left: 0;
  }
  .table.table-quests .table-header-column:nth-child(2),
  .table.table-quests .table-column:nth-child(2) {
    display: none;
  }
  .table.table-quests .table-body .table-column:first-child {
    width: auto;
  }
  .table.table-quests .table-body .table-column .progress-stat-wrap {
    width: auto;
  }
  .table.table-quests .table-body .table-column .table-image {
    display: none;
  }
  .table.table-top-friends .table-row:first-child {
    height: auto;
  }
  .table.table-top-friends .table-row:first-child .table-column {
    padding-top: 28px;
  }
  .table.table-top-friends .table-header-column:nth-child(2), .table.table-top-friends .table-header-column:nth-child(3),
  .table.table-top-friends .table-column:nth-child(2),
  .table.table-top-friends .table-column:nth-child(3) {
    display: none;
  }
  .table.table-top-friends .table-header-column:last-child,
  .table.table-top-friends .table-column:last-child {
    padding-left: 68px;
  }
  .table.table-top-friends .progress-stat-wrap {
    width: 180px;
  }
  .table.join-rows .table-body .table-row.micro:first-child, .table.join-rows .table-body .table-row.tiny:first-child {
    height: auto;
  }
  .table.join-rows .table-body .table-row.micro:first-child .table-column, .table.join-rows .table-body .table-row.tiny:first-child .table-column {
    padding-top: 24px;
  }
  .table .table-row {
    height: auto;
  }
  .table .table-row .table-column {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .table .table-row.micro .table-column, .table .table-row.tiny .table-column {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .table .table-row.medium .table-column, .table .table-row.big .table-column {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 460px) {
  .table.table-forum-category .table-header-column:first-child,
  .table.table-forum-category .table-column:first-child, .table.table-forum-discussion .table-header-column:first-child,
  .table.table-forum-discussion .table-column:first-child {
    padding-right: 28px;
  }
  .table.table-forum-category .table-header-column:nth-last-child(2),
  .table.table-forum-category .table-column:nth-last-child(2), .table.table-forum-discussion .table-header-column:nth-last-child(2),
  .table.table-forum-discussion .table-column:nth-last-child(2) {
    display: none;
  }
  .table.table-forum-category .table-row:first-child .table-column:first-child, .table.table-forum-discussion .table-row:first-child .table-column:first-child {
    border-top-right-radius: 12px;
  }
  .table.table-forum-category .table-row:last-child .table-column:first-child, .table.table-forum-discussion .table-row:last-child .table-column:first-child {
    border-bottom-right-radius: 12px;
  }
  .table.table-top-friends .table-header-column:last-child,
  .table.table-top-friends .table-column:last-child {
    padding-left: 28px;
  }
  .table.table-top-friends .table-row .table-column:first-child {
    width: auto;
  }
  .table.table-top-friends .user-status .user-status-title,
  .table.table-top-friends .user-status .user-status-text {
    display: none;
  }
  .table.table-top-sellers .product-preview .product-preview-image {
    display: none;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .widget-box
-------------------------------------------------------------------*/
/*--------------------
    1. WIDGET BOX
--------------------*/
.widget-box {
  padding: 32px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.widget-box.no-padding {
  padding: 0;
}

.widget-box.no-padding .widget-box-title {
  padding: 32px 28px 0;
}

.widget-box .widget-box-settings {
  position: absolute;
  top: 22px;
  right: 19px;
  z-index: 9999;
}

.widget-box .widget-box-controls {
  position: absolute;
  top: 28px;
  right: 22px;
  z-index: 9999;
}

.widget-box .widget-box-controls .slider-controls {
  display: flex;
}

.widget-box .widget-box-controls .slider-controls .slider-control:first-child {
  margin-right: 2px;
}

.widget-box .widget-box-actions {
  display: flex;
  justify-content: space-between;
}

.widget-box .widget-box-actions .widget-box-action {
  display: flex;
}

.widget-box .widget-box-actions .widget-box-action .reference-item-list {
  position: relative;
  top: -3px;
}

.widget-box .widget-box-actions .widget-box-action .form-select.v2 {
  top: -6px;
}

.widget-box .widget-box-footer .reference-item-list {
  margin-top: 12px;
}

.widget-box .widget-box-footer .chart-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  padding-left: 36px;
}

.widget-box .widget-box-content-slider .reaction-stats + .reaction-stats {
  margin-top: 40px;
}

.widget-box .widget-box-content-slider .badge-item-stat .text-sticker {
  top: 32px;
  right: 18px;
}

.widget-box .widget-box-title {
  font-size: 1rem;
  font-weight: 700;
}

.widget-box .widget-box-title .highlighted {
  color: #00c7d9;
}

.widget-box .widget-box-text {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

.widget-box .widget-box-text.light {
  color: #8f91ac;
}

.widget-box .widget-box-content {
  margin-top: 36px;
}

.widget-box .widget-box-content:first-child {
  margin-top: 0;
}

.widget-box .widget-box-content.small-margin-top {
  margin-top: 28px;
}

.widget-box .widget-box-content.no-margin-top {
  margin-top: 0;
}

.widget-box .widget-box-content.padded-for-scroll {
  height: 358px;
  padding-bottom: 28px;
}

.widget-box .widget-box-content.padded-for-scroll.small {
  height: 320px;
}

.widget-box .widget-box-content.padded-for-scroll.medium {
  height: 676px;
}

.widget-box .widget-box-content.padded-for-scroll .scroll-content {
  padding: 0 28px;
}

.widget-box .widget-box-content.padded-for-scroll .exp-line-list {
  padding-top: 8px;
}

.widget-box .widget-box-content .paragraph + .information-line-list {
  margin-top: 24px;
}

.widget-box .widget-box-content .filters + .user-status-list,
.widget-box .widget-box-content .filters + .post-preview-line-list {
  margin-top: 24px;
}

.widget-box .widget-box-content .week-box:last-child {
  margin: 22px 0 -60px;
}

.widget-box .widget-box-content .calendar {
  margin: 0 auto;
}

.widget-box .widget-box-content .calendar-events-preview {
  margin-top: 24px;
}

.widget-box .widget-box-content .ordered-item-list {
  margin-top: 18px;
}

.widget-box .widget-box-content .progress-arc-wrap {
  margin: 0 auto;
}

.widget-box .widget-box-content .draggable-items + .widget-box-text {
  margin-top: 32px;
}

.widget-box .widget-box-content .user-stats {
  margin-top: 36px;
}

.widget-box .widget-box-content .user-stats.reference .user-stat {
  width: 100%;
}

.widget-box .widget-box-content .user-stats.reference .user-stat::after {
  height: 40px;
  top: 20px;
}

.widget-box .widget-box-content .user-stats + .user-stats {
  margin-top: 32px;
}

.widget-box .achievement-status-list {
  margin-top: 50px;
}

.widget-box .widget-box-status {
  padding-top: 24px;
  position: relative;
}

.widget-box .widget-box-status .widget-box-status-content {
  padding: 0 28px;
}

.widget-box .widget-box-status .widget-box-status-content .user-status {
  font-family: "Rajdhani", sans-serif;
  padding-right: 70px;
}

.widget-box .widget-box-status .widget-box-status-content .user-status .user-status-title .user-avatar {
  display: inline;
  margin-right: 24px;
}

.widget-box .widget-box-status .user-status + .widget-box-status-text {
  margin-top: 20px;
}

.widget-box .widget-box-status .widget-box-status-text {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

.widget-box .widget-box-status .widget-box-status-text + .widget-box-status-text {
  margin-top: 26px;
}

.widget-box .widget-box-status .widget-box-status-text + .tag-list {
  margin-top: 8px;
}

.widget-box .widget-box-status .widget-box-picture {
  width: 100%;
  height: auto;
  margin-top: 20px;
  cursor: pointer;
}

.widget-box .widget-box-status .tag-sticker,
.widget-box .widget-box-status .text-sticker {
  position: absolute;
  top: -8px;
  right: 68px;
}

.widget-box .widget-box-status .post-preview,
.widget-box .widget-box-status .quote-box {
  margin-top: 28px;
}

.widget-box .widget-box-status .video-status,
.widget-box .widget-box-status .iframe-wrap,
.widget-box .widget-box-status .poll-box,
.widget-box .widget-box-status .picture-collage,
.widget-box .widget-box-status .widget-box {
  margin-top: 24px;
}

.widget-box .widget-box-status .widget-box {
  padding-bottom: 32px;
}

.widget-box .widget-box-status .tag-list {
  margin-top: 28px;
}

.widget-box .widget-box-status .tag-list:first-child {
  margin-top: 18px;
}

.widget-box .widget-box-status .content-actions {
  margin-top: 28px;
  border-top: 1px solid #eaeaf5;
}

.widget-box .widget-box-button {
  width: 100%;
  margin-top: 32px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1365px) {
  .widget-box .widget-box-footer .chart-info {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .widget-box .widget-box-status > .text-sticker .text-sticker-icon {
    margin-right: 0;
  }
  .widget-box .widget-box-status > .text-sticker .text-sticker-content {
    display: none;
  }
  .widget-box .widget-box-actions .widget-box-action:last-child .reference-item-list {
    position: absolute;
    top: 60px;
    left: 28px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .loader-bars
-------------------------------------------------------------------*/
/*---------------------
    1. LOADER BARS
---------------------*/
.loader-bars {
  display: flex;
  justify-content: center;
  height: 40px;
}

.loader-bars .loader-bar {
  width: 4px;
  height: 100%;
  margin-right: 4px;
  border-radius: 200px;
  transform: scaleY(0.2);
  animation: loader-bars .5s infinite alternate ease-in;
}

.loader-bars .loader-bar:last-child {
  margin-right: 0;
}

.loader-bars .loader-bar:nth-child(1) {
  background-color: #615dfa;
}

.loader-bars .loader-bar:nth-child(2) {
  background-color: #5d71fb;
  animation-delay: .1s;
}

.loader-bars .loader-bar:nth-child(3) {
  background-color: #5983fb;
  animation-delay: .2s;
}

.loader-bars .loader-bar:nth-child(4) {
  background-color: #549afc;
  animation-delay: .3s;
}

.loader-bars .loader-bar:nth-child(5) {
  background-color: #4eb2fd;
  animation-delay: .4s;
}

.loader-bars .loader-bar:nth-child(6) {
  background-color: #49c9fe;
  animation-delay: .5s;
}

.loader-bars .loader-bar:nth-child(7) {
  background-color: #45ddfe;
  animation-delay: .6s;
}

.loader-bars .loader-bar:nth-child(8) {
  background-color: #41efff;
  animation-delay: .7s;
}

@keyframes loader-bars {
  0% {
    transform: scaleY(0.2);
  }
  100% {
    transform: scaleY(1);
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .error-section
-------------------------------------------------------------------*/
/*-----------------------
    1. ERROR SECTION
-----------------------*/
.error-section {
  width: 100%;
  height: 100%;
  background: url("../img/landing/404-bg.png") no-repeat left top, url("../img/landing/vikinger-logo.png") right 40px top 40px no-repeat, url("../img/landing/dot-texture.png") repeat left top, #fff;
  background-size: contain, auto, auto;
  position: fixed;
  top: 0;
  left: 0;
}

.error-section .error-section-title {
  color: #fff;
  font-family: "Titillium Web", sans-serif;
  font-size: 15.8125rem;
  font-weight: 900;
  position: absolute;
  top: 280px;
  left: 220px;
}

.error-section .error-section-info {
  width: 420px;
  position: absolute;
  top: 400px;
  right: 270px;
}

.error-section .error-section-subtitle {
  color: #302f45;
  font-size: 6.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.error-section .error-section-subtitle + .error-section-text {
  margin-top: 30px;
}

.error-section .error-section-text {
  margin-top: 20px;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.7777777778em;
}

.error-section .button {
  width: 160px;
  margin-top: 50px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (min-width: 2560px) {
  .error-section .error-section-title {
    top: 35%;
    left: 15%;
  }
  .error-section .error-section-info {
    top: 45%;
    right: 20%;
  }
}

@media screen and (max-width: 1366px) {
  .error-section .error-section-title {
    top: 185px;
    left: 145px;
  }
  .error-section .error-section-info {
    top: 270px;
    right: 140px;
  }
}

@media screen and (max-width: 1365px) {
  .error-section {
    background: url("../img/landing/dot-texture.png") repeat left top #fff;
    background-size: auto;
    padding: 120px 0;
    position: static;
  }
  .error-section .error-section-title {
    color: #3e3f5e;
    font-size: 3.75rem;
    position: static;
    text-align: center;
  }
  .error-section .error-section-info {
    width: 90%;
    margin: 0 auto;
    position: static;
    text-align: center;
  }
  .error-section .error-section-subtitle {
    margin-top: 60px;
    font-size: 2.25rem;
  }
  .error-section .button {
    margin: 50px auto 0;
  }
}

/*---------------
    COMPONENT
---------------*/
/*------------------------------------------------------------------
[Table of contents]

1. .picture
2. .picture-item-list
3. .picture-item
-------------------------------------------------------------------*/
/*-------------------
    1. PICTURE
-------------------*/
.picture {
  width: 52px;
  height: 52px;
}

.picture.round {
  border-radius: 12px;
}

.picture.round.small, .picture.round.tiny {
  border-radius: 10px;
}

.picture.circle {
  border-radius: 50%;
}

.picture.medium {
  width: 60px;
  height: 60px;
}

.picture.small {
  width: 40px;
  height: 40px;
}

.picture.tiny {
  width: 32px;
  height: 32px;
}

/*----------------------------
    2. PICTURE ITEM LIST
----------------------------*/
.picture-item-list {
  display: grid;
  justify-content: center;
}

.picture-item-list.small {
  grid-template-columns: repeat(auto-fit, 52px);
  grid-gap: 6px;
}

/*-----------------------
    3. PICTURE ITEM
-----------------------*/
.picture-item {
  cursor: pointer;
  position: relative;
}

.picture-item .picture-item-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(36, 210, 226, 0.9);
}

.picture-item .picture-item-overlay.round {
  border-radius: 12px;
}

.picture-item .picture-item-overlay.circle {
  border-radius: 50%;
}

.picture-item .picture-item-overlay .picture-item-overlay-text {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
}

/*------------------------------------------------------------------
[Table of contents]

1. .progress-stat-wrap
2. .progress-stat
-------------------------------------------------------------------*/
/*----------------------------
    1. PROGRESS STAT WRAP
----------------------------*/
.progress-stat-wrap {
  padding-right: 56px;
  position: relative;
}

.progress-stat-wrap .progress-stat .bar-progress-wrap {
  position: absolute;
  top: -5px;
  right: 0;
}

.progress-stat-wrap .progress-stat .bar-progress-wrap:first-child, .progress-stat-wrap .progress-stat .bar-progress-wrap:last-child {
  margin: 0;
}

/*------------------------
    2. PROGRESS STAT
------------------------*/
.progress-stat.small {
  width: 178px;
}

.progress-stat .bar-progress-wrap {
  position: relative;
}

.progress-stat .bar-progress-wrap:first-child {
  margin-bottom: 6px;
}

.progress-stat .bar-progress-wrap.big {
  margin-bottom: 20px;
}

.progress-stat .bar-progress-wrap.medium:first-child {
  margin-bottom: 14px;
}

.progress-stat .bar-progress-wrap.small:last-child {
  margin-top: 24px;
}

.progress-stat .bar-progress-wrap:last-child {
  margin-top: 28px;
}

.progress-stat .bar-progress-wrap .bar-progress-info {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.progress-stat .bar-progress-wrap .bar-progress-info.progress-with-text {
  font-size: 1.25rem;
  font-weight: 700;
}

.progress-stat .bar-progress-wrap .bar-progress-info.progress-with-text .bar-progress-unit {
  margin-right: 2px;
}

.progress-stat .bar-progress-wrap .bar-progress-info.progress-with-text .light {
  font-size: 1rem;
  font-weight: 500;
  text-transform: none;
}

.progress-stat .bar-progress-wrap .bar-progress-info.negative {
  color: #3e3f5e;
}

.progress-stat .bar-progress-wrap .bar-progress-info.start {
  justify-content: flex-start;
}

.progress-stat .bar-progress-wrap .bar-progress-info.start .bar-progress-text {
  margin-right: 4px;
}

.progress-stat .bar-progress-wrap .bar-progress-info.center {
  justify-content: center;
}

.progress-stat .bar-progress-wrap .bar-progress-info.regular {
  text-transform: none;
}

.progress-stat .bar-progress-wrap .bar-progress-info.medium {
  font-size: 0.875rem;
}

.progress-stat .bar-progress-wrap .bar-progress-info .light {
  margin-right: 4px;
  color: #adafca;
}

.progress-stat .bar-progress-wrap .bar-progress-text.no-space .bar-progress-unit {
  margin-left: 0;
}

.progress-stat .bar-progress-wrap .bar-progress-unit {
  margin-left: 3px;
  text-transform: uppercase;
}

.progress-stat .progress-stat-info {
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: 8px;
  right: 0;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 680px) {
  .progress-stat .progress-stat-info {
    display: none;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .progress-arc-wrap
2. .progress-arc-block
3. .progress-arc-summary
-------------------------------------------------------------------*/
/*----------------------------
    1. PROGRESS ARC WRAP
----------------------------*/
.progress-arc-wrap {
  width: 140px;
  height: 140px;
  position: relative;
}

.progress-arc-wrap.small {
  width: 80px;
  height: 80px;
}

.progress-arc-wrap.small .progress-arc-info .progress-arc-title {
  font-size: 1.375rem;
}

.progress-arc-wrap.tiny {
  width: 60px;
  height: 60px;
}

.progress-arc-wrap.tiny .progress-arc-info .progress-arc-title {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.progress-arc-wrap .progress-arc {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.progress-arc-wrap .progress-arc-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.progress-arc-wrap .progress-arc-info .progress-arc-title {
  font-size: 2.25rem;
  font-weight: 700;
}

.progress-arc-wrap .progress-arc-info .progress-arc-text {
  margin-top: 2px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

/*----------------------------
    2. PROGRESS ARC BLOCK
----------------------------*/
.progress-arc-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-arc-block .progress-arc-block-info {
  margin-top: 20px;
  text-align: center;
}

.progress-arc-block .progress-arc-block-info .progress-arc-block-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.progress-arc-block .progress-arc-block-info .progress-arc-block-text {
  margin-top: 6px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

/*------------------------------
    3. PROGRESS ARC SUMMARY
------------------------------*/
.progress-arc-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-arc-summary .progress-arc-summary-info {
  margin-top: 36px;
  text-align: center;
}

.progress-arc-summary .progress-arc-summary-info .progress-arc-summary-title {
  font-size: 1.125rem;
  font-weight: 700;
}

.progress-arc-summary .progress-arc-summary-info .progress-arc-summary-subtitle {
  margin-top: 6px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
}

.progress-arc-summary .progress-arc-summary-info .progress-arc-summary-text {
  margin-top: 22px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

/*------------------------------------------------------------------
[Table of contents]

1. .user-avatar
-------------------------------------------------------------------*/
/*----------------------
    1. USER AVATAR
----------------------*/
.user-avatar {
  display: block;
  width: 100px;
  height: 110px;
  position: relative;
}

.user-avatar.online::before, .user-avatar.offline::before, .user-avatar.away::before {
  content: '';
  width: 12px;
  height: 12px;
  border: 3px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: -2px;
  z-index: 10;
}

.user-avatar.online::before {
  background-color: #1df377;
}

.user-avatar.offline::before {
  background-color: #f9515c;
}

.user-avatar.away::before {
  background-color: #adafca;
}

.user-avatar.big {
  width: 148px;
  height: 164px;
}

.user-avatar.big .user-avatar-content {
  top: 27px;
  left: 24px;
}

.user-avatar.big .user-avatar-progress,
.user-avatar.big .user-avatar-progress-border {
  top: 14px;
  left: 12px;
}

.user-avatar.big .user-avatar-badge {
  width: 40px;
  height: 44px;
  top: 102px;
  right: 8px;
}

.user-avatar.big .user-avatar-badge .user-avatar-badge-content {
  top: 5px;
  left: 4px;
}

.user-avatar.big .user-avatar-badge .user-avatar-badge-text {
  font-size: 1rem;
}

.user-avatar.medium {
  width: 120px;
  height: 132px;
}

.user-avatar.medium .user-avatar-content {
  top: 21px;
  left: 19px;
}

.user-avatar.medium .user-avatar-progress,
.user-avatar.medium .user-avatar-progress-border {
  top: 11px;
  left: 10px;
}

.user-avatar.medium .user-avatar-badge {
  width: 32px;
  height: 36px;
  top: 82px;
  right: 7px;
}

.user-avatar.medium .user-avatar-badge .user-avatar-badge-content {
  top: 4px;
  left: 3px;
}

.user-avatar.medium .user-avatar-badge .user-avatar-badge-text {
  font-size: 0.875rem;
}

.user-avatar.small {
  width: 50px;
  height: 56px;
}

.user-avatar.small .user-avatar-content {
  top: 12px;
  left: 10px;
}

.user-avatar.small .user-avatar-progress,
.user-avatar.small .user-avatar-progress-border {
  top: 6px;
  left: 5px;
}

.user-avatar.small .user-avatar-badge {
  width: 22px;
  height: 24px;
  top: 27px;
  right: 2px;
}

.user-avatar.small .user-avatar-badge .user-avatar-badge-content {
  top: 3px;
  left: 3px;
}

.user-avatar.small .user-avatar-badge .user-avatar-badge-text {
  font-size: 0.625rem;
}

.user-avatar.smaller .user-avatar-overlay {
  width: 30px;
  height: 32px;
}

.user-avatar.smaller .user-avatar-overlay-content .user-avatar-overlay-content-text {
  font-size: 0.6875rem;
}

.user-avatar.no-stats {
  width: 100px;
  height: 108px;
}

.user-avatar.no-stats .user-avatar-content {
  top: 8px;
  left: 8px;
}

.user-avatar.no-stats.big {
  width: 148px;
  height: 164px;
}

.user-avatar.no-stats.big .user-avatar-content {
  top: 14px;
  left: 12px;
}

.user-avatar.no-stats.medium {
  width: 120px;
  height: 130px;
}

.user-avatar.no-stats.medium .user-avatar-content {
  top: 10px;
  left: 10px;
}

.user-avatar.no-stats.small {
  width: 50px;
  height: 56px;
}

.user-avatar.no-stats.small .user-avatar-content {
  top: 6px;
  left: 5px;
}

.user-avatar.no-stats.smaller {
  width: 34px;
  height: 36px;
}

.user-avatar.no-stats.smaller .user-avatar-content {
  top: 2px;
  left: 2px;
}

.user-avatar.no-stats.micro {
  width: 22px;
  height: 24px;
}

.user-avatar.no-stats.micro .user-avatar-content {
  top: 2px;
  left: 2px;
}

.user-avatar.no-border {
  width: 84px;
  height: 92px;
}

.user-avatar.no-border .user-avatar-content {
  top: 0;
  left: 0;
}

.user-avatar.no-border.big {
  width: 124px;
  height: 136px;
}

.user-avatar.no-border.medium {
  width: 100px;
  height: 110px;
}

.user-avatar.no-border.small {
  width: 40px;
  height: 44px;
}

.user-avatar.no-border.tiny {
  width: 24px;
  height: 26px;
}

.user-avatar.no-border.micro {
  width: 18px;
  height: 20px;
}

.user-avatar.no-outline {
  width: 84px;
  height: 92px;
}

.user-avatar.no-outline .user-avatar-progress,
.user-avatar.no-outline .user-avatar-progress-border {
  top: 0;
  left: 0;
}

.user-avatar.no-outline .user-avatar-content {
  top: 9px;
  left: 8px;
}

.user-avatar.no-outline .user-avatar-badge {
  top: 60px;
  right: -3px;
}

.user-avatar.no-outline.small {
  width: 40px;
  height: 44px;
}

.user-avatar.no-outline.small .user-avatar-content {
  top: 6px;
  left: 5px;
}

.user-avatar.no-outline.small .user-avatar-badge {
  top: 21px;
  right: -3px;
}

.user-avatar .user-avatar-border {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.user-avatar .user-avatar-border .hexagon-content {
  background-color: #fff;
}

.user-avatar .user-avatar-content {
  position: absolute;
  top: 18px;
  left: 16px;
  z-index: 3;
}

.user-avatar .user-avatar-progress,
.user-avatar .user-avatar-progress-border {
  position: absolute;
  top: 9px;
  left: 8px;
  z-index: 2;
}

.user-avatar .user-avatar-progress {
  z-index: 3;
}

.user-avatar .user-avatar-progress-border {
  z-index: 2;
}

.user-avatar .user-avatar-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 32px;
  position: absolute;
  top: 68px;
  right: 5px;
  z-index: 4;
}

.user-avatar .user-avatar-badge .user-avatar-badge-border {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}

.user-avatar .user-avatar-badge .user-avatar-badge-border .hexagon-content {
  background-color: #fff;
}

.user-avatar .user-avatar-badge .user-avatar-badge-content {
  position: absolute;
  top: 4px;
  left: 3px;
  z-index: 5;
}

.user-avatar .user-avatar-badge .user-avatar-badge-text {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  pointer-events: none;
  position: relative;
  z-index: 6;
}

.user-avatar .user-avatar-overlay {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 11;
}

.user-avatar .user-avatar-overlay-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
}

.user-avatar .user-avatar-overlay-content .user-avatar-overlay-content-text {
  color: #fff;
  font-weight: 700;
  pointer-events: none;
  position: relative;
  top: 1px;
}

/*------------------------------------------------------------------
[Table of contents]

1. .user-status-list
2. .user-status
-------------------------------------------------------------------*/
/*-------------------------
    1. USER STATUS LIST
-------------------------*/
.user-status-list .user-status {
  margin-bottom: 22px;
}

.user-status-list .user-status:last-child {
  margin-bottom: 0;
}

/*---------------------
    2. USER STATUS
---------------------*/
.user-status {
  min-height: 44px;
  padding: 2px 0 0 52px;
  position: relative;
}

.user-status.no-padding-top {
  padding-top: 0;
}

.user-status.notification {
  padding-right: 60px;
}

.user-status.request .user-status-title, .user-status.request-small .user-status-title {
  margin-top: 2px;
  line-height: 1.1428571429em;
}

.user-status.request .user-status-text, .user-status.request-small .user-status-text {
  margin-top: 8px;
}

.user-status.request-small {
  padding-right: 48px;
}

.user-status.request {
  padding-right: 100px;
}

.user-status .user-status-avatar,
.user-status .user-status-activity {
  position: absolute;
  top: 0;
  left: 0;
}

.user-status .user-status-activity {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.user-status .user-status-activity.activity-reaction {
  background-color: #615dfa;
}

.user-status .user-status-activity.activity-comment {
  background-color: #4f8dff;
}

.user-status .user-status-activity.activity-share {
  background-color: #3ad2fe;
}

.user-status .user-status-activity.activity-update {
  background-color: #23d2e2;
}

.user-status .user-status-activity .user-status-activity-icon {
  fill: #fff;
}

.user-status .user-status-title {
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.user-status .user-status-title.medium {
  font-size: 1rem;
}

.user-status .user-status-title.medium + .user-status-text {
  margin-top: 2px;
}

.user-status .user-status-title .bold {
  color: #3e3f5e;
  font-weight: 700;
}

.user-status .user-status-title .highlighted {
  color: #00c7d9;
  font-weight: 600;
}

.user-status .user-status-timestamp {
  margin-top: 10px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

.user-status .user-status-timestamp.small-space {
  margin-top: 4px;
}

.user-status .user-status-timestamp.floaty {
  margin-top: 0;
  position: absolute;
  top: 7px;
  right: 0;
}

.user-status .user-status-text {
  margin-top: 4px;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
}

.user-status .user-status-text.small {
  font-size: 0.75rem;
}

.user-status .user-status-text.small-space {
  margin-top: 4px;
}

.user-status .user-status-text a {
  color: #3e3f5e;
}

.user-status .user-status-tag {
  display: inline-block;
  height: 20px;
  padding: 0 8px;
  border-radius: 200px;
  background-color: #3e3f5e;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  position: relative;
  top: -3px;
}

.user-status .user-status-tag.online {
  background-color: #1df377;
}

.user-status .user-status-tag.offline {
  background-color: #f9515c;
}

.user-status .user-status-tag.away {
  background-color: #adafca;
}

.user-status .user-status-icon {
  opacity: .4;
  position: absolute;
  top: 10px;
  right: 0;
}

.user-status .action-request-list {
  position: absolute;
  top: 2px;
  right: 0;
}

/*------------------------------------------------------------------
[Table of contents]

1. .cart-item-preview
2. .cart-preview-total
-------------------------------------------------------------------*/
/*----------------------------
    1. CART ITEM PREVIEW
----------------------------*/
.cart-item-preview {
  padding-left: 70px;
  position: relative;
}

.cart-item-preview .cart-item-preview-image {
  position: absolute;
  top: 2px;
  left: 0;
}

.cart-item-preview .cart-item-preview-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.cart-item-preview .cart-item-preview-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.cart-item-preview .cart-item-preview-text {
  margin-top: 2px;
  font-size: 0.75rem;
  font-weight: 500;
}

.cart-item-preview .cart-item-preview-price {
  margin-top: 16px;
  font-size: 0.875rem;
  font-weight: 700;
}

.cart-item-preview .cart-item-preview-price .highlighted {
  color: #00c7d9;
}

.cart-item-preview .cart-item-preview-action {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.cart-item-preview .cart-item-preview-action .icon-delete {
  opacity: .4;
  transition: fill .2s ease-in-out, opacity .2s ease-in-out;
}

.cart-item-preview .cart-item-preview-action:hover .icon-delete {
  fill: #3e3f5e;
  opacity: 1;
}

/*----------------------------
    2. CART PREVIEW TOTAL
----------------------------*/
.cart-preview-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 28px 0 100px;
  border-top: 1px solid #eaeaf5;
  border-bottom: 1px solid #eaeaf5;
}

.cart-preview-total .cart-preview-total-title,
.cart-preview-total .cart-preview-total-text {
  font-size: 0.875rem;
  font-weight: 700;
}

.cart-preview-total .cart-preview-total-text .highlighted {
  color: #00c7d9;
}

/*------------------------------------------------------------------
[Table of contents]

1. .form-box
-------------------------------------------------------------------*/
/*------------------
    1. FORM BOX
------------------*/
.form-box {
  width: 484px;
  padding: 64px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 60px 0 rgba(94, 92, 154, 0.12);
  position: relative;
}

.form-box .form-box-decoration {
  position: absolute;
  top: -14px;
  left: -80px;
}

.form-box .form-box-decoration.overflowing {
  top: -68px;
}

.form-box .form-box-title {
  font-size: 1.625rem;
  text-align: center;
}

.form-box .form {
  margin-top: 76px;
}

.form-box .lined-text {
  margin-top: 40px;
}

.form-box .social-links,
.form-box .form-text {
  margin-top: 30px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 500px) {
  .form-box {
    padding: 40px 32px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .landing
2. .landing-info
3. .landing-form
-------------------------------------------------------------------*/
/*------------------
    1. LANDING
------------------*/
.landing {
  width: 100%;
  height: 100%;
  background: url("../img/landing/landing-background.jpg") no-repeat left center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
}

.landing .landing-decoration {
  width: 64%;
  height: 140%;
  border-radius: 50%;
  background: url("../img/landing/dot-texture.png") repeat left top #fff;
  position: absolute;
  top: -20%;
  right: -32%;
  pointer-events: none;
}

/*----------------------
    2. LANDING INFO
----------------------*/
.landing-info {
  width: 584px;
  position: absolute;
  top: 16%;
  left: 13%;
}

.landing-info .logo {
  display: flex;
  justify-content: center;
}

.landing-info .landing-info-pretitle,
.landing-info .landing-info-title,
.landing-info .landing-info-text {
  color: #fff;
  text-align: center;
}

.landing-info .landing-info-pretitle,
.landing-info .landing-info-title {
  text-transform: uppercase;
}

.landing-info .landing-info-pretitle {
  margin-top: 36px;
  font-size: 1.5rem;
  font-weight: 500;
}

.landing-info .landing-info-title {
  font-family: "Titillium Web", sans-serif;
  font-size: 6.5rem;
  font-weight: 900;
  position: relative;
  top: -10px;
}

.landing-info .landing-info-text {
  width: 384px;
  margin: 30px auto 0;
  font-size: 1.125rem;
  line-height: 1.3333333333em;
  font-weight: 500;
  font-weight: 500;
}

.landing-info .tab-switch {
  margin-top: 90px;
}

/*----------------------
    3. LANDING FORM
----------------------*/
.landing-form {
  width: 484px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 13%;
}

.landing-form .form-box {
  position: absolute;
  top: 50%;
  left: 0;
}

.landing-form .form-box:first-child {
  margin-top: -313px;
}

.landing-form .form-box:last-child {
  margin-top: -370px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (min-width: 2560px) {
  .landing-info {
    top: 25%;
  }
  .landing-form {
    right: 20%;
  }
}

@media screen and (min-width: 3840px) {
  .landing-info {
    top: 35%;
    left: 18%;
  }
  .landing-form {
    right: 25%;
  }
}

@media screen and (max-width: 1500px) {
  .landing .landing-decoration {
    display: none;
  }
  .landing-info {
    left: 6%;
  }
  .landing-info .landing-info-text {
    margin-top: 10px;
  }
  .landing-info .tab-switch {
    margin-top: 40px;
  }
  .landing-form {
    right: 80px;
  }
}

@media screen and (max-width: 1365px) {
  .landing {
    padding: 80px 0 100px;
    position: static;
  }
  .landing-info {
    width: 100%;
    position: static;
  }
  .landing-info .landing-info-text {
    display: none;
  }
  .landing-form {
    width: 100%;
    margin-top: 80px;
    position: static;
  }
  .landing-form .form-box {
    margin: 0 auto;
    position: static;
  }
  .landing-form .form-box .form-box-decoration {
    display: none;
  }
  .landing-form .form-box:first-child, .landing-form .form-box:last-child {
    margin-top: 0;
  }
}

@media screen and (max-width: 600px) {
  .landing-form .form-box {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .landing-info .landing-info-title {
    font-size: 3.75rem;
  }
  .landing-info .tab-switch {
    width: 90%;
    margin: 20px auto 0;
  }
  .landing-info .tab-switch .tab-switch-button {
    width: 50%;
  }
  .landing-form {
    margin-top: 60px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .dropdown-box
2. .dropdown-box-category
3. .dropdown-box-list
4. .dropdown-box-actions
5. .dropdown-box-button
-------------------------------------------------------------------*/
/*----------------------
    1. DROPDOWN BOX
----------------------*/
.dropdown-box {
  width: 384px;
  padding-bottom: 60px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.dropdown-box.padding-bottom-small {
  padding-bottom: 14px;
}

.dropdown-box.no-padding-bottom {
  padding-bottom: 0;
}

.dropdown-box .dropdown-box-header {
  display: flex;
  justify-content: space-between;
  padding: 28px 28px 20px;
}

.dropdown-box .dropdown-box-header .dropdown-box-header-title {
  font-size: 1rem;
  font-weight: 700;
}

.dropdown-box .dropdown-box-header .dropdown-box-header-title .highlighted {
  color: #00c7d9;
}

.dropdown-box .dropdown-box-header .dropdown-box-header-actions {
  display: flex;
  align-items: flex-end;
}

.dropdown-box .dropdown-box-header .dropdown-box-header-actions .dropdown-box-header-action {
  margin-right: 16px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 700;
  opacity: .6;
  cursor: pointer;
}

.dropdown-box .dropdown-box-header .dropdown-box-header-actions .dropdown-box-header-action:hover {
  color: #00c7d9;
}

.dropdown-box .dropdown-box-header .dropdown-box-header-actions .dropdown-box-header-action:last-child {
  margin-right: 0;
}

.dropdown-box .dropdown-box-category {
  padding-top: 20px;
}

.dropdown-box .dropdown-box-category:first-child {
  padding-top: 28px;
}

.dropdown-box .dropdown-box-button {
  position: absolute;
  bottom: 0;
  left: 0;
}

.dropdown-box .dropdown-box-actions:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/*------------------------------
    2. DROPDOWN BOX CATEGORY
------------------------------*/
.dropdown-box-category {
  padding: 0 28px 4px;
}

.dropdown-box-category .dropdown-box-category-title {
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

/*----------------------------
    3. DROPDOWN BOX LIST
----------------------------*/
.dropdown-box-list {
  height: 420px;
  overflow-y: auto;
}

.dropdown-box-list.no-scroll {
  height: auto;
}

.dropdown-box-list.scroll-small {
  height: 286px;
}

.dropdown-box-list.medium .dropdown-box-list-item {
  padding: 20px 28px;
}

.dropdown-box-list.small .dropdown-box-list-item {
  padding: 10px 28px;
}

.dropdown-box-list.no-hover .dropdown-box-list-item:hover {
  background-color: transparent;
}

.dropdown-box-list .dropdown-box-list-item {
  display: block;
  padding: 16px 28px;
}

.dropdown-box-list .dropdown-box-list-item.unread {
  background-color: #fcfcfd;
}

.dropdown-box-list .dropdown-box-list-item:hover {
  background-color: #eaeaf5;
}

/*------------------------------
    4. DROPDOWN BOX ACTIONS
------------------------------*/
.dropdown-box-actions {
  display: flex;
  justify-content: center;
  padding: 32px 0;
  background-color: #fcfcfd;
}

.dropdown-box-actions .dropdown-box-action {
  margin-right: 16px;
}

.dropdown-box-actions .dropdown-box-action .button {
  width: 156px;
}

.dropdown-box-actions .dropdown-box-action:last-child {
  margin-right: 0;
}

/*------------------------------
    5. DROPDOWN BOX BUTTON
------------------------------*/
.dropdown-box-button {
  width: 100%;
  height: 60px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #3e3f5e;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  line-height: 60px;
  transition: background-color .2s ease-in-out;
}

.dropdown-box-button:hover {
  color: #fff;
}

.dropdown-box-button.primary {
  background-color: #23d2e2;
}

.dropdown-box-button.primary:hover {
  background-color: #1bc5d4;
}

.dropdown-box-button.secondary {
  background-color: #615dfa;
}

.dropdown-box-button.secondary:hover {
  background-color: #5753e4;
}

/*------------------------------------------------------------------
[Table of contents]

1. .dropdown-navigation
-------------------------------------------------------------------*/
/*----------------------------
    1. DROPDOWN NAVIGATION
----------------------------*/
.dropdown-navigation {
  width: 220px;
  padding: 20px 28px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);
}

.dropdown-navigation .dropdown-navigation-header + .dropdown-navigation-category {
  margin-top: 30px;
}

.dropdown-navigation .dropdown-navigation-category {
  margin: 20px 0 10px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.dropdown-navigation .dropdown-navigation-link {
  display: block;
  padding: 8px 0;
  font-size: 0.875rem;
  font-weight: 700;
  transition: padding .2s ease-in-out, color .2s ease-in-out;
}

.dropdown-navigation .dropdown-navigation-link .highlighted {
  float: right;
  color: #00c7d9;
}

.dropdown-navigation .dropdown-navigation-link:hover {
  padding-left: 4px;
}

.dropdown-navigation .dropdown-navigation-button {
  width: 100%;
  margin-top: 20px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1366px) {
  .dropdown-navigation .dropdown-navigation-category {
    font-size: 0.6875rem;
  }
  .dropdown-navigation .dropdown-navigation-link {
    font-size: 0.75rem;
    padding: 6px 0;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .navigation-widget
-------------------------------------------------------------------*/
/*----------------------------
    1. NAVIGATION WIDGET
----------------------------*/
.navigation-widget {
  width: 300px;
  padding-bottom: 40px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.navigation-widget .navigation-widget-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #3e3f5e;
  width: 70px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.navigation-widget .navigation-widget-cover {
  width: 100%;
  height: 76px;
}

.navigation-widget .navigation-widget-info-wrap {
  padding: 32px 0 0 30px;
  margin-bottom: 40px;
}

.navigation-widget .navigation-widget-info-wrap .navigation-widget-info {
  min-height: 44px;
  padding: 8px 0 0 50px;
  position: relative;
}

.navigation-widget .navigation-widget-info-wrap .navigation-widget-info .user-avatar {
  position: absolute;
  top: 0;
  left: 0;
}

.navigation-widget .navigation-widget-info-wrap .navigation-widget-info .navigation-widget-info-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.navigation-widget .navigation-widget-info-wrap .navigation-widget-info .navigation-widget-info-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.navigation-widget .navigation-widget-info-wrap .navigation-widget-info .navigation-widget-info-text {
  margin-top: 4px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

.navigation-widget .navigation-widget-info-wrap .navigation-widget-info-button {
  margin-top: 20px;
  width: 140px;
}

.navigation-widget .navigation-widget-section-title {
  padding-left: 30px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.navigation-widget .navigation-widget-section-title.space-top {
  margin-top: 30px;
}

.navigation-widget .navigation-widget-section-title + .menu {
  margin-top: 26px;
}

.navigation-widget .navigation-widget-section-title + .navigation-widget-section-link {
  margin-top: 14px;
}

.navigation-widget .navigation-widget-section-link {
  display: block;
  padding: 12px 30px;
  font-size: 0.875rem;
  font-weight: 700;
  transition: padding .2s ease-in-out, color .2s ease-in-out;
}

.navigation-widget .navigation-widget-section-link .highlighted {
  float: right;
  color: #00c7d9;
}

.navigation-widget .navigation-widget-section-link:hover {
  padding-left: 34px;
}

.navigation-widget .navigation-widget-section-link + .navigation-widget-section-title {
  margin-top: 46px;
}

.navigation-widget .badge-list {
  margin-top: 36px;
}

.navigation-widget .user-stats {
  margin-top: 44px;
}

.navigation-widget .menu {
  margin-top: 60px;
}

.navigation-widget .menu + .navigation-widget-section-title {
  margin-top: 40px;
}

.navigation-widget.closed {
  width: 80px;
  padding-top: 20px;
}

.navigation-widget.closed .user-avatar {
  margin: 0 auto;
}

.navigation-widget.closed .menu {
  margin-top: 26px;
}

/*------------------------------------------------------------------
[Table of contents]

1. .chat-widget-wrap
2. .chat-widget
3. .chat-widget-header
4. .chat-widget-messages
5. .chat-widget-conversation
6. .chat-widget-form
7. .chat-widget-button
-------------------------------------------------------------------*/
/*--------------------------
    1. CHAT WIDGET WRAP
--------------------------*/
.chat-widget-wrap {
  display: flex;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.chat-widget-wrap .chat-widget {
  box-shadow: none;
}

.chat-widget-wrap .chat-widget .chat-widget-messages {
  height: 588px;
}

.chat-widget-wrap .chat-widget .chat-widget-conversation {
  height: 500px;
}

.chat-widget-wrap .chat-widget:first-child {
  width: 43.4389140271%;
  border-right: 1px solid #eaeaf5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.chat-widget-wrap .chat-widget:first-child .chat-widget-form {
  border-bottom-left-radius: 12px;
}

.chat-widget-wrap .chat-widget:last-child {
  width: 56.5610859729%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.chat-widget-wrap .chat-widget:last-child .chat-widget-form {
  border-bottom-right-radius: 12px;
}

/*----------------------
    2. CHAT WIDGET
----------------------*/
.chat-widget {
  width: 300px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  transition: transform .4s ease-in-out;
}

.chat-widget.static .chat-widget-messages .chat-widget-message {
  padding: 20px 28px 20px 24px;
  border-left: 4px solid transparent;
}

.chat-widget.static .chat-widget-messages .chat-widget-message.active, .chat-widget.static .chat-widget-messages .chat-widget-message:hover {
  border-left-color: #23d2e2;
}

.chat-widget.chat-widget-overlay {
  padding-bottom: 92px;
}

.chat-widget.hidden .chat-widget-button-icon .burger-icon .burger-icon-bar:nth-child(1) {
  width: 100%;
}

.chat-widget.hidden .chat-widget-button-icon .burger-icon .burger-icon-bar:nth-child(2) {
  width: 14px;
}

.chat-widget.hidden .chat-widget-button-icon .burger-icon .burger-icon-bar:nth-child(3) {
  width: 10px;
}

.chat-widget.closed .chat-widget-messages .chat-widget-message {
  padding: 11px 20px;
}

.chat-widget.closed .chat-widget-messages .chat-widget-message:first-child {
  padding-top: 20px;
}

.chat-widget.closed .chat-widget-messages .chat-widget-message:hover {
  background-color: #fff;
}

.chat-widget.closed .chat-widget-messages .chat-widget-message .user-status .user-status-title,
.chat-widget.closed .chat-widget-messages .chat-widget-message .user-status .user-status-text,
.chat-widget.closed .chat-widget-messages .chat-widget-message .user-status .user-status-timestamp {
  opacity: 0;
  visibility: hidden;
}

.chat-widget.closed .chat-widget-form {
  opacity: 0;
  visibility: hidden;
}

.chat-widget.closed .chat-widget-button .chat-widget-button-icon .burger-icon .burger-icon-bar:nth-child(1) {
  width: 100%;
}

.chat-widget.closed .chat-widget-button .chat-widget-button-icon .burger-icon .burger-icon-bar:nth-child(2) {
  width: 14px;
}

.chat-widget.closed .chat-widget-button .chat-widget-button-icon .burger-icon .burger-icon-bar:nth-child(3) {
  width: 10px;
}

.chat-widget.closed .chat-widget-button .chat-widget-button-text {
  display: none;
}

.chat-widget .chat-widget-messages .chat-widget-message .user-status .user-status-title,
.chat-widget .chat-widget-messages .chat-widget-message .user-status .user-status-text,
.chat-widget .chat-widget-messages .chat-widget-message .user-status .user-status-timestamp {
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
}

/*----------------------------
    3. CHAT WIDGET HEADER
----------------------------*/
.chat-widget-header {
  padding: 20px 28px;
  border-bottom: 1px solid #eaeaf5;
  position: relative;
}

.chat-widget-header .chat-widget-settings {
  position: absolute;
  top: 12px;
  right: 14px;
  z-index: 2;
}

.chat-widget-header .chat-widget-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.chat-widget-header .chat-widget-close-button .chat-widget-close-button-icon {
  fill: #3e3f5e;
}

/*------------------------------
    3. CHAT WIDGET MESSAGES
------------------------------*/
.chat-widget-messages {
  height: 700px;
  overflow-y: auto;
}

.chat-widget-messages .chat-widget-message {
  display: block;
  padding: 14px 28px;
  cursor: pointer;
  transition: padding .4s ease-in-out;
}

.chat-widget-messages .chat-widget-message.active, .chat-widget-messages .chat-widget-message:hover {
  background-color: #eaeaf5;
}

/*----------------------------------
    4. CHAT WIDGET CONVERSATION
----------------------------------*/
.chat-widget-conversation {
  height: 615px;
  padding: 35px 28px;
  background-color: #fcfcfd;
  overflow-y: auto;
}

.chat-widget-conversation .chat-widget-speaker {
  display: flex;
  flex-flow: column;
  position: relative;
}

.chat-widget-conversation .chat-widget-speaker + .chat-widget-speaker {
  margin-top: 16px;
}

.chat-widget-conversation .chat-widget-speaker.left {
  padding: 0 26px 0 36px;
}

.chat-widget-conversation .chat-widget-speaker.left .chat-widget-speaker-avatar {
  left: 0;
}

.chat-widget-conversation .chat-widget-speaker.left .chat-widget-speaker-message {
  border-top-left-radius: 0;
}

.chat-widget-conversation .chat-widget-speaker.right {
  padding-left: 64px;
  align-items: flex-end;
}

.chat-widget-conversation .chat-widget-speaker.right .chat-widget-speaker-message {
  border-top-right-radius: 0;
  background-color: #615dfa;
  color: #fff;
}

.chat-widget-conversation .chat-widget-speaker .chat-widget-speaker-avatar {
  position: absolute;
  top: 0;
}

.chat-widget-conversation .chat-widget-speaker .chat-widget-speaker-message {
  display: inline-block;
  padding: 12px;
  border-radius: 10px;
  background-color: #f5f5fa;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.1428571429em;
}

.chat-widget-conversation .chat-widget-speaker .chat-widget-speaker-message + .chat-widget-speaker-message {
  margin-top: 8px;
}

.chat-widget-conversation .chat-widget-speaker .chat-widget-speaker-timestamp {
  margin-top: 12px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 500;
}

/*--------------------------
    5. CHAT WIDGET FORM
--------------------------*/
.chat-widget-form {
  padding: 22px 28px;
  background-color: #fcfcfd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.1);
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out, bottom .4s ease-in-out;
}

/*----------------------------
    6. CHAT WIDGET BUTTON
----------------------------*/
.chat-widget-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding-left: 28px;
  background-color: #23d2e2;
  cursor: pointer;
}

.chat-widget-button .chat-widget-button-icon {
  margin-right: 30px;
}

.chat-widget-button .chat-widget-button-text {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 960px) {
  .chat-widget-wrap {
    display: block;
    box-shadow: none;
  }
  .chat-widget-wrap .chat-widget {
    border-radius: 12px;
  }
  .chat-widget-wrap .chat-widget:first-child, .chat-widget-wrap .chat-widget:last-child {
    width: 100%;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  }
  .chat-widget-wrap .chat-widget:first-child {
    border-right: none;
    margin-bottom: 26px;
  }
  .chat-widget-wrap .chat-widget:first-child .chat-widget-form {
    border-bottom-right-radius: 12px;
  }
  .chat-widget-wrap .chat-widget:last-child .chat-widget-form {
    border-bottom-left-radius: 12px;
  }
  .chat-widget-wrap .chat-widget .chat-widget-conversation + .chat-widget-form .button {
    width: 100%;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .profile-header
-------------------------------------------------------------------*/
/*--------------------------
    1. PROFILE HEADER
--------------------------*/
.profile-header {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.profile-header.v2 .profile-header-info {
  height: 112px;
}

.profile-header.v2 .profile-header-info .user-short-description {
  min-height: 164px;
  padding: 84px 0 0 164px;
  position: absolute;
  left: 32px;
  bottom: 0;
}

.profile-header.v2 .profile-header-info .user-short-description .user-short-description-avatar {
  top: 0;
  left: 0;
  margin-left: 0;
}

.profile-header.v2 .profile-header-info .user-short-description .user-short-description-avatar.user-short-description-avatar-mobile {
  top: -54px;
  margin-left: -60px;
}

.profile-header.v2 .profile-header-info .user-short-description .user-short-description-title,
.profile-header.v2 .profile-header-info .user-short-description .user-short-description-text {
  text-align: left;
}

.profile-header.v2 .profile-header-info .user-stats {
  left: auto;
  top: 38px;
  right: 164px;
}

.profile-header.v2 .profile-header-info .tag-sticker {
  position: absolute;
  top: -12px;
  right: 28px;
  display: none;
}

.profile-header.v2 .profile-header-info .profile-header-info-actions {
  top: 32px;
}

.profile-header.v2 .profile-header-info .profile-header-info-actions .profile-header-info-action.button {
  width: 58px;
}

.profile-header .profile-header-cover {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 300px;
}

.profile-header .profile-header-info {
  height: 126px;
  position: relative;
}

.profile-header .profile-header-info .user-short-description .user-short-description-avatar-mobile {
  display: none;
  top: -54px;
  margin-left: -60px;
}

.profile-header .profile-header-info .profile-header-social-links-wrap {
  width: 352px;
  height: 44px;
  margin: 0 auto;
  position: absolute;
  top: 54px;
  right: 32px;
}

.profile-header .profile-header-info .profile-header-social-links-wrap .profile-header-social-links {
  padding-top: 4px;
  position: relative;
  z-index: 2;
}

.profile-header .profile-header-info .profile-header-social-links-wrap .slider-controls {
  width: 234px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 10px;
  left: -40px;
  z-index: 1;
}

.profile-header .profile-header-info .user-stats {
  position: absolute;
  top: 54px;
  left: 40px;
}

.profile-header .profile-header-info .profile-header-info-actions {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -40px;
  right: 32px;
}

.profile-header .profile-header-info .profile-header-info-actions .profile-header-info-action {
  margin-right: 16px;
}

.profile-header .profile-header-info .profile-header-info-actions .profile-header-info-action.button {
  width: 180px;
}

.profile-header .profile-header-info .profile-header-info-actions .profile-header-info-action:last-child {
  margin-right: 0;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1365px) {
  .profile-header .profile-header-cover {
    height: 200px;
  }
  .profile-header.v2 .profile-header-info {
    height: auto;
  }
  .profile-header.v2 .profile-header-info .user-short-description {
    min-height: auto;
    padding: 52px 0 0;
    position: static;
  }
  .profile-header.v2 .profile-header-info .user-short-description .user-short-description-avatar {
    top: -116px;
    left: 50%;
    margin-left: -74px;
  }
  .profile-header.v2 .profile-header-info .user-short-description .user-short-description-title,
  .profile-header.v2 .profile-header-info .user-short-description .user-short-description-text {
    text-align: center;
  }
  .profile-header .profile-header-info {
    height: auto;
    padding-bottom: 32px;
  }
  .profile-header .profile-header-info .profile-header-social-links-wrap,
  .profile-header .profile-header-info .user-stats,
  .profile-header .profile-header-info .profile-header-info-actions {
    position: static;
  }
  .profile-header .profile-header-info .profile-header-social-links-wrap {
    margin-top: 32px;
  }
  .profile-header .profile-header-info .user-stats,
  .profile-header .profile-header-info .profile-header-info-actions {
    margin-top: 40px;
  }
}

@media screen and (max-width: 480px) {
  .profile-header .profile-header-cover {
    height: 76px;
  }
  .profile-header.v2 .profile-header-info .user-short-description {
    padding-top: 84px;
  }
  .profile-header.v2 .profile-header-info .user-stats .user-stat:first-child {
    display: none;
  }
  .profile-header.v2 .profile-header-info .user-stats .user-stat:nth-last-child(2)::after {
    display: block;
  }
  .profile-header.v2 .profile-header-info .user-stats .user-stat:last-child {
    display: block;
  }
  .profile-header.v2 .profile-header-info .tag-sticker {
    display: flex;
  }
  .profile-header .profile-header-info .user-short-description {
    padding-top: 84px;
  }
  .profile-header .profile-header-info .user-short-description .user-short-description-avatar {
    display: none;
  }
  .profile-header .profile-header-info .user-short-description .user-short-description-avatar-mobile {
    display: block;
  }
  .profile-header .profile-header-info .user-short-description .user-short-description-title {
    font-size: 1.125rem;
  }
  .profile-header .profile-header-info .user-short-description .user-short-description-text {
    font-size: 0.6875rem;
  }
  .profile-header .profile-header-info .profile-header-social-links-wrap {
    width: 160px;
    height: 36px;
    position: relative;
    top: auto;
    right: auto;
  }
  .profile-header .profile-header-info .profile-header-social-links-wrap .social-link {
    width: 32px;
    height: 32px;
  }
  .profile-header .profile-header-info .user-stats .user-stat:nth-last-child(2)::after {
    display: none;
  }
  .profile-header .profile-header-info .user-stats .user-stat:last-child {
    display: none;
  }
  .profile-header .profile-header-info .user-stats .user-stat .user-stat-title {
    font-size: 0.875rem;
  }
  .profile-header .profile-header-info .user-stats .user-stat .user-stat-text {
    font-size: 0.6875rem;
  }
  .profile-header .profile-header-info .profile-header-info-actions .profile-header-info-action.button {
    width: 114px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .post-preview
2. .post-peek-list
3. .post-peek
4. .post-preview-line-list
5. .post-preview-line
-------------------------------------------------------------------*/
/*----------------------
    1. POST PREVIEW
----------------------*/
.post-preview {
  min-height: 516px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.post-preview.medium {
  min-height: auto;
  box-shadow: none;
}

.post-preview.medium .post-preview-image {
  height: 320px;
  border-radius: 0;
}

.post-preview.medium .post-preview-info {
  width: 90.4109589041%;
  margin-top: -140px;
}

.post-preview.medium .post-preview-info .post-preview-title {
  font-size: 1.5rem;
  line-height: 1.3333333333em;
}

.post-preview.medium .post-preview-info .post-preview-text {
  margin-top: 24px;
}

.post-preview .post-preview-image {
  height: 210px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.post-preview .post-preview-info {
  width: 91.6666666667%;
  padding: 28px;
  margin: -48px auto 0;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.1);
}

.post-preview .post-preview-info.fixed-height {
  height: 292px;
  padding: 28px 28px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-preview .post-preview-info.fixed-height .post-preview-text {
  margin-top: 0;
}

.post-preview .post-preview-info .post-preview-timestamp {
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.post-preview .post-preview-info .post-preview-title {
  margin-top: 10px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2em;
}

.post-preview .post-preview-info .post-preview-text {
  margin-top: 16px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

.post-preview .post-preview-info .post-preview-link {
  margin-top: 20px;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: color .2s ease-in-out;
}

.post-preview .content-actions {
  margin-top: 10px;
  padding: 0 28px;
}

/*------------------------
    1. POST PEEK LIST
------------------------*/
.post-peek-list .post-peek {
  margin-bottom: 22px;
}

.post-peek-list .post-peek:last-child {
  margin-bottom: 0;
}

/*-------------------
    1. POST PEEK
-------------------*/
.post-peek {
  min-height: 40px;
  padding-left: 52px;
  position: relative;
}

.post-peek .post-peek-image {
  position: absolute;
  top: 0;
  left: 0;
}

.post-peek .post-peek-title {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.1428571429em;
}

.post-peek .post-peek-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.post-peek .post-peek-text {
  margin-top: 8px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

/*----------------------------
    POST PREVIEW LINE LIST
----------------------------*/
.post-preview-line-list .post-preview-line {
  margin-bottom: 22px;
}

.post-preview-line-list .post-preview-line:last-child {
  margin-bottom: 0;
}

/*------------------------
    POST PREVIEW LINE
------------------------*/
.post-preview-line .post-preview-line-title {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.1428571429em;
}

.post-preview-line .post-preview-line-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.post-preview-line .post-preview-line-meta {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.post-preview-line .user-avatar {
  margin-right: 8px;
}

.post-preview-line .post-preview-line-author {
  margin-right: 6px;
  font-size: 0.75rem;
  font-weight: 700;
}

.post-preview-line .post-preview-line-author a {
  color: #3e3f5e;
  font-weight: 700;
}

.post-preview-line .post-preview-line-timestamp {
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 460px) {
  .post-preview .post-preview-info.fixed-height {
    display: block;
    height: auto;
    padding: 28px;
  }
  .post-preview .post-preview-info.fixed-height .post-preview-text {
    margin-top: 16px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .post-comment
2. .post-comment-heading
3. .post-comment-form
4. .post-comment-list
-------------------------------------------------------------------*/
/*-----------------------
    1. POST COMMENT
-----------------------*/
.post-comment {
  padding: 26px 28px 28px 80px;
  background-color: #fff;
  position: relative;
}

.post-comment.unread {
  background-color: #fcfcfd;
}

.post-comment.reply-2 {
  padding-left: 108px;
}

.post-comment.reply-2 .user-avatar {
  left: 56px;
}

.post-comment .user-avatar {
  position: absolute;
  top: 28px;
  left: 28px;
}

.post-comment .post-comment-text-wrap {
  display: flex;
  align-items: center;
}

.post-comment .post-comment-text-wrap .rating-list {
  margin-right: 12px;
}

.post-comment .post-comment-text {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

.post-comment .post-comment-text .bold {
  font-weight: 700;
}

.post-comment .post-comment-text .highlighted {
  color: #00c7d9;
  font-weight: 700;
}

.post-comment .post-comment-text .post-comment-text-author {
  margin-right: 6px;
  color: #3e3f5e;
  font-weight: 700;
}

.post-comment .post-comment-text.user-tag::after {
  display: inline-block;
  height: 20px;
  padding: 0 8px;
  border-radius: 200px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 20px;
  position: relative;
  top: -1px;
}

.post-comment .post-comment-text.user-tag.purchased::after {
  content: 'Purchased!';
  background-color: #615dfa;
}

.post-comment .post-comment-text.user-tag.author::after {
  content: 'Author';
  background-color: #23d2e2;
}

.post-comment .content-actions {
  height: 20px;
  margin-top: 14px;
}

.post-comment .post-comment-form {
  padding: 26px 0 0 30px;
}

.post-comment .post-comment-form .user-avatar {
  left: -24px;
}

/*------------------------------
    2. POST COMMENT HEADING
------------------------------*/
.post-comment-heading {
  height: 53px;
  background-color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 53px;
  text-align: center;
  cursor: pointer;
}

.post-comment-heading .highlighted {
  color: #00c7d9;
}

/*---------------------------
    3. POST COMMENT FORM
---------------------------*/
.post-comment-form {
  padding: 26px 28px 26px 80px;
  background-color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
}

.post-comment-form.bordered-top {
  border-top: 1px solid #eaeaf5;
}

.post-comment-form.with-title {
  padding-top: 80px;
}

.post-comment-form.with-title .user-avatar {
  top: 82px;
}

.post-comment-form .post-comment-form-title {
  font-size: 1rem;
  font-weight: 700;
  position: absolute;
  top: 32px;
  left: 28px;
}

.post-comment-form .user-avatar {
  position: absolute;
  top: 28px;
  left: 28px;
}

/*---------------------------
    4. POST COMMENT LIST
---------------------------*/
.post-comment-list.no-border-top > .post-comment:first-child,
.post-comment-list.no-border-top > .post-comment-heading:first-child,
.post-comment-list.no-border-top > .post-comment-form:first-child {
  border-top: none;
}

.post-comment-list > .post-comment,
.post-comment-list > .post-comment-heading,
.post-comment-list > .post-comment-form {
  border-top: 1px solid #eaeaf5;
}

.post-comment-list > .post-comment:last-child,
.post-comment-list > .post-comment-heading:last-child,
.post-comment-list > .post-comment-form:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 460px) {
  .post-comment .content-actions {
    height: auto;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .post-open
-------------------------------------------------------------------*/
/*--------------------
    1. POST OPEN
--------------------*/
.post-open .post-open-cover {
  width: 100%;
  height: 560px;
}

.post-open .post-open-body {
  width: 65%;
  max-width: 784px;
  margin: -220px auto 0;
  padding-top: 90px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.post-open .post-open-body .post-open-heading {
  padding: 0 100px;
}

.post-open .post-open-body .post-open-heading .post-open-timestamp {
  color: #adafca;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.post-open .post-open-body .post-open-heading .post-open-timestamp .highlighted {
  margin-right: 12px;
  color: #3e3f5e;
}

.post-open .post-open-body .post-open-heading .post-open-title {
  margin-top: 22px;
  font-size: 2.875rem;
  font-weight: 700;
  line-height: 0.9565217391em;
}

.post-open .post-open-body .post-open-content {
  display: flex;
  margin-top: 70px;
  padding: 0 100px;
}

.post-open .post-open-body .post-open-content .post-open-content-sidebar {
  width: 84px;
  flex-shrink: 0;
}

.post-open .post-open-body .post-open-content .post-open-content-sidebar .post-open-sidebar-title {
  width: 40px;
  margin-top: 24px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.post-open .post-open-body .post-open-content .post-open-content-sidebar .social-links {
  margin-top: 26px;
}

.post-open .post-open-body .post-open-content .post-open-content-body {
  width: 500px;
  padding-bottom: 80px;
}

.post-open .post-open-body .post-open-content .post-open-content-body .post-open-paragraph {
  margin-top: 36px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2em;
}

.post-open .post-open-body .post-open-content .post-open-content-body .post-open-paragraph:first-child {
  margin-top: 0;
}

.post-open .post-open-body .post-open-content .post-open-content-body .post-open-image {
  width: 100%;
  height: 320px;
  margin-top: 50px;
}

.post-open .post-open-body .post-open-content .post-open-content-body .post-open-image-caption {
  margin-top: 20px;
  color: #8f91ac;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
  text-align: center;
}

.post-open .post-open-body .post-open-content .post-open-content-body .tag-list {
  margin-top: 60px;
}

.post-open .post-open-body > .content-actions {
  margin: 0 28px;
  border-top: 1px solid #eaeaf5;
}

.post-open .post-open-body .post-options {
  padding: 0 100px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1366px) {
  .post-open .post-open-body {
    width: 95%;
    max-width: 100%;
  }
  .post-open .post-open-body .post-open-content .post-open-content-sidebar {
    width: 15%;
  }
  .post-open .post-open-body .post-open-content .post-open-content-body {
    width: 85%;
  }
}

@media screen and (max-width: 960px) {
  .post-open .post-open-body .post-open-heading {
    padding: 0 40px;
  }
  .post-open .post-open-body .post-open-content {
    padding: 0 40px;
    margin-top: 40px;
    flex-direction: column;
  }
  .post-open .post-open-body .post-open-content .post-open-content-sidebar,
  .post-open .post-open-body .post-open-content .post-open-content-body {
    width: 100%;
  }
  .post-open .post-open-body .post-open-content .post-open-content-sidebar {
    margin-bottom: 60px;
    display: flex;
    align-items: center;
  }
  .post-open .post-open-body .post-open-content .post-open-content-sidebar .post-open-sidebar-title {
    width: auto;
    margin: 0 0 0 16px;
    line-height: 40px;
    text-align: left;
  }
  .post-open .post-open-body .post-open-content .post-open-content-sidebar .social-links {
    margin: 0 0 0 26px;
  }
  .post-open .post-open-body .post-open-content .post-open-content-sidebar .social-links.vertical {
    flex-direction: row;
  }
  .post-open .post-open-body .post-open-content .post-open-content-sidebar .social-links.vertical .social-link {
    margin: 0 16px 0 0;
  }
  .post-open .post-open-body .post-options {
    padding: 0 28px;
  }
}

@media screen and (max-width: 460px) {
  .post-open .post-open-cover {
    height: 320px;
  }
  .post-open .post-open-body {
    margin-top: -100px;
    padding-top: 60px;
  }
  .post-open .post-open-body .post-open-heading,
  .post-open .post-open-body .post-open-content {
    padding: 0 28px;
  }
  .post-open .post-open-body .post-open-heading .post-open-title {
    font-size: 2.25rem;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .product-preview
2. .product-category-box
-------------------------------------------------------------------*/
/*--------------------------
    1. PRODUCT PREVIEW
--------------------------*/
.product-preview {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.product-preview.fixed-height {
  height: 360px;
}

.product-preview.small {
  background-color: transparent;
  box-shadow: none;
}

.product-preview.small .product-preview-image {
  height: 144px;
  border-radius: 12px;
}

.product-preview.small .product-preview-info {
  padding: 20px 0 0;
}

.product-preview.small .product-preview-info .text-sticker {
  right: -8px;
}

.product-preview.small .product-preview-info .product-preview-title {
  font-size: 0.875rem;
}

.product-preview.tiny {
  display: flex;
  background-color: transparent;
  box-shadow: none;
}

.product-preview.tiny .product-preview-image {
  width: 94px;
  height: 60px;
  margin-right: 20px;
  border-radius: 12px;
}

.product-preview.tiny .product-preview-image.short {
  width: 60px;
}

.product-preview.tiny .product-preview-info {
  padding: 4px 0 0;
}

.product-preview.tiny .product-preview-info .product-preview-title {
  font-size: 0.875rem;
}

.product-preview.tiny .product-preview-info .product-preview-category {
  margin-top: 6px;
}

.product-preview.tiny .product-preview-info .product-preview-creator {
  margin-top: 6px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 700;
}

.product-preview.tiny .product-preview-info .product-preview-creator a {
  color: #adafca;
  font-weight: 700;
}

.product-preview.micro {
  display: flex;
  background-color: transparent;
  box-shadow: none;
}

.product-preview.micro .product-preview-image {
  width: 68px;
  height: 44px;
  margin-right: 12px;
  border-radius: 12px;
}

.product-preview.micro .product-preview-info {
  padding: 6px 0 0;
}

.product-preview.micro .product-preview-info .product-preview-title {
  font-size: 0.875rem;
}

.product-preview.micro .product-preview-info .product-preview-category {
  margin-top: 6px;
}

.product-preview .product-preview-image {
  height: 180px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.product-preview .product-preview-info {
  padding: 28px;
  position: relative;
}

.product-preview .product-preview-info .text-sticker {
  position: absolute;
  top: -14px;
  right: 14px;
}

.product-preview .product-preview-info .product-preview-title a,
.product-preview .product-preview-info .product-preview-category a {
  color: #3e3f5e;
  font-weight: 700;
}

.product-preview .product-preview-info .product-preview-title {
  font-size: 1rem;
  font-weight: 700;
}

.product-preview .product-preview-info .product-preview-category {
  margin-top: 8px;
  padding-left: 14px;
  font-size: 0.75rem;
  font-weight: 700;
  position: relative;
}

.product-preview .product-preview-info .product-preview-category.digital::before {
  border-color: #3ad2fe;
}

.product-preview .product-preview-info .product-preview-category.physical::before {
  border-color: #23d2e2;
}

.product-preview .product-preview-info .product-preview-category::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #3e3f5e;
  position: absolute;
  top: 1px;
  left: 0;
}

.product-preview .product-preview-info .product-preview-text {
  margin-top: 20px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.product-preview .product-preview-info .button {
  margin-top: 36px;
}

.product-preview .product-preview-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  padding: 0 28px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fcfcfd;
  border-top: 1px solid #eaeaf5;
}

.product-preview .product-preview-author {
  padding-left: 26px;
  position: relative;
}

.product-preview .product-preview-author .product-preview-author-image {
  position: absolute;
  top: 1px;
  left: 0;
}

.product-preview .product-preview-author .product-preview-author-title {
  font-size: 0.625rem;
  font-weight: 500;
}

.product-preview .product-preview-author .product-preview-author-text {
  margin-top: 1px;
  font-size: 0.75rem;
  font-weight: 700;
}

.product-preview .product-preview-author .product-preview-author-text a {
  color: #3e3f5e;
  font-weight: 700;
}

/*-----------------------------
    1. PRODUCT CATEGORY BOX
-----------------------------*/
.product-category-box {
  display: block;
  height: 142px;
  padding: 26px 0 0 28px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.product-category-box.category-all {
  background: url("../img/marketplace/category/01.png") no-repeat right top, linear-gradient(to right, #615dfa, #8d7aff);
}

.product-category-box.category-all .product-category-box-tag {
  color: #615dfa;
}

.product-category-box.category-featured {
  background: url("../img/marketplace/category/02.png") no-repeat right top, linear-gradient(to right, #417ae1, #5aafff);
}

.product-category-box.category-featured .product-category-box-tag {
  color: #4f8dff;
}

.product-category-box.category-digital {
  background: url("../img/marketplace/category/03.png") no-repeat right top, linear-gradient(to right, #2ebfef, #4ce4ff);
}

.product-category-box.category-digital .product-category-box-tag {
  color: #3ad2fe;
}

.product-category-box.category-physical {
  background: url("../img/marketplace/category/04.png") no-repeat right top, linear-gradient(to right, #17cada, #2becfe);
}

.product-category-box.category-physical .product-category-box-tag {
  color: #23d2e2;
}

.product-category-box .product-category-box-title {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
}

.product-category-box .product-category-box-text {
  margin-top: 4px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
}

.product-category-box .product-category-box-tag {
  display: inline-block;
  height: 20px;
  margin-top: 36px;
  padding: 0 8px;
  border-radius: 200px;
  background-color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
}

/*------------------------------------------------------------------
[Table of contents]

1. .stream-box
-------------------------------------------------------------------*/
/*----------------------
    1. STREAM BOX
----------------------*/
.stream-box {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
  transition: border-radius .2s ease-in-out;
}

.stream-box.no-video-radius:hover .stream-box-info {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.stream-box.no-video-radius .stream-box-video iframe {
  border-radius: 0;
}

.stream-box.big:hover .stream-box-info {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.stream-box.big .stream-box-video {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.stream-box.big .stream-box-video iframe {
  position: absolute;
  top: 0;
  left: 0;
}

.stream-box.big .stream-box-info {
  padding: 28px 28px 28px 88px;
}

.stream-box.big .stream-box-info .stream-box-game-image {
  width: 44px;
  height: 60px;
  position: absolute;
  top: 28px;
  left: 28px;
}

.stream-box.big .stream-box-info .stream-box-title {
  font-size: 1.125rem;
}

.stream-box.big .stream-box-info .stream-box-category {
  margin-top: 6px;
  font-size: 0.875rem;
}

.stream-box.big .stream-box-info .stream-box-views {
  margin-top: 10px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
}

.stream-box .stream-box-video {
  height: 160px;
}

.stream-box .stream-box-video iframe {
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.stream-box .stream-box-image {
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: 138px;
  left: 24px;
  z-index: 3;
}

.stream-box .stream-box-info {
  padding: 22px 28px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
  z-index: 2;
  transition: border-radius .2s ease-in-out;
}

.stream-box .stream-box-info .stream-box-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.stream-box .stream-box-info .stream-box-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.stream-box .stream-box-info .stream-box-text {
  margin-top: 6px;
  font-size: 0.75rem;
  font-weight: 500;
}

.stream-box .stream-box-info .stream-box-text a {
  color: #8f91ac;
  font-weight: 500;
}

.stream-box .stream-box-info .stream-box-category {
  margin-top: 14px;
  font-size: 0.75rem;
  font-weight: 600;
}

.stream-box .stream-box-meta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 77px;
  border-top: 1px solid #eaeaf5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fcfcfd;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: absolute;
  bottom: -77px;
  left: 0;
  z-index: 9999;
  transform: translate(0, -20px);
  opacity: 0;
  visibility: hidden;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.stream-box:hover {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.stream-box:hover .stream-box-info {
  border-radius: 0;
}

.stream-box:hover .stream-box-meta {
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
}

/*------------------------------------------------------------------
[Table of contents]

1. .video-box
2. .video-box-list
-------------------------------------------------------------------*/
/*-------------------
    1. VIDEO BOX
-------------------*/
.video-box {
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.video-box.small .video-box-cover:hover .play-button {
  transform: translate(0, -20px);
}

.video-box.small .video-box-cover:hover .video-box-info .video-box-title,
.video-box.small .video-box-cover:hover .video-box-info .video-box-text {
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
}

.video-box.small .video-box-cover::after {
  border-radius: 12px;
}

.video-box.small .video-box-cover .video-box-cover-image {
  height: 128px;
  border-radius: 12px;
}

.video-box.small .video-box-cover .play-button {
  top: 40px;
  transition: transform .3s ease-in-out;
}

.video-box.small .video-box-cover .video-box-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 0 16px 14px;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.video-box.small .video-box-cover .video-box-info .video-box-title,
.video-box.small .video-box-cover .video-box-info .video-box-text {
  color: #fff;
  transform: translate(20px, 0);
  opacity: 0;
  visibility: hidden;
  transition: transform .3s ease-in-out, visibility .3s ease-in-out, opacity .3s ease-in-out;
}

.video-box.small .video-box-cover .video-box-info .video-box-title {
  font-size: 0.75rem;
}

.video-box.small .video-box-cover .video-box-info .video-box-text {
  margin-top: 4px;
  transition-delay: .15s;
}

.video-box .video-box-cover {
  cursor: pointer;
  position: relative;
}

.video-box .video-box-cover:hover::after {
  opacity: 1;
  visibility: visible;
}

.video-box .video-box-cover::after {
  content: '';
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgba(21, 21, 31, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.video-box .video-box-cover .video-box-cover-image {
  height: 160px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.video-box .video-box-cover .play-button {
  position: absolute;
  top: 56px;
  left: 50%;
  margin-left: -23px;
  z-index: 2;
}

.video-box .video-box-info {
  padding: 22px 28px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fff;
}

.video-box .video-box-info .video-box-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.video-box .video-box-info .video-box-text {
  margin-top: 6px;
  font-size: 0.75rem;
  font-weight: 500;
}

/*-----------------------
    2. VIDEO BOX LIST
-----------------------*/
.video-box-list .video-box {
  margin-bottom: 22px;
}

.video-box-list .video-box:last-child {
  margin-bottom: 0;
}

/*------------------------------------------------------------------
[Table of contents]

1. .video-status
-------------------------------------------------------------------*/
/*----------------------
    1. VIDEO STATUS
----------------------*/
.video-status {
  display: block;
  border-radius: 12px;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.1);
}

.video-status.small {
  padding-left: 180px;
  position: relative;
}

.video-status.small .video-status-image {
  width: 180px;
  height: 180px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.video-status.small .video-status-info {
  height: 180px;
  padding: 26px 28px 22px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
}

.video-status.small .video-status-info .video-status-title {
  margin-top: 0;
  font-size: 1rem;
}

.video-status.small .video-status-info .video-status-text {
  margin-top: 10px;
}

.video-status.small .video-status-info .video-status-meta {
  margin-top: 22px;
}

.video-status .video-status-image {
  width: 100%;
  height: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.video-status .video-status-image.mobile {
  display: none;
}

.video-status .video-status-info {
  padding: 22px 28px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fcfcfd;
}

.video-status .video-status-info .video-status-meta {
  color: #adafca;
  font-size: 0.6875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.video-status .video-status-info .video-status-title {
  margin-top: 12px;
  font-size: 1.125rem;
  font-weight: 500;
}

.video-status .video-status-info .video-status-title .bold {
  font-weight: 700;
}

.video-status .video-status-info .video-status-title .highlighted {
  color: #00c7d9;
  font-weight: 700;
}

.video-status .video-status-info .video-status-text {
  margin-top: 2px;
  color: #8f91ac;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 680px) {
  .video-status.small {
    padding-left: 0;
  }
  .video-status.small .video-status-image {
    display: none;
    width: 100%;
    height: auto;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    position: static;
  }
  .video-status.small .video-status-image.mobile {
    display: block;
  }
  .video-status.small .video-status-info {
    height: auto;
    padding: 22px 28px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 0;
  }
  .video-status.small .video-status-info .video-status-title {
    margin-top: 12px;
    font-size: 1.125rem;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .content-actions
2. .reaction-item-list
3. .reaction-image
4. .user-avatar-list
-------------------------------------------------------------------*/
/*--------------------------
    1. CONTENT ACTIONS
--------------------------*/
.content-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
}

.content-actions .content-action {
  display: flex;
  flex-wrap: wrap;
}

/*-----------------------------
    2. REACTION ITEM LIST
-----------------------------*/
.reaction-item-list {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.reaction-item-list.small .reaction-item {
  width: 20px;
  height: 20px;
}

.reaction-item-list.small .reaction-item .reaction-image {
  width: 16px;
  height: 16px;
}

.reaction-item-list .reaction-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  margin-left: -6px;
}

.reaction-item-list .reaction-item .simple-dropdown .simple-dropdown-text:first-child {
  margin-bottom: 10px;
}

.reaction-item-list .reaction-item > .reaction-image {
  cursor: pointer;
}

.reaction-item-list .reaction-item:last-child {
  margin-left: 0;
}

/*-------------------------
    3. REACTION IMAGE
-------------------------*/
.reaction-image {
  width: 20px;
  height: 20px;
}

/*---------------------------
    4. USER AVATAR LIST
---------------------------*/
.user-avatar-list {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.user-avatar-list.reverse {
  flex-direction: row;
  justify-content: flex-start;
}

.user-avatar-list.reverse.medium .user-avatar:first-child {
  margin-left: 0;
}

.user-avatar-list.reverse.medium .user-avatar:last-child {
  margin-left: -10px;
}

.user-avatar-list.centered {
  justify-content: center;
}

.user-avatar-list.medium .user-avatar {
  margin-left: -10px;
}

.user-avatar-list.medium .user-avatar:last-child {
  margin-left: 0;
}

.user-avatar-list .user-avatar {
  margin-left: -8px;
}

.user-avatar-list .user-avatar .user-avatar-border {
  z-index: 3;
}

.user-avatar-list .user-avatar:last-child {
  margin-left: 0;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 479px) {
  .content-actions .user-avatar-list {
    display: none;
  }
  .content-actions .user-avatar-list + .meta-line-text {
    display: none;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .user-preview
-------------------------------------------------------------------*/
/*----------------------
    1. USER PREVIEW
----------------------*/
.user-preview {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.user-preview.fixed-height {
  height: 140px;
}

.user-preview.fixed-height-medium {
  height: 284px;
}

.user-preview.small .user-preview-cover {
  height: 70px;
}

.user-preview.small .user-preview-info .tag-sticker {
  right: 16px;
}

.user-preview.small .user-preview-info .user-short-description .user-short-description-title {
  font-size: 1.125rem;
}

.user-preview.small .user-preview-info .user-short-description .user-short-description-title.small {
  font-size: 0.875rem;
}

.user-preview.small .user-preview-info .user-short-description .user-short-description-text {
  font-size: 0.6875rem;
}

.user-preview.small .user-preview-info .user-short-description .user-short-description-text.regular {
  margin-top: 6px;
  font-size: 0.75rem;
}

.user-preview.small .user-preview-info .user-stats,
.user-preview.small .user-preview-info .social-links {
  margin-top: 36px;
}

.user-preview.small .user-preview-info .user-preview-social-links-wrap {
  width: 160px;
  margin: 32px auto 0;
}

.user-preview.landscape {
  display: flex;
  align-items: center;
  height: 100px;
  padding-left: 84px;
  position: relative;
}

.user-preview.landscape .user-preview-cover {
  width: 84px;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.user-preview.landscape .user-preview-info {
  width: 100%;
  padding: 0 28px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-preview.landscape .user-preview-info .user-short-description {
  width: 200px;
}

.user-preview.landscape .user-preview-info .user-short-description + .user-stats {
  margin-top: 0;
}

.user-preview.landscape .user-preview-info .user-avatar-list + .user-preview-actions {
  margin-top: 0;
}

.user-preview.landscape .user-preview-info .user-avatar-list,
.user-preview.landscape .user-preview-info .badge-list,
.user-preview.landscape .user-preview-info .user-preview-social-links-wrap,
.user-preview.landscape .user-preview-info .user-preview-actions,
.user-preview.landscape .user-preview-info .social-links {
  margin-top: 0;
}

.user-preview.landscape .user-preview-info .social-links {
  width: 168px;
}

.user-preview.landscape .user-preview-info .badge-list {
  display: flex;
}

.user-preview.landscape .user-preview-info .badge-list .badge-item {
  margin-right: 8px;
}

.user-preview.landscape .user-preview-info .badge-list .badge-item:last-child {
  margin-right: 0;
}

.user-preview.landscape .user-preview-info .user-preview-social-links-wrap {
  width: 160px;
  margin-left: 8px;
}

.user-preview.landscape .user-preview-info .user-preview-actions {
  margin-top: 0;
}

.user-preview.landscape .user-preview-info .user-preview-actions .button,
.user-preview.landscape .user-preview-info .user-preview-actions .tag-sticker {
  margin-right: 12px;
}

.user-preview.landscape .user-preview-info .user-preview-actions .button:last-child,
.user-preview.landscape .user-preview-info .user-preview-actions .tag-sticker:last-child {
  margin-right: 0;
}

.user-preview.landscape .user-preview-info .user-preview-actions .tag-sticker {
  position: static;
}

.user-preview.landscape .user-preview-info .user-preview-actions .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 44px;
}

.user-preview .user-preview-cover {
  height: 96px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.user-preview .user-preview-info {
  padding: 0 28px 32px;
  position: relative;
}

.user-preview .user-preview-info .tag-sticker {
  position: absolute;
  top: -12px;
  right: 28px;
}

.user-preview .user-preview-info .user-short-description.tiny .user-short-description-title {
  font-size: 0.875rem;
}

.user-preview .user-preview-info .user-short-description.tiny .user-short-description-text {
  font-size: 0.6875rem;
}

.user-preview .user-preview-info .user-short-description .user-short-description-title {
  font-size: 1.25rem;
}

.user-preview .user-preview-info .user-short-description .user-short-description-text {
  font-size: 0.75rem;
}

.user-preview .user-preview-info .user-short-description + .user-stats {
  margin-top: 30px;
}

.user-preview .user-preview-info .user-short-description + .button {
  margin-top: 34px;
}

.user-preview .user-preview-info .badge-list {
  margin-top: 28px;
}

.user-preview .user-preview-info .user-preview-stats-slides {
  margin-top: 40px;
}

.user-preview .user-preview-info .user-preview-stats-roster {
  position: absolute;
  top: 22px;
  right: 28px;
}

.user-preview .user-preview-info .user-preview-text {
  width: 270px;
  margin: -10px auto 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
  text-align: center;
}

.user-preview .user-preview-info .social-links {
  margin-top: 24px;
}

.user-preview .user-preview-info .user-preview-social-links-wrap {
  height: 36px;
  margin-top: 20px;
  position: relative;
}

.user-preview .user-preview-info .user-preview-social-links-wrap .user-preview-social-links {
  padding-top: 4px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.user-preview .user-preview-info .user-preview-social-links-wrap .slider-controls {
  width: 234px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 10px;
  left: -40px;
  z-index: 1;
}

.user-preview .user-preview-info .user-avatar-list {
  margin-top: 34px;
}

.user-preview .user-preview-info .user-avatar-list + .user-preview-actions {
  margin-top: 36px;
}

.user-preview .user-preview-info .user-preview-actions {
  display: flex;
  margin-top: 40px;
}

.user-preview .user-preview-info .user-preview-actions .button {
  width: 100%;
  margin-right: 16px;
}

.user-preview .user-preview-info .user-preview-actions .button:last-child {
  margin-right: 0;
}

.user-preview .user-preview-info .user-preview-actions .button .button-icon {
  fill: #fff;
}

.user-preview .user-preview-info .user-preview-actions .button.white .button-icon {
  fill: #adafca;
}

.user-preview .user-preview-info .user-preview-actions .button.white:hover .button-icon {
  fill: #fff;
}

.user-preview .user-preview-info .user-preview-actions .button.full {
  width: 100%;
}

.user-preview .user-preview-info .user-preview-actions .button.full .button-icon {
  position: relative;
  left: -12px;
}

.user-preview .user-preview-author {
  padding-left: 26px;
  position: relative;
}

.user-preview .user-preview-author .user-preview-author-image {
  position: absolute;
  top: 1px;
  left: 0;
}

.user-preview .user-preview-author .user-preview-author-title {
  font-size: 0.625rem;
  font-weight: 500;
}

.user-preview .user-preview-author .user-preview-author-text {
  margin-top: 1px;
  font-size: 0.75rem;
  font-weight: 700;
}

.user-preview .user-preview-author .user-preview-author-text a {
  color: #3e3f5e;
  font-weight: 700;
}

.user-preview .user-preview-footer {
  display: flex;
  align-items: center;
  height: 65px;
  border-top: 1px solid #eaeaf5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fcfcfd;
}

.user-preview .user-preview-footer.padded {
  height: auto;
  padding: 16px 28px;
}

.user-preview .user-preview-footer .user-preview-footer-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border-right: 1px solid #eaeaf5;
}

.user-preview .user-preview-footer .user-preview-footer-action:last-child {
  border-right: none;
}

.user-preview .user-preview-footer .user-preview-footer-action.full {
  width: 100%;
  padding: 0 28px;
}

.user-preview .user-preview-footer .user-preview-footer-action.full .button {
  width: 100%;
}

.user-preview .user-preview-footer .user-preview-footer-action .button {
  width: 64px;
  height: 44px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1365px) {
  .user-preview.landscape .user-preview-info .badge-list {
    display: none;
  }
}

@media screen and (max-width: 1260px) {
  .user-preview.landscape .user-preview-info .social-links,
  .user-preview.landscape .user-preview-info .user-preview-social-links-wrap,
  .user-preview.landscape .user-preview-info .user-avatar-list {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .user-preview.landscape .user-preview-info .user-stats {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .user-preview.landscape {
    padding-left: 60px;
  }
  .user-preview.landscape .user-preview-cover {
    width: 60px;
  }
  .user-preview.landscape .user-preview-info {
    height: 100%;
    padding: 0 68px 0 0;
  }
  .user-preview.landscape .user-preview-info .user-short-description {
    width: auto;
  }
  .user-preview.landscape .user-preview-info .user-preview-actions {
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 12px;
  }
  .user-preview.landscape .user-preview-info .user-preview-actions .button,
  .user-preview.landscape .user-preview-info .user-preview-actions .tag-sticker {
    width: 44px;
    height: 40px;
    margin: 0 0 4px 0;
  }
  .user-preview.landscape .user-preview-info .user-preview-actions .button:last-child,
  .user-preview.landscape .user-preview-info .user-preview-actions .tag-sticker:last-child {
    margin-bottom: 0;
  }
  .user-preview .user-preview-info .user-preview-social-links-wrap {
    width: 160px;
    margin: 20px auto 0;
  }
  .user-preview .user-preview-info .user-preview-text {
    width: 95%;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .album-preview
2. .photo-preview
-------------------------------------------------------------------*/
/*----------------------
    1. ALBUM PREVIEW
----------------------*/
.album-preview {
  display: block;
  height: 340px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  cursor: pointer;
  position: relative;
}

.album-preview .album-preview-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.album-preview .text-sticker {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 3;
  pointer-events: none;
}

.album-preview .album-preview-info {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: inset 0 -120px 50px -40px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 28px 26px;
  position: relative;
  z-index: 2;
  transition: box-shadow .2s ease-in-out;
}

.album-preview .album-preview-info:hover {
  box-shadow: inset 0 -120px 50px -40px rgba(35, 210, 226, 0.9);
}

.album-preview .album-preview-info .album-preview-title,
.album-preview .album-preview-info .album-preview-text {
  color: #fff;
}

.album-preview .album-preview-info .album-preview-title {
  font-size: 1rem;
  font-weight: 700;
}

.album-preview .album-preview-info .album-preview-text {
  margin-top: 6px;
  font-size: 0.875rem;
  font-weight: 500;
}

/*----------------------
    2. PHOTO PREVIEW
----------------------*/
.photo-preview {
  display: block;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  cursor: pointer;
  position: relative;
}

.photo-preview.small {
  height: 184px;
}

.photo-preview:hover .photo-preview-info {
  opacity: 1;
  visibility: visible;
}

.photo-preview:hover .photo-preview-info .reaction-count-list .reaction-count {
  transform: scale(1);
}

.photo-preview .photo-preview-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.photo-preview .photo-preview-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: rgba(21, 21, 31, 0.96);
  position: relative;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}

.photo-preview .photo-preview-info .reaction-count-list .reaction-count {
  transform: scale(0);
  transition: transform .2s ease-in-out;
}

.photo-preview .photo-preview-info .reaction-count-list .reaction-count:first-child {
  transition-delay: .1s;
}

.photo-preview .photo-preview-info .reaction-count-list .reaction-count:last-child {
  transition-delay: .2s;
}

/*------------------------------------------------------------------
[Table of contents]

1. .popup-box
-------------------------------------------------------------------*/
/*--------------------
    1. POPUP BOX
--------------------*/
.popup-box {
  width: 90%;
  max-width: 984px;
  border-radius: 12px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
}

.popup-box.mid {
  max-width: 784px;
}

.popup-box.small {
  max-width: 384px;
}

.popup-box .popup-box-body {
  display: flex;
}

.popup-box .popup-box-body .popup-box-sidebar {
  width: 36.2244897959%;
  border-right: 1px solid #eaeaf5;
}

.popup-box .popup-box-body .popup-box-sidebar .popup-box-sidebar-footer {
  padding: 28px;
}

.popup-box .popup-box-body .popup-box-sidebar .popup-box-sidebar-footer .button {
  margin-bottom: 16px;
}

.popup-box .popup-box-body .popup-box-sidebar .popup-box-sidebar-footer .button:last-child {
  margin-bottom: 0;
}

.popup-box .popup-box-body .popup-box-sidebar .user-preview {
  box-shadow: none;
  border-top-right-radius: 0;
}

.popup-box .popup-box-body .popup-box-sidebar .user-preview .user-preview-cover {
  border-top-right-radius: 0;
}

.popup-box .popup-box-body .popup-box-sidebar .product-preview {
  box-shadow: none;
  border-top-right-radius: 0;
}

.popup-box .popup-box-body .popup-box-sidebar .product-preview .product-preview-image {
  border-top-right-radius: 0;
}

.popup-box .popup-box-body .popup-box-content {
  width: 63.7755102041%;
  border-bottom-right-radius: 12px;
}

.popup-box .popup-box-body .popup-box-content.limited {
  max-height: 620px;
  overflow-y: auto;
}

.popup-box .popup-box-body .popup-box-content .widget-box {
  box-shadow: none;
}

.popup-box .popup-box-title {
  padding: 32px 28px 0;
  font-size: 1rem;
  font-weight: 700;
}

.popup-box .popup-box-subtitle {
  padding: 0 28px;
  margin-top: 6px;
  font-size: 0.875rem;
  font-weight: 700;
}

.popup-box .popup-box-subtitle .light {
  color: #8f91ac;
  font-weight: 500;
}

.popup-box .form {
  margin-top: 32px;
}

.popup-box .form .form-row {
  padding: 0 28px;
}

.popup-box .form .form-uploadables {
  margin-top: 32px;
  padding: 0 28px 40px;
  height: 406px;
  overflow-y: auto;
}

.popup-box .widget-box .form .form-row {
  padding: 0;
}

.popup-box .popup-box-actions {
  display: flex;
  justify-content: flex-end;
  padding: 32px 38px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.popup-box .popup-box-actions.void {
  box-shadow: none;
}

.popup-box .popup-box-actions.medium {
  padding: 40px 28px;
}

.popup-box .popup-box-actions.full {
  display: block;
  padding: 40px 28px 28px;
}

.popup-box .popup-box-actions .popup-box-action-text {
  margin-top: 24px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.popup-box .popup-box-actions .popup-box-action {
  width: 180px;
  margin-right: 16px;
}

.popup-box .popup-box-actions .popup-box-action.full {
  width: 100%;
}

.popup-box .popup-box-actions .popup-box-action:last-child {
  margin-right: 0;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 680px) {
  .popup-box .popup-box-body {
    display: block;
  }
  .popup-box .popup-box-body .popup-box-sidebar,
  .popup-box .popup-box-body .popup-box-content {
    width: 100%;
  }
  .popup-box .popup-box-body .popup-box-sidebar {
    border-right: none;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .badge-item-preview
-------------------------------------------------------------------*/
/*----------------------------
    1. BADGE ITEM PREVIEW
----------------------------*/
.badge-item-preview {
  display: flex;
  height: 154px;
  padding: 32px 28px 0;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.badge-item-preview .badge-item-preview-image {
  width: 82px;
  height: 90px;
  flex-shrink: 0;
}

.badge-item-preview .badge-item-preview-info {
  padding-left: 24px;
}

.badge-item-preview .badge-item-preview-info .badge-item-preview-title {
  font-size: 1.125rem;
  font-weight: 700;
}

.badge-item-preview .badge-item-preview-info .badge-item-preview-text {
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.badge-item-preview .badge-item-preview-info .badge-item-preview-timestamp {
  margin-top: 14px;
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 460px) {
  .badge-item-preview {
    height: auto;
    padding-bottom: 32px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .badge-item-stat
-------------------------------------------------------------------*/
/*--------------------------
    1. BADGE ITEM STAT
--------------------------*/
.badge-item-stat {
  padding: 32px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.badge-item-stat.void {
  padding: 20px 0 0;
  box-shadow: none;
}

.badge-item-stat .text-sticker {
  position: absolute;
  top: 10px;
  right: -6px;
}

.badge-item-stat .badge-item-stat-image-preview {
  position: absolute;
  top: 32px;
  left: 28px;
}

.badge-item-stat .badge-item-stat-image {
  display: block;
  margin: 0 auto;
}

.badge-item-stat .badge-item-stat-title {
  margin-top: 36px;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
}

.badge-item-stat .badge-item-stat-text {
  width: 180px;
  margin: 16px auto 0;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
  text-align: center;
}

.badge-item-stat .progress-stat {
  max-width: 204px;
  margin: 54px auto 0;
}

/*------------------------------------------------------------------
[Table of contents]

1. .streamer-box
-------------------------------------------------------------------*/
/*----------------------
    1. STREAMER BOX
----------------------*/
.streamer-box {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.streamer-box.small .streamer-box-cover {
  height: 48px;
}

.streamer-box.small .streamer-box-info .streamer-box-image {
  top: -26px;
}

.streamer-box.small .streamer-box-info .streamer-box-title {
  font-size: 1rem;
}

.streamer-box.small .streamer-box-info .button {
  margin-top: 28px;
}

.streamer-box.small .streamer-box-info .button + .button {
  margin-top: 12px;
}

.streamer-box .streamer-box-cover {
  width: 100%;
  height: 70px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.streamer-box .streamer-box-info {
  padding: 50px 28px 32px;
  text-align: center;
  position: relative;
}

.streamer-box .streamer-box-info .streamer-box-image {
  width: 72px;
  height: 72px;
  border: 6px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -36px;
}

.streamer-box .streamer-box-info .streamer-box-title {
  font-size: 1.125rem;
  font-weight: 700;
}

.streamer-box .streamer-box-info .streamer-box-text {
  margin-top: 4px;
  color: #3e3f5e;
  font-size: 0.75rem;
  font-weight: 500;
}

.streamer-box .streamer-box-info .streamer-box-status {
  margin-top: 10px;
  display: inline-block;
  height: 18px;
  padding: 0 6px;
  border-radius: 4px;
  background-color: #adafca;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}

.streamer-box .streamer-box-info .streamer-box-status.active {
  background-color: #f1315e;
}

.streamer-box .streamer-box-info .user-stats {
  margin-top: 38px;
}

.streamer-box .streamer-box-info .button {
  margin-top: 40px;
  width: 100%;
}

/*------------------------------------------------------------------
[Table of contents]

1. .calendar
2. .calendar-weekly
3. .calendar-daily
4. .calendar-events-preview
5. .calendar-event-preview-list
6. .calendar-event-preview
7. .calendar-widget
-------------------------------------------------------------------*/
/*--------------------
    1. CALENDAR
--------------------*/
.calendar {
  background-color: #fff;
}

.calendar.small {
  max-width: 215px;
}

.calendar.small .calendar-week {
  border: none;
}

.calendar.small .calendar-week .calendar-week-day {
  padding: 16px 0;
  font-size: 0.75rem;
}

.calendar.small .calendar-week .calendar-week-day .week-day-short {
  display: block;
}

.calendar.small .calendar-week .calendar-week-day .week-day-long {
  display: none;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 0;
  border: none;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day.inactive {
  background-color: #fff;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day.current .calendar-day-number, .calendar.small .calendar-days .calendar-day-row .calendar-day.active .calendar-day-number {
  width: 24px;
  height: 24px;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day .calendar-day-number {
  font-size: 0.75rem;
  position: static;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day .calendar-day-events {
  position: absolute;
  bottom: 2px;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day .calendar-day-events .calendar-day-event {
  display: block;
  margin: 0 2px 0 0;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day .calendar-day-events .calendar-day-event:last-child {
  margin: 0;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day .calendar-day-event {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  padding: 0;
}

.calendar.small .calendar-days .calendar-day-row .calendar-day .calendar-day-event .calendar-day-event-text {
  display: none;
}

.calendar .calendar-week {
  display: flex;
  border-bottom: 1px solid #eaeaf5;
}

.calendar .calendar-week .calendar-week-day {
  width: 14.2857142857%;
  padding: 28px 0;
  color: #8f91ac;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.calendar .calendar-week .calendar-week-day .week-day-short {
  display: none;
}

.calendar .calendar-days .calendar-day-row {
  display: flex;
}

.calendar .calendar-days .calendar-day-row .calendar-day {
  width: 14.2857142857%;
  height: 120px;
  padding: 52px 12px 0 16px;
  border-right: 1px solid #eaeaf5;
  border-bottom: 1px solid #eaeaf5;
  position: relative;
}

.calendar .calendar-days .calendar-day-row .calendar-day:last-child {
  border-right: none;
}

.calendar .calendar-days .calendar-day-row .calendar-day.inactive {
  background-color: #fcfcfd;
}

.calendar .calendar-days .calendar-day-row .calendar-day.inactive .calendar-day-number {
  color: #8f91ac;
  font-weight: 500;
}

.calendar .calendar-days .calendar-day-row .calendar-day.current .calendar-day-number, .calendar .calendar-days .calendar-day-row .calendar-day.active .calendar-day-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 8px;
  left: 10px;
}

.calendar .calendar-days .calendar-day-row .calendar-day.current .calendar-day-number {
  box-shadow: 3px 5px 20px 0px rgba(94, 92, 154, 0.16);
}

.calendar .calendar-days .calendar-day-row .calendar-day.active .calendar-day-number {
  background-color: #615dfa;
  color: #fff;
}

.calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-number {
  font-size: 1rem;
  font-weight: 700;
  position: absolute;
  top: 20px;
  left: 18px;
  z-index: 1;
}

.calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-events {
  display: flex;
  flex-wrap: wrap;
}

.calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-events .calendar-day-event {
  display: none;
  margin-bottom: 6px;
}

.calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-events .calendar-day-event:nth-child(1), .calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-events .calendar-day-event:nth-child(2) {
  display: block;
}

.calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-events .calendar-day-event:last-child {
  margin-bottom: 0;
}

.calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-event {
  border-radius: 200px;
  padding: 6px 12px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-event.primary {
  background-color: #23d2e2;
}

.calendar .calendar-days .calendar-day-row .calendar-day .calendar-day-event.secondary {
  background-color: #615dfa;
}

/*-------------------------
    2. CALENDAR WEEKLY
-------------------------*/
.calendar-weekly {
  background-color: #fff;
}

.calendar-weekly .calendar-weekly-week {
  display: flex;
  padding-left: 56px;
}

.calendar-weekly .calendar-weekly-week-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 14.2857142857%;
  padding: 28px 0;
}

.calendar-weekly .calendar-weekly-week-day.active .calendar-weekly-week-day-number {
  color: #615dfa;
  font-weight: 700;
}

.calendar-weekly .calendar-weekly-week-day .calendar-weekly-week-day-text {
  color: #8f91ac;
  font-size: 1rem;
  font-weight: 500;
}

.calendar-weekly .calendar-weekly-week-day .calendar-weekly-week-day-number {
  margin-top: 8px;
  font-size: 1.25rem;
  font-weight: 500;
}

.calendar-weekly .calendar-weekly-body-wrap {
  height: 640px;
  overflow-y: auto;
}

.calendar-weekly .calendar-weekly-body {
  padding: 6px 0 0 56px;
  position: relative;
}

.calendar-weekly .calendar-weekly-hours {
  position: absolute;
  top: 0;
  left: 0;
}

.calendar-weekly .calendar-weekly-hours .calendar-weekly-hour {
  height: 120px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
}

.calendar-weekly .calendar-weekly-days {
  display: flex;
}

.calendar-weekly .calendar-weekly-day-column {
  width: 14.2857142857%;
}

.calendar-weekly .calendar-weekly-day-column.active .calendar-weekly-day-interval {
  background-color: #fcfcfd;
}

.calendar-weekly .calendar-weekly-day-column:last-child .calendar-weekly-day-interval {
  border-right: none;
}

.calendar-weekly .calendar-weekly-day-interval {
  width: 100%;
  height: 120px;
  border-top: 1px solid #eaeaf5;
  border-right: 1px solid #eaeaf5;
}

.calendar-weekly .calendar-weekly-day-interval:last-child {
  border-bottom: 1px solid #eaeaf5;
}

.calendar-weekly .calendar-weekly-day-part {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 50%;
  padding: 0 10px;
  border-bottom: 1px solid rgba(234, 234, 245, 0.5);
}

.calendar-weekly .calendar-weekly-day-part:last-child {
  border-bottom: none;
}

.calendar-weekly .calendar-weekly-day-part .calendar-weekly-day-event {
  border-radius: 200px;
  padding: 6px 12px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-weekly .calendar-weekly-day-part .calendar-weekly-day-event.span-3 {
  width: 100%;
  height: 180px;
  border-radius: 12px;
  position: relative;
  z-index: 1;
}

.calendar-weekly .calendar-weekly-day-part .calendar-weekly-day-event.primary {
  background-color: #23d2e2;
}

.calendar-weekly .calendar-weekly-day-part .calendar-weekly-day-event.secondary {
  background-color: #615dfa;
}

/*-------------------------
    3. CALENDAR DAILY
-------------------------*/
.calendar-daily {
  background-color: #fff;
}

.calendar-daily .calendar-daily-body-wrap {
  height: 640px;
  overflow-y: auto;
}

.calendar-daily .calendar-daily-body {
  padding: 6px 0 0 56px;
  position: relative;
}

.calendar-daily .calendar-daily-hours {
  position: absolute;
  top: 0;
  left: 0;
}

.calendar-daily .calendar-daily-hours .calendar-daily-hour {
  height: 120px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
}

.calendar-daily .calendar-daily-interval {
  width: 100%;
  height: 120px;
  border-top: 1px solid #eaeaf5;
}

.calendar-daily .calendar-daily-interval:last-child {
  border-bottom: 1px solid #eaeaf5;
}

.calendar-daily .calendar-daily-interval-part {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 50%;
  border-bottom: 1px solid rgba(234, 234, 245, 0.5);
}

.calendar-daily .calendar-daily-interval-part:last-child {
  border-bottom: none;
}

.calendar-daily .calendar-daily-event {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 12px 14px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-daily .calendar-daily-event.span-3 {
  height: 180px;
  position: relative;
  z-index: 1;
}

.calendar-daily .calendar-daily-event.primary {
  background-color: #23d2e2;
}

.calendar-daily .calendar-daily-event.secondary {
  background-color: #615dfa;
}

/*--------------------------------
    4. CALENDAR EVENTS PREVIEW
---------------------------------*/
.calendar-events-preview {
  padding: 32px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.calendar-events-preview.small .calendar-events-preview-title {
  font-size: 0.875rem;
}

.calendar-events-preview.small .calendar-event-preview-list {
  margin-top: 36px;
}

.calendar-events-preview .calendar-events-preview-title {
  font-size: 1.125rem;
  font-weight: 700;
}

.calendar-events-preview .calendar-event-preview-list {
  margin-top: 48px;
}

/*-------------------------------------
    5. CALENDAR EVENT PREVIEW LIST
-------------------------------------*/
.calendar-event-preview-list .calendar-event-preview {
  margin-bottom: 28px;
}

.calendar-event-preview-list .calendar-event-preview:last-child {
  margin-bottom: 0;
}

/*--------------------------------
    6. CALENDAR EVENT PREVIEW
---------------------------------*/
.calendar-event-preview {
  padding-left: 72px;
  position: relative;
}

.calendar-event-preview.small .calendar-event-preview-start-time .calendar-event-preview-start-time-title {
  font-size: 0.875rem;
}

.calendar-event-preview.small .calendar-event-preview-start-time .calendar-event-preview-start-time-text {
  margin-top: 4px;
  font-size: 0.75rem;
}

.calendar-event-preview.small .calendar-event-preview-info::before {
  content: '';
  width: 8px;
  height: 8px;
  border: 2px solid transparent;
  top: 2px;
}

.calendar-event-preview.small .calendar-event-preview-info .calendar-event-preview-title {
  font-size: 0.875rem;
}

.calendar-event-preview.small .calendar-event-preview-info .calendar-event-preview-text {
  font-size: 0.75rem;
  line-height: 1.1666666667em;
}

.calendar-event-preview.primary .calendar-event-preview-info::before {
  border-color: #23d2e2;
}

.calendar-event-preview.secondary .calendar-event-preview-info::before {
  border-color: #615dfa;
}

.calendar-event-preview .calendar-event-preview-start-time {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.calendar-event-preview .calendar-event-preview-start-time .calendar-event-preview-start-time-title {
  font-size: 1rem;
  font-weight: 700;
}

.calendar-event-preview .calendar-event-preview-start-time .calendar-event-preview-start-time-text {
  margin-top: 2px;
  font-size: 0.875rem;
  font-weight: 500;
}

.calendar-event-preview .calendar-event-preview-info {
  position: relative;
}

.calendar-event-preview .calendar-event-preview-info::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 3px solid transparent;
  position: absolute;
  top: 0;
  left: -24px;
}

.calendar-event-preview .calendar-event-preview-info .calendar-event-preview-title {
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.calendar-event-preview .calendar-event-preview-info .calendar-event-preview-text {
  margin-top: 6px;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.calendar-event-preview .calendar-event-preview-info .calendar-event-preview-time {
  margin-top: 14px;
  font-size: 0.875rem;
  font-weight: 500;
}

.calendar-event-preview .calendar-event-preview-info .calendar-event-preview-time .bold {
  font-weight: 700;
}

/*------------------------
    7. CALENDAR WIDGET
------------------------*/
.calendar-widget {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.calendar-widget .calendar-widget-header {
  padding: 28px 28px 0;
  display: flex;
  justify-content: space-between;
}

.calendar-widget .calendar-widget-header .calendar-widget-header-actions {
  display: flex;
  align-items: center;
}

.calendar-widget .calendar-widget-header .calendar-widget-header-actions .slider-controls {
  margin-right: 25px;
}

.calendar-widget .calendar-widget-title {
  font-size: 1.25rem;
  font-weight: 700;
}

.calendar-widget .slider-controls {
  display: flex;
}

.calendar-widget .slider-controls .slider-control {
  margin-right: 18px;
}

.calendar-widget .slider-controls .slider-control:last-child {
  margin-right: 0;
}

.calendar-widget .calendar,
.calendar-widget .calendar-weekly {
  margin-top: 26px;
  padding: 0 28px;
}

.calendar-widget .calendar-daily {
  margin-top: 60px;
  padding: 0 28px;
}

.calendar-widget .calendar-events-preview {
  margin-top: 60px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 680px) {
  .calendar.full .calendar-week {
    border: none;
  }
  .calendar.full .calendar-week .calendar-week-day {
    padding: 16px 0;
    font-size: 0.75rem;
  }
  .calendar.full .calendar-week .calendar-week-day .week-day-short {
    display: block;
  }
  .calendar.full .calendar-week .calendar-week-day .week-day-long {
    display: none;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding: 0;
    border: none;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day.inactive {
    background-color: #fff;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day.current .calendar-day-number, .calendar.full .calendar-days .calendar-day-row .calendar-day.active .calendar-day-number {
    width: 24px;
    height: 24px;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day .calendar-day-number {
    font-size: 0.75rem;
    position: static;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day .calendar-day-events {
    flex-direction: row;
    position: absolute;
    bottom: 2px;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day .calendar-day-events .calendar-day-event {
    display: block;
    margin: 0 2px 0 0;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day .calendar-day-events .calendar-day-event:last-child {
    margin: 0;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day .calendar-day-event {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    padding: 0;
  }
  .calendar.full .calendar-days .calendar-day-row .calendar-day .calendar-day-event .calendar-day-event-text {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .calendar-widget .calendar-widget-header {
    display: block;
  }
  .calendar-widget .calendar-widget-header .calendar-widget-header-actions {
    justify-content: center;
  }
  .calendar-widget .calendar-widget-header .calendar-widget-header-actions:first-child {
    flex-direction: column;
  }
  .calendar-widget .calendar-widget-header .calendar-widget-header-actions:first-child .slider-controls {
    order: 2;
    margin: 12px 0 0;
  }
  .calendar-widget .calendar-widget-header .calendar-widget-header-actions:last-child {
    margin-top: 18px;
  }
  .calendar-widget .calendar-widget-title {
    font-size: 1rem;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .event-preview-list
2. .event-preview
-------------------------------------------------------------------*/
/*----------------------------
    1. EVENT PREVIEW LIST
----------------------------*/
.event-preview-list .event-preview {
  margin-bottom: 32px;
}

.event-preview-list .event-preview:last-child {
  margin-bottom: 0;
}

/*------------------------
    2. EVENT PREVIEW
------------------------*/
.event-preview {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.event-preview.small {
  box-shadow: none;
}

.event-preview.small .event-preview-cover {
  height: 80px;
  border-radius: 12px;
}

.event-preview.small .event-preview-info {
  display: block;
  height: auto;
  padding: 20px 0 0 42px;
}

.event-preview.small .event-preview-info .date-sticker {
  width: 30px;
  top: 22px;
  left: 0;
}

.event-preview.small .event-preview-info .date-sticker .date-sticker-day {
  padding: 6px 0 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 1rem;
}

.event-preview.small .event-preview-info .date-sticker .date-sticker-month {
  padding: 2px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 0.625rem;
}

.event-preview.small .event-preview-title {
  font-size: 0.875rem;
}

.event-preview.small .event-preview-text {
  margin-top: 12px;
  font-size: 0.75rem;
  line-height: 1.1666666667em;
}

.event-preview.small .event-preview-timestamp {
  margin-top: 6px;
  color: #8f91ac;
  font-size: 0.75rem;
}

.event-preview .event-preview-cover {
  width: 100%;
  height: 98px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.event-preview .event-preview-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 385px;
  padding: 40px 28px 32px;
  position: relative;
}

.event-preview .event-preview-info .date-sticker {
  position: absolute;
  top: -42px;
  left: 28px;
}

.event-preview .event-preview-title {
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.event-preview .event-preview-timestamp {
  margin-top: 10px;
  font-size: 0.875rem;
  font-weight: 500;
}

.event-preview .event-preview-timestamp .bold {
  font-weight: 700;
}

.event-preview .event-preview-text {
  margin-top: 20px;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.event-preview .meta-line {
  margin-top: 26px;
}

.event-preview .button {
  width: 100%;
  margin-top: 38px;
}

/*------------------------------------------------------------------
[Table of contents]

1. .quest-preview-list
2. .quest-preview
-------------------------------------------------------------------*/
/*----------------------------
    1. QUEST PREVIEW LIST
----------------------------*/
.quest-preview-list .quest-preview {
  margin-bottom: 28px;
}

.quest-preview-list .quest-preview:last-child {
  margin-bottom: 0;
}

/*-----------------------
    2. QUEST PREVIEW
-----------------------*/
.quest-preview .quest-preview-info {
  padding: 2px 8px 0 42px;
  position: relative;
}

.quest-preview .quest-preview-info .quest-preview-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.quest-preview .quest-preview-info .quest-preview-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.quest-preview .quest-preview-info .quest-preview-text {
  margin-top: 4px;
  color: #3e3f5e;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.3333333333em;
}

.quest-preview .progress-stat {
  margin-top: 16px;
}

/*------------------------------------------------------------------
[Table of contents]

1. .quest-item
-------------------------------------------------------------------*/
/*---------------------
    1. QUEST ITEM
---------------------*/
.quest-item {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.quest-item .quest-item-cover {
  width: 100%;
  height: 120px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.quest-item .text-sticker {
  position: absolute;
  top: 10px;
  right: -6px;
}

.quest-item .quest-item-info {
  padding: 44px 28px 0;
  position: relative;
}

.quest-item .quest-item-badge {
  border: 6px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: -28px;
  left: 22px;
}

.quest-item .quest-item-title {
  font-size: 1.125rem;
  font-weight: 700;
}

.quest-item .quest-item-text {
  margin-top: 18px;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.quest-item .progress-stat {
  max-width: 228px;
  margin-top: 48px;
}

.quest-item .quest-item-meta {
  display: flex;
  align-items: center;
  margin-top: 22px;
  padding: 14px 0;
  border-top: 1px solid #eaeaf5;
}

.quest-item .quest-item-meta .quest-item-meta-info {
  margin-left: 10px;
}

.quest-item .quest-item-meta .quest-item-meta-title {
  font-size: 0.75rem;
  font-weight: 700;
}

.quest-item .quest-item-meta .quest-item-meta-text {
  color: #3e3f5e;
  font-size: 0.625rem;
  font-weight: 500;
}

/*------------------------------------------------------------------
[Table of contents]

1. .forum-post-header
2. .forum-post-list
3. .forum-post
-------------------------------------------------------------------*/
/*---------------------------
    1. FORUM POST HEADER
---------------------------*/
.forum-post-header {
  display: flex;
  align-items: center;
  height: 40px;
}

.forum-post-header .forum-post-header-title {
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.forum-post-header .forum-post-header-title:first-child {
  width: 184px;
  padding-left: 28px;
  flex-shrink: 0;
}

.forum-post-header .forum-post-header-title:last-child {
  width: 79.185520362%;
}

/*------------------------
    2. FORUM POST LIST
------------------------*/
.forum-post-list {
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.forum-post-list .forum-post {
  box-shadow: none;
}

.forum-post-list .forum-post:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.forum-post-list .forum-post:first-child .forum-post-meta {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.forum-post-list .forum-post:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

/*--------------------
    3. FORUM POST
--------------------*/
.forum-post {
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.forum-post .forum-post-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  padding: 0 28px;
  background-color: #fcfcfd;
}

.forum-post .forum-post-timestamp {
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
}

.forum-post .forum-post-actions {
  display: flex;
}

.forum-post .forum-post-actions .forum-post-action {
  margin-right: 32px;
}

.forum-post .forum-post-actions .forum-post-action:last-child {
  margin-right: 0;
}

.forum-post .forum-post-action {
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
}

.forum-post .forum-post-action:hover {
  color: #00c7d9;
}

.forum-post .forum-post-action.light {
  color: #adafca;
}

.forum-post .forum-post-action.light:hover {
  color: #3e3f5e;
}

.forum-post .forum-post-content {
  display: flex;
  min-height: 234px;
}

.forum-post .forum-post-content .forum-post-user {
  width: 184px;
  flex-shrink: 0;
}

.forum-post .forum-post-content .forum-post-info {
  width: 79.185520362%;
}

.forum-post .forum-post-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;
}

.forum-post .forum-post-user .user-avatar.small {
  display: none;
}

.forum-post .forum-post-user .forum-post-user-title {
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: 700;
}

.forum-post .forum-post-user .forum-post-user-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.forum-post .forum-post-user .forum-post-user-text {
  margin-top: 4px;
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
}

.forum-post .forum-post-user .forum-post-user-text a {
  color: #8f91ac;
  font-weight: 500;
}

.forum-post .forum-post-user .forum-post-user-text a:hover {
  color: #00c7d9;
}

.forum-post .forum-post-user .forum-post-user-tag {
  height: 20px;
  margin-top: 12px;
  padding: 0 8px;
  border-radius: 200px;
  background-color: #45437f;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.forum-post .forum-post-user .forum-post-user-tag.organizer {
  background-color: #615dfa;
}

.forum-post .forum-post-info {
  padding: 32px 28px 32px 0;
}

.forum-post .forum-post-info .forum-post-paragraph {
  margin-top: 24px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

.forum-post .forum-post-info .forum-post-paragraph:first-child {
  margin-top: 0;
}

.forum-post .forum-post-info .forum-post-paragraph .bold {
  font-weight: 700;
}

.forum-post .forum-post-info .forum-post-image {
  margin-top: 24px;
  width: 75%;
  height: auto;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 460px) {
  .forum-post-header {
    display: none;
  }
  .forum-post .forum-post-content {
    display: block;
  }
  .forum-post .forum-post-content .forum-post-user,
  .forum-post .forum-post-content .forum-post-info {
    width: 100%;
  }
  .forum-post .forum-post-actions .forum-post-action {
    margin-right: 16px;
  }
  .forum-post .forum-post-actions .forum-post-action:last-child {
    margin-right: 0;
  }
  .forum-post .forum-post-user {
    align-items: flex-start;
    padding-left: 28px;
  }
  .forum-post .forum-post-user .user-avatar {
    display: none;
  }
  .forum-post .forum-post-user .user-avatar.small {
    display: block;
  }
  .forum-post .forum-post-info {
    padding-left: 28px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .slider-panel
2. .roster-pictures
3. .roster-picture
-------------------------------------------------------------------*/
/*----------------------
    1. SLIDER PANEL
----------------------*/
.slider-panel .slider-panel-slide {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.slider-panel .slider-panel-slide-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-panel .slider-panel-roster {
  display: flex;
  align-items: center;
  height: 100px;
  margin-top: 16px;
  padding: 0 46px 0 74px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.slider-panel .slider-panel-roster .slider-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.slider-panel .slider-panel-roster .slider-controls::before, .slider-panel .slider-panel-roster .slider-controls::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 40px;
}

.slider-panel .slider-panel-roster .slider-controls::before {
  left: 46px;
}

.slider-panel .slider-panel-roster .slider-controls::after {
  display: none;
  right: 46px;
}

.slider-panel .slider-panel-roster .button {
  width: 180px;
  margin-right: 28px;
  position: relative;
  z-index: 2;
}

.slider-panel .slider-panel-roster .roster-pictures {
  position: relative;
  z-index: 2;
}

/*------------------------
    2. ROSTER PICTURES
------------------------*/
.roster-pictures {
  display: flex;
  padding: 0 22px;
  position: relative;
}

.roster-pictures::before, .roster-pictures::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 14px;
}

.roster-pictures::before {
  left: 0;
}

.roster-pictures::after {
  right: 0;
}

.roster-pictures .roster-picture {
  margin-right: 28px;
}

.roster-pictures .roster-picture:last-child {
  margin-right: 0;
}

/*------------------------
    3. ROSTER PICTURE
------------------------*/
.roster-picture {
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: relative;
}

.roster-picture.tns-nav-active::before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 4px solid #615dfa;
  position: absolute;
  top: 0;
  left: 0;
}

.roster-picture .roster-picture-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1365px) {
  .slider-panel .slider-panel-roster {
    justify-content: center;
  }
  .slider-panel .slider-panel-roster .roster-pictures {
    display: none;
  }
  .slider-panel .slider-panel-roster .slider-controls::after {
    display: block;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .chart-wrap
2. .chart
-------------------------------------------------------------------*/
/*----------------------
    1. CHART WRAP
----------------------*/
.chart-wrap {
  width: 100%;
  height: 360px;
}

.chart-wrap.small {
  height: 210px;
}

/*---------------
    2. CHART
---------------*/
.chart {
  width: 100%;
  height: 100%;
  position: relative;
}

.demo-box {
  padding: 48px 28px 32px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.demo-box::before {
  content: '';
  width: 100%;
  height: 48px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #615dfa;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.demo-box:nth-child(2n+2)::before {
  background-color: #23d2e2;
}

.demo-box .demo-box-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #fff;
  margin: -26px auto 0;
  position: relative;
  z-index: 2;
}

.demo-box .demo-box-icon-wrap .demo-box-icon {
  fill: #3e3f5e;
}

.demo-box .demo-box-title,
.demo-box .demo-box-text {
  text-align: center;
}

.demo-box .demo-box-title {
  margin-top: 8px;
  font-size: 1rem;
  font-weight: 700;
}

.demo-box .demo-box-text {
  margin-top: 4px;
  font-size: 0.75rem;
  font-weight: 500;
}

.demo-box .button {
  margin-top: 36px;
  overflow: hidden;
  position: relative;
}

.demo-box .button .active-text,
.demo-box .button .inactive-text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform .3s ease-in-out;
}

.demo-box .button .active-text {
  transform: translate(0, 100%);
}

.demo-box .button.active {
  background-color: #23d2e2;
}

.demo-box .button.active .inactive-text {
  transform: translate(0, -100%);
}

.demo-box .button.active .active-text {
  transform: translate(0, 0);
}

/*-------------
    LAYOUT
-------------*/
/*------------------------------------------------------------------
[Table of contents]

1. .header
2. .sidemenu-trigger
3. .floaty-bar
-------------------------------------------------------------------*/
/*-----------------
    1. HEADER
-----------------*/
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #615dfa;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}

.header .header-actions {
  display: flex;
  align-items: center;
}

.header .header-actions.search-bar {
  width: 30%;
}

.header .header-actions.search-bar .header-search-dropdown {
  width: 100%;
}

.header .header-actions .progress-stat {
  width: 110px;
}

.header .header-actions .register-button {
  padding: 0 26px;
  margin: 0 30px;
}

.header .header-actions .login-form {
  margin: 0 14px 0 12px;
}

.header .header-actions .login-form .button {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
}

.header .header-brand {
  display: flex;
  align-items: center;
}

.header .header-brand .logo {
  margin-left: 26px;
}

.header .header-brand .header-brand-text {
  margin-left: 26px;
  color: #fff;
  font-family: "Titillium Web", sans-serif;
  font-size: 1.25rem;
  font-weight: 900;
  text-transform: uppercase;
}

.header .sidemenu-trigger,
.header .mobilemenu-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.header .sidemenu-trigger.active .icon-grid, .header .sidemenu-trigger:hover .icon-grid {
  fill: #fff;
}

.header .sidemenu-trigger .icon-grid {
  pointer-events: none;
  transition: fill .3s ease-in-out;
}

.header .mobilemenu-trigger {
  display: none;
}

.header .input-search {
  width: 100%;
}

.header .action-list {
  height: 80px;
}

.header .action-list::before, .header .action-list::after {
  top: 24px;
}

.header .action-item {
  width: 80px;
  height: 80px;
}

/*--------------------------
    2. SIDEMENU TRIGGER
--------------------------*/
.sidemenu-trigger .icon-grid {
  fill: #8b88ff;
}

.sidemenu-trigger.active .icon-grid {
  fill: #fff;
}

/*---------------------
    3. FLOATY BAR
---------------------*/
.floaty-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #615dfa;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: none;
}

.floaty-bar .bar-actions {
  display: flex;
  align-items: center;
}

.floaty-bar .bar-actions .progress-stat {
  width: 110px;
  margin: 0 32px;
}

.floaty-bar .action-list {
  height: 60px;
}

.floaty-bar .action-list::before, .floaty-bar .action-list::after {
  top: 14px;
}

.floaty-bar .action-list .action-list-item.unread::after {
  top: 16px;
}

.floaty-bar .action-item {
  width: 80px;
  height: 60px;
}

.floaty-bar .login-button {
  padding: 0 26px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1500px) {
  .header .header-brand .header-brand-text {
    display: none;
  }
}

@media screen and (max-width: 1365px) {
  .header.logged-out .header-actions .mobilemenu-trigger {
    display: flex;
  }
  .header .header-actions .navigation {
    display: none;
  }
  .header .header-actions .progress-stat {
    margin-right: 28px;
  }
  .header .header-actions.search-bar {
    width: 50%;
    margin-right: 26px;
  }
}

@media screen and (max-width: 960px) {
  .header.logged-out .header-actions:nth-last-child(2) {
    display: block;
  }
  .header.logged-out .header-actions:last-child {
    display: none;
  }
  .header.logged-out .header-actions .register-button,
  .header.logged-out .header-actions .navigation {
    display: none;
  }
  .header .header-actions:nth-last-child(2) {
    display: none;
  }
  .header .header-actions .navigation {
    display: none;
  }
  .header .header-actions.search-bar {
    width: 80%;
  }
  .floaty-bar.logged-out {
    display: flex;
  }
}

@media screen and (max-width: 680px) {
  .header {
    height: 60px;
  }
  .header .header-actions:nth-last-child(1) {
    display: none;
  }
  .header .header-actions.search-bar {
    height: 100%;
    margin-right: 0;
  }
  .header .header-actions.search-bar .interactive-input {
    height: 100%;
  }
  .header .header-actions.search-bar .interactive-input input {
    border-radius: 0;
  }
  .header .header-actions .header-brand .logo {
    margin-left: 16px;
  }
  .header .header-actions .sidemenu-trigger {
    display: none;
  }
  .header .header-actions .mobilemenu-trigger {
    display: flex;
  }
  .floaty-bar {
    display: flex;
  }
}

@media screen and (max-width: 480px) {
  .floaty-bar .bar-actions:nth-child(1) {
    display: none;
  }
  .floaty-bar .action-list {
    padding: 0;
  }
  .floaty-bar .action-list::before, .floaty-bar .action-list::after {
    display: none;
  }
  .floaty-bar .action-list .action-list-item {
    padding: 0 20px;
  }
  .floaty-bar .action-item {
    width: auto;
    padding: 0 20px;
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .navigation
2. .menu-main
3. .menu
4. .section-navigation
5. .section-menu
-------------------------------------------------------------------*/
/*---------------------
    1. NAVIGATION
---------------------*/
.navigation {
  height: 80px;
}

/*---------------------
    2. MENU MAIN
---------------------*/
.menu-main {
  display: flex;
}

.menu-main .menu-main-item {
  position: relative;
}

.menu-main .menu-main-item .menu-main-item-link {
  padding: 0 24px;
  display: block;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 80px;
  cursor: pointer;
  transition: color .2s ease-in-out;
}

.menu-main .menu-main-item .menu-main-item-link .icon-dots {
  transition: fill .2s ease-in-out;
}

.menu-main .menu-main-item:hover > .menu-main-item-link {
  color: #41efff;
}

.menu-main .menu-main-item:hover > .menu-main-item-link .icon-dots {
  fill: #41efff;
}

.menu-main .menu-main-item:hover > .menu-main {
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.menu-main .menu-main-item .menu-main {
  flex-flow: column;
  width: 120px;
  padding: 8px 0;
  border-radius: 10px;
  background-color: #615dfa;
  position: absolute;
  top: 64px;
  transform: translate(0, -40px);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.menu-main .menu-main-item .menu-main .menu-main-item .menu-main-item-link {
  padding: 8px 0 8px 18px;
  line-height: 1em;
}

/*---------------
    3. MENU
---------------*/
.menu.small .menu-item.active .menu-item-link:hover {
  padding-left: 0;
}

.menu.small .menu-item .menu-item-link {
  padding-left: 0;
}

.menu.small .menu-item .menu-item-link:hover {
  padding-left: 0;
}

.menu .menu-item {
  padding: 0 16px;
  margin-bottom: 10px;
}

.menu .menu-item:last-child {
  margin-bottom: 0;
}

.menu .menu-item.active .menu-item-link {
  color: #fff;
  background-color: #23d2e2;
  box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
}

.menu .menu-item.active .menu-item-link:hover {
  background-color: #23d2e2;
  color: #fff;
  padding-left: 62px;
  box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
}

.menu .menu-item.active .menu-item-link:hover .menu-item-link-icon {
  fill: #fff;
}

.menu .menu-item.active .menu-item-link .menu-item-link-icon {
  fill: #fff;
}

.menu .menu-item .menu-item-link {
  display: block;
  height: 48px;
  padding-left: 62px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 48px;
  position: relative;
  transition: all .2s ease-in-out;
}

.menu .menu-item .menu-item-link:hover {
  background-color: #fff;
  color: #3e3f5e;
  padding-left: 70px;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.12);
}

.menu .menu-item .menu-item-link:hover .menu-item-link-icon {
  fill: #23d2e2;
}

.menu .menu-item .menu-item-link .menu-item-link-icon {
  position: absolute;
  top: 14px;
  left: 14px;
  pointer-events: none;
  transition: all .2s ease-in-out;
}

/*-----------------------------
    4. SECTION NAVIGATION
-----------------------------*/
.section-navigation {
  height: 80px;
  padding: 0 43px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
}

.section-navigation .slider-controls .slider-control {
  width: 43px;
  height: 80px;
  position: absolute;
  top: 0;
}

.section-navigation .slider-controls .slider-control.left {
  left: 0;
}

.section-navigation .slider-controls .slider-control.right {
  right: 0;
}

/*----------------------
    5. SECTION MENU
----------------------*/
.section-menu {
  height: 80px;
  overflow: hidden;
}

.section-menu.secondary .section-menu-item.active, .section-menu.secondary .section-menu-item:hover {
  border-bottom: 4px solid #615dfa;
}

.section-menu.secondary .section-menu-item.active .section-menu-item-icon {
  fill: #3e3f5e;
}

.section-menu.medium .section-menu-item {
  width: 160px;
}

.section-menu .section-menu-item {
  float: left;
  width: 122px;
  height: 80px;
  position: relative;
}

.section-menu .section-menu-item.active {
  border-bottom: 4px solid #23d2e2;
}

.section-menu .section-menu-item.active .section-menu-item-icon {
  fill: #3e3f5e;
  opacity: 1;
}

.section-menu .section-menu-item:hover {
  border-bottom: 4px solid #23d2e2;
}

.section-menu .section-menu-item:hover .section-menu-item-icon {
  transform: translate(0, -20px);
  opacity: 0;
  visibility: hidden;
}

.section-menu .section-menu-item:hover .section-menu-item-text {
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
}

.section-menu .section-menu-item::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 30px;
  right: 0;
}

.section-menu .section-menu-item:first-child::before {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 30px;
  left: 0;
}

.section-menu .section-menu-item .section-menu-item-icon {
  fill: #adafca;
  opacity: .6;
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -10px;
  transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
}

.section-menu .section-menu-item .section-menu-item-text {
  width: 100%;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 34px;
  transform: translate(0, 20px);
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
}

/*------------------------------------------------------------------
[Table of contents]

1. .sidebar
-------------------------------------------------------------------*/
/*-------------------
    1. SIDEBAR
-------------------*/
.sidebar {
  height: 100%;
  padding-top: 80px;
  position: fixed;
  top: 0;
  z-index: 9999;
}

.sidebar.right {
  right: 0;
}

.sidebar.left {
  left: 0;
}

.sidebar.navigation-widget {
  padding-top: 0;
  margin-top: 80px;
  overflow-y: auto;
  transition: transform .35s ease-in-out;
}

.sidebar.navigation-widget.navigation-widget-mobile {
  margin-top: 0;
  z-index: 100000;
}

.sidebar.navigation-widget.closed {
  padding-top: 20px;
  overflow-y: visible;
}

.sidebar.navigation-widget.delayed {
  transition-delay: .25s;
}

.sidebar.navigation-widget.hidden {
  transform: translate(-100%, 0);
}

.sidebar.chat-widget {
  height: 100%;
}

.sidebar.chat-widget.chat-widget-overlay {
  height: auto;
}

.sidebar.chat-widget.chat-widget-overlay .chat-widget-form {
  bottom: 0;
}

.sidebar.chat-widget.closed {
  transform: translate(220px, 0);
}

.sidebar.chat-widget.closed .chat-widget-form {
  bottom: 0;
}

.sidebar.chat-widget.hidden {
  transform: translate(100%, 0);
}

.sidebar.chat-widget .chat-widget-form,
.sidebar.chat-widget .chat-widget-button {
  position: absolute;
  left: 0;
  z-index: 9999;
}

.sidebar.chat-widget .chat-widget-form {
  bottom: 80px;
}

.sidebar.chat-widget .chat-widget-button {
  bottom: 0;
}

/*------------------------------------------------------------------
[Table of contents]

1. .section
2. .section-header
3. .section-header-info
4. .section-header-actions
5. .section-filters-bar
6. .section-pager-bar-wrap
7. .section-pager-bar
8. .section-pager
9. .section-results-text
10. .section-banner
-------------------------------------------------------------------*/
/*---------------------
    1. SECTION
---------------------*/
.section .section-filters-bar {
  margin-top: 30px;
}

/*-----------------------
    2. SECTION HEADER
-----------------------*/
.section-header {
  display: flex;
  justify-content: space-between;
}

/*-----------------------------
    3. SECTION HEADER INFO
-----------------------------*/
.section-header-info .section-pretitle {
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.section-header-info .section-title {
  margin-top: 8px;
  font-size: 1.625rem;
  font-weight: 700;
}

.section-header-info .section-title .highlighted {
  color: #00c7d9;
}

.section-header-info .section-title .highlighted.secondary {
  color: #615dfa;
}

.section-header-info .section-title.pinned::before {
  content: 'pinned';
  display: inline-block;
  margin-right: 12px;
  padding: 4px 8px;
  border-radius: 200px;
  background-color: #23d2e2;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  position: relative;
  top: -4px;
}

/*--------------------------------
    4. SECTION HEADER ACTIONS
--------------------------------*/
.section-header-actions {
  display: flex;
  align-items: flex-end;
  padding-bottom: 4px;
}

.section-header-actions .section-header-action {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
  transition: color .2s ease-in-out;
}

.section-header-actions .section-header-action:hover {
  color: #3e3f5e;
}

.section-header-actions .section-header-action + .section-header-action {
  margin-left: 32px;
}

.section-header-actions .slider-controls {
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.section-header-actions .section-header-subsection {
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 700;
}

.section-header-actions .section-header-subsection:last-child {
  color: #adafca;
}

.section-header-actions .section-header-subsection + .section-header-subsection {
  margin-left: 26px;
  position: relative;
}

.section-header-actions .section-header-subsection + .section-header-subsection::before {
  content: '';
  width: 2px;
  height: 10px;
  background-color: #23d2e2;
  position: absolute;
  top: 2px;
  left: -13px;
}

/*------------------------------
    5. SECTION FILTERS BAR
------------------------------*/
.section-filters-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  padding: 0 28px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  background-color: #fff;
}

.section-filters-bar .form + .button {
  margin-top: 0;
}

.section-filters-bar.centered {
  justify-content: center;
}

.section-filters-bar.v1 .form .form-select {
  display: none;
}

.section-filters-bar.v3 .form {
  display: none;
}

.section-filters-bar.v5 .section-filters-bar-actions:first-child .form {
  display: none;
}

.section-filters-bar .section-filters-bar-actions {
  display: flex;
  align-items: center;
}

.section-filters-bar .section-filters-bar-actions .form + .filter-tabs {
  margin-left: 80px;
}

.section-filters-bar .section-filters-bar-actions .form + .button {
  flex-shrink: 0;
  margin-left: 16px;
}

.section-filters-bar .form-input.with-button {
  width: 240px;
}

.section-filters-bar .form-input.with-button input {
  padding-right: 82px;
}

.section-filters-bar .form-item.split.medium .button {
  width: 180px;
}

.section-filters-bar .form-item.split .form-input-decorated {
  width: 188px;
}

.section-filters-bar .form-item.split .form-input-decorated .form-input {
  width: 100%;
}

.section-filters-bar .form-item.split .form-input,
.section-filters-bar .form-item.split .form-select {
  width: 300px;
}

.section-filters-bar .form-item.split .form-select.small {
  width: 254px;
}

.section-filters-bar .form-item.split .button {
  width: 64px;
}

.section-filters-bar .button {
  width: 180px;
}

.section-filters-bar .filter-tabs {
  height: 96px;
}

.section-filters-bar .section-filters-bar-title {
  font-size: 1rem;
  font-weight: 700;
}

.section-filters-bar .section-filters-bar-title a {
  color: #3e3f5e;
  font-weight: 700;
}

.section-filters-bar .section-filters-bar-title .separator {
  display: inline-block;
  width: 2px;
  height: 10px;
  margin: 0 12px;
  background-color: #23d2e2;
}

.section-filters-bar .section-filters-bar-title + .section-filters-bar-text {
  margin-top: 8px;
}

.section-filters-bar .section-filters-bar-title + .section-filters-bar-text.small-space {
  margin-top: 4px;
}

.section-filters-bar .section-filters-bar-text {
  display: flex;
  align-items: center;
  font-family: "Rajdhani", sans-serif;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 500;
}

.section-filters-bar .section-filters-bar-text .bold {
  font-weight: 700;
}

.section-filters-bar .section-filters-bar-text .bold:last-child {
  margin-right: 4px;
}

.section-filters-bar .section-filters-bar-text .highlighted {
  color: #00c7d9;
  font-weight: 700;
  margin: 0 4px;
}

.section-filters-bar .section-filters-bar-text .user-avatar {
  margin: 0 4px;
}

/*--------------------------------
    6. SECTION PAGER BAR WRAP
--------------------------------*/
.section-pager-bar-wrap {
  margin-top: 32px;
}

.section-pager-bar-wrap.align-right {
  text-align: right;
}

.section-pager-bar-wrap.align-center {
  text-align: center;
}

.section-pager-bar-wrap .section-pager-bar {
  display: inline-block;
  margin: 0;
}

/*----------------------------
    7. SECTION PAGER BAR
----------------------------*/
.section-pager-bar {
  width: 494px;
  height: 60px;
  margin: 32px auto 0;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
  overflow: hidden;
}

.section-pager-bar .section-pager {
  height: 100%;
  position: absolute;
  top: 0;
  left: 60px;
  z-index: 1;
}

.section-pager-bar .section-pager-controls .slider-control {
  width: 60px;
  height: 60px;
  background-color: #fff;
  position: absolute;
  top: 0;
  z-index: 2;
}

.section-pager-bar .section-pager-controls .slider-control.left {
  left: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.section-pager-bar .section-pager-controls .slider-control.right {
  right: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

/*------------------------
    8. SECTION PAGER
------------------------*/
.section-pager {
  display: flex;
  align-items: center;
}

.section-pager.secondary .section-pager-item.active .section-pager-item-text, .section-pager.secondary .section-pager-item:hover .section-pager-item-text {
  color: #615dfa;
}

.section-pager .section-pager-item {
  padding: 0 8px;
  cursor: pointer;
  position: relative;
}

.section-pager .section-pager-item::before {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 12px;
  left: 0;
}

.section-pager .section-pager-item:last-child::after {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #eaeaf5;
  position: absolute;
  top: 12px;
  right: 0;
}

.section-pager .section-pager-item.active .section-pager-item-text, .section-pager .section-pager-item:hover .section-pager-item-text {
  background-color: #fff;
  color: #00c7d9;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.12);
}

.section-pager .section-pager-item .section-pager-item-text {
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 700;
  transition: color .2s ease-in-out, box-shadow .2s ease-in-out, background-color .2s ease-in-out;
}

/*------------------------------
    9. SECTION RESULTS TEXT
------------------------------*/
.section-results-text {
  margin-top: -12px;
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
}

/*-------------------------
    10. SECTION BANNER
-------------------------*/
.section-banner {
  min-height: 160px;
  padding: 52px 60px 0 200px;
  border-radius: 12px;
  background: url("../img/banner/banner-bg.png") no-repeat center;
  position: relative;
}

.section-banner .section-banner-icon {
  position: absolute;
  bottom: 0;
  left: 0;
}

.section-banner .section-banner-title,
.section-banner .section-banner-text {
  color: #fff;
}

.section-banner .section-banner-title {
  font-size: 2.25rem;
  font-weight: 700;
}

.section-banner .section-banner-text {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 500;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 1500px) {
  .section-filters-bar.v4 .form-item.split .form-select.small {
    width: 200px;
  }
}

@media screen and (max-width: 1365px) {
  .section-filters-bar.v1, .section-filters-bar.v3, .section-filters-bar.v4, .section-filters-bar.v5, .section-filters-bar.v7 {
    flex-direction: column;
    height: auto;
    padding: 32px 28px;
  }
  .section-filters-bar.v1 .filter-tabs, .section-filters-bar.v3 .filter-tabs, .section-filters-bar.v5 .filter-tabs {
    display: none;
  }
  .section-filters-bar.v1 .section-filters-bar-actions, .section-filters-bar.v4 .section-filters-bar-actions, .section-filters-bar.v7 .section-filters-bar-actions {
    margin-bottom: 40px;
  }
  .section-filters-bar.v1 .section-filters-bar-actions:last-child, .section-filters-bar.v4 .section-filters-bar-actions:last-child, .section-filters-bar.v7 .section-filters-bar-actions:last-child {
    margin-bottom: 0;
  }
  .section-filters-bar.v1 .section-filters-bar-actions .form {
    display: flex;
  }
  .section-filters-bar.v1 .section-filters-bar-actions .form .form-select {
    display: block;
    width: 254px;
    margin-left: 16px;
  }
  .section-filters-bar.v3 .form {
    display: block;
  }
  .section-filters-bar.v4 .section-filters-bar-actions {
    margin-bottom: 40px;
  }
  .section-filters-bar.v5 .section-filters-bar-actions {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .section-filters-bar.v5 .section-filters-bar-actions:first-child .form {
    display: block;
  }
  .section-filters-bar.v5 .section-filters-bar-actions:first-child .form .form-select {
    margin-bottom: 24px;
  }
  .section-filters-bar.v5 .section-filters-bar-actions .form-item.split {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .section-filters-bar.v5 .section-filters-bar-actions .form-item.split .form-select {
    width: 100%;
    margin: 0 0 24px 0;
  }
  .section-results-text {
    margin-top: 24px;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .section-filters-bar.v1 .section-filters-bar-actions {
    width: 100%;
    justify-content: center;
  }
  .section-filters-bar.v1 .section-filters-bar-actions .form {
    display: block;
  }
  .section-filters-bar.v1 .section-filters-bar-actions .form .form-input.with-button {
    width: 100%;
  }
  .section-filters-bar.v1 .section-filters-bar-actions .form .form-select {
    width: 100%;
    margin: 24px 0 0 0;
  }
  .section-filters-bar.v2 {
    flex-direction: column;
    height: auto;
    padding: 32px 28px;
  }
  .section-filters-bar.v2 .form .form-item.split {
    flex-direction: column;
    align-items: center;
  }
  .section-filters-bar.v2 .form .form-item.split .form-select {
    width: 100%;
    margin: 0 0 24px 0;
  }
  .section-filters-bar.v4 .section-filters-bar-actions {
    width: 100%;
    margin-bottom: 0;
  }
  .section-filters-bar.v4 .section-filters-bar-actions .form-item.split {
    flex-direction: column;
    align-items: center;
  }
  .section-filters-bar.v4 .section-filters-bar-actions .form-item.split .form-select,
  .section-filters-bar.v4 .section-filters-bar-actions .form-item.split .form-input {
    width: 100%;
    margin: 0 0 24px 0;
  }
  .section-filters-bar.v4 .section-filters-bar-actions:first-child .form-item.split {
    position: relative;
  }
  .section-filters-bar.v4 .section-filters-bar-actions:first-child .form-item.split .form-input {
    width: 100%;
  }
  .section-filters-bar.v4 .section-filters-bar-actions:first-child .form-item.split .form-input input {
    padding-right: 82px;
  }
  .section-filters-bar.v4 .section-filters-bar-actions:first-child .form-item.split .button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .section-filters-bar.v6 {
    flex-direction: column;
    height: auto;
    padding: 32px 28px;
  }
  .section-filters-bar.v6.v6-2 .section-filters-bar-actions:first-child .form-item.split {
    flex-direction: column;
    position: relative;
  }
  .section-filters-bar.v6.v6-2 .section-filters-bar-actions:first-child .form-item.split .form-input-decorated {
    width: 100%;
  }
  .section-filters-bar.v6.v6-2 .section-filters-bar-actions:first-child .form-item.split .form-input {
    width: 100%;
  }
  .section-filters-bar.v6.v6-2 .section-filters-bar-actions:first-child .form-item.split .form-input input {
    padding-right: 0;
  }
  .section-filters-bar.v6.v6-2 .section-filters-bar-actions:first-child .form-item.split .button {
    width: 180px;
    margin: 0 auto 16px;
    position: static;
  }
  .section-filters-bar.v6 .section-filters-bar-actions {
    width: 100%;
    justify-content: center;
  }
  .section-filters-bar.v6 .section-filters-bar-actions:first-child .form-item.split {
    position: relative;
  }
  .section-filters-bar.v6 .section-filters-bar-actions:first-child .form-item.split .form-input {
    width: 100%;
    margin-bottom: 24px;
  }
  .section-filters-bar.v6 .section-filters-bar-actions:first-child .form-item.split .form-input input {
    padding-right: 82px;
  }
  .section-filters-bar.v6 .section-filters-bar-actions:first-child .form-item.split .button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .section-filters-bar.v7 .section-filters-bar-actions {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .section-filters-bar.v7 .section-filters-bar-actions .form + .button {
    margin-top: 24px;
  }
  .section-filters-bar.v7 .section-filters-bar-actions .form-item.split {
    position: relative;
  }
  .section-filters-bar.v7 .section-filters-bar-actions .form-item.split .form-input {
    width: 100%;
  }
  .section-filters-bar.v7 .section-filters-bar-actions .form-item.split .form-input input {
    padding-right: 82px;
  }
  .section-filters-bar.v7 .section-filters-bar-actions .form-item.split .button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .section-pager-bar {
    width: 306px;
  }
  .section-banner {
    height: 86px;
    min-height: auto;
    padding: 18px 28px 0 108px;
  }
  .section-banner .section-banner-icon {
    width: 92px;
    height: 86px;
  }
  .section-banner .section-banner-title {
    font-size: 1.5rem;
  }
  .section-banner .section-banner-text {
    margin-top: 6px;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 480px) {
  .section-filters-bar.v2 .form-item.split.medium .button {
    width: 100%;
  }
  .section-filters-bar .section-filters-bar-actions:last-child .form-item.split.medium .button {
    width: 100%;
  }
}

/*# sourceMappingURL=styles.css.map */